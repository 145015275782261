<template>
  <div v-if="showDetail" @click="hideBox()" class="guide-details">
    <div
      v-if="detailBoxData != ''"
      class="detail-box"
      ref="detail_box"
      :style="{
        left: left + 'px',
        top: top + 'px',
      }"
    >
      <div class="detail-box-title">
        {{ getString(detailBoxData.content.title) }}
      </div>
      <div class="detail-box-contet">
        <div class="detail-box-contet-text">
          {{ getString(detailBoxData.content.content) }}
        </div>
        <div class="detail-box-contet-img"></div>
      </div>
      <div class="detail-box-footer clearfix">
        <div class="know-button">
          {{ getString(strings.Mind_Usinghelp_Understood) }}
        </div>
      </div>
      <div v-if="data.menuboxType == 'rightCanvas'" class="triangle"></div>
      <div
        v-else-if="data.menuboxType == 'leftMenu'"
        class="triangle-left"
      ></div>
    </div>
  </div>
</template>

<script>
import getString from "../../../common/lang/language";
import strings from "../../../common/lang/strings";
export default {
  data() {
    return {
      showDetail: false,
      data: "",
      detailBoxData: "",
      left: 0,
      top: 0,
      boxarr: {
        1: {
          title: strings.Mind_Edit_Right_Menu_background_Color,
          content: strings.Help_Background_Content,
          url: "",
        },
        2: {
          title: strings.Mind_Edit_Right_Menu_layout,
          content: strings.Help_Mind_Content,
          url: "",
        },
        share_link: {
          title: strings.Mind_Edit_Left_Menu_Share_Links,
          content: strings.Help_Share_Link_Content,
          url: "",
        },
      },
      strings: strings,
    };
  },
  methods: {
    getString(i) {
      return getString(i);
    },
    hideBox() {
      this.showDetail = false;
    },
    showBox(val) {
      this.data = val;
      this.showDetail = true;
      this.detailBoxData = {};
      // this.detailBoxData.opacity = 0;
      this.detailBoxData.x = 0;
      this.detailBoxData.y = 0;
      this.detailBoxData.content = this.boxarr[val.index];
      this.$nextTick((_) => {
        let box = this.$refs.detail_box.getBoundingClientRect();
        let newVal;
        if (val.menuboxType == "rightCanvas") {
          newVal = this.computed(box, val.clientRect);
        } else if (val.menuboxType == "leftMenu") {
          newVal = this.computedLeft(box, val.clientRect);
        }
        this.detailBoxData.x = newVal.x;
        this.left = newVal.x;
        this.top = newVal.y;
        // this.detailBoxData.opacity = 1;
      });
    },
    computed(box, rect) {
      let obj = {};
      obj.x = 0;
      obj.y = 0;
      obj.x = rect.x - box.width - 30;
      obj.y = rect.y + rect.height / 2 - box.height / 2;
      return obj;
    },
    computedLeft(box, rect) {
      let obj = {};
      obj.x = 0;
      obj.y = 0;
      obj.x = rect.x + rect.width + 10;
      obj.y = rect.y + rect.height / 2 - box.height / 2;
      return obj;
    },
  },
  watch: {
    "$store.state.functionDetail"(newval, olval) {
      if (newval != "") {
        this.showBox(newval);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.guide-details {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
  // background-color:rgba(255,0,0,.2);
  .detail-box {
    position: absolute;
    left: 200px;
    top: 300px;
    width: 295px;
    z-index: 999999;
    background-color: #fff;
    // background-color:rgba(255,0,0,.2);
    border-radius: 10px;
    padding: 15px;
    box-shadow: 0px 0px 6px rgba(80, 80, 80, 0.26);
    .triangle {
      border-top: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 10px solid #fff;
      position: absolute;
      left: 100%;
      top: 50%;
      transform: translate(0, -50%);
    }
    .triangle-left {
      border-top: 10px solid transparent;
      border-left: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right: 10px solid #fff;
      position: absolute;
      right: 100%;
      top: 50%;
      transform: translate(0, -50%);
    }
    .detail-box-title {
      font-size: 16px;
      color: #333;
      margin-bottom: 10px;
    }
    .detail-box-contet {
      line-height: 24px;
      color: #666666;
    }
    .detail-box-footer {
      .know-button {
        float: right;
        cursor: pointer;
        color: #ec705a;
      }
    }
  }
}
</style>