<template>
  <div id="export-menu">
    <div
      v-show="showPage"
      :style="{
        left: pointLeft + 'px',
        top: pointTop + 'px',
        opacity: opacity,
      }"
      ref="addGroup"
      class="add-group"
    >
      <!-- <div :style="{position:'absolute',left: hiddenLeft + 'px',
            top: hiddenTop + 'px',width:hiddenWidth + 'px',height: hiddenHeight + 'px'}" class="hidden-dom"></div> -->
      <div
        v-for="(item, index) in showData"
        :key="index"
        @click="checkMenuItem(item)"
        class="menulist-each-big-box"
      >
        <div class="menulist-each">
          <img v-if="!darkMode" :src="item.image" :alt="item.name" />
          <img v-else :src="item.imgWhite" :alt="item.name" />
          <span>
            {{ item.name }}
          </span>
        </div>
        <img
          v-if="item.needMember && !isMember()"
          src="../../../assets/img/user/icon/vip.svg"
          class="need-member"
          alt="vip"
        />
      </div>
    </div>
    <!-- <GroupAddGroupModal :show="showModal" @hidden="hidden" /> -->
  </div>
</template>

<script>
import { mapMutations } from "vuex";
// import GroupAddGroupModal from "./GroupAddGroupModal"
export default {
  components: {
    // GroupAddGroupModal
  },
  props: ["showData", "pointObj", "menuPoint", "showMenu", "darkMode"],
  data() {
    return {
      showPage: false,
      pointLeft: 0, //大盒子相对于整个页面的位置
      pointTop: 0,
      // hiddenLeft: 0, //透明盒子相对与父盒子的位置
      // hiddenTop: 0,
      // hiddenWidth: 0,
      // hiddenHeight: 0,
      opacity: 0,
      showAnimal: false,
      topSpace: 15, //菜单距离顶部的距离
      // showModal: false,
    };
  },
  mounted() {
    // this.setDardarkMode()
  },
  updated() {
    let menudom = this.$refs.addGroup.getBoundingClientRect();
    // console.log('menudom.width',menudom.width);
    if (this.menuPoint.val1 == "bottom" && this.menuPoint.val2 == "left") {
      this.pointTop = this.pointObj.y + this.pointObj.height + this.topSpace;
      this.pointLeft = this.pointObj.x - menudom.width + this.pointObj.width;
      // this.hiddenLeft = menudom.width - this.pointObj.width;
      // this.hiddenTop = -this.pointObj.height;
      // this.hiddenWidth = this.pointObj.width;
      // this.hiddenHeight = this.pointObj.height;
      // console.log('进入了准确位置1')
    } else if (this.menuPoint.val1 == "top" && this.menuPoint.val2 == "left") {
      this.pointTop = this.pointObj.y - menudom.height;
      this.pointLeft = this.pointObj.x + this.pointObj.width - menudom.width;
      // this.hiddenLeft = menudom.width - this.pointObj.width;
      // this.hiddenTop = menudom.height;
      // this.hiddenWidth = this.pointObj.width;
      // this.hiddenHeight = this.pointObj.height;
    } else if (
      this.menuPoint.val1 == "left" &&
      this.menuPoint.val2 == "bottom"
    ) {
      this.pointTop = this.pointObj.y;
      this.pointLeft = this.pointObj.x - menudom.width;
      // this.hiddenLeft = menudom.width ;
      // this.hiddenTop = 0;
      // this.hiddenWidth = this.pointObj.width;
      // this.hiddenHeight = this.pointObj.height;
    } else if (
      this.menuPoint.val1 == "bottom" &&
      this.menuPoint.val2 == "center"
    ) {
      this.pointTop = this.pointObj.y + this.pointObj.height + this.topSpace;
      this.pointLeft =
        this.pointObj.x + this.pointObj.width / 2 - menudom.width / 2;
    } else if (
      this.menuPoint.val1 == "bottom" &&
      this.menuPoint.val2 == "right"
    ) {
      this.pointTop = this.pointObj.y + this.pointObj.height + this.topSpace;
      this.pointLeft = this.pointObj.x; // + this.pointObj.width/2 - menudom.width/2;
    }
  },
  methods: {
    ...mapMutations(["setgroupCreatedMenu"]),
    moveMenu(show) {
      if (show) {
        // this.setgroupCreatedMenu(true);
        this.showPage = true;
      } else {
        this.showPage = false;
        // this.setgroupCreatedMenu(false);
      }
    },
    checkMenuItem(item) {
      // if (item.name == this.$getStringObj.getString(this.$Strings.Mind_Group_Join_The_Group)) {
      //     this.showModal = true;
      // }
      this.$emit("checkMenuItem", { type: this.menuPoint.type, item: item });
    },
    hidden(obj) {
      // if (obj.show == false){
      //     this.showModal =false
      // }
    },
    setDardarkMode() {
      // 设置深色模式
      this.$nextTick(() => {
        const white = "#fff";
        let addGroupDom = this.$refs.addGroup;
        let dardarkMode = this.$store.state.mapEditIsDardarkMode;
        let daGangModels = this.$store.state.showDaGangModels;//大纲
        if (addGroupDom) {
          if (dardarkMode.darkMode && !daGangModels) {
            addGroupDom.style.color = dardarkMode.fontColor;
            addGroupDom.style.background = dardarkMode.bgColor;
            addGroupDom.style.borderColor = 'rgba(255,255,255,.2)';
            addGroupDom.style.boxShadow = 'none';
            document.documentElement.style.setProperty(
              "--exportMenuColor",
              white
            );
            document.documentElement.style.setProperty(
              "--exportMenuBg",
              "rgba(255, 255, 255, 0.3)"
            );
            document.documentElement.style.setProperty(
              "--exportMenuDashedColor",
              "rgba(255, 255, 255, 0.3)"
            );
            
            document.documentElement.style.setProperty(
              "--styleScrollbarThumbColor",
              "rgba(113, 116, 120, 1.0)"
            );
            document.documentElement.style.setProperty(
              "--styleScrollbarTackColor",
              "rgba(52, 56, 62, 1.0)"
            );
            document.documentElement.style.setProperty(
              "--styleScrollbarTackBorderColor",
              "rgba(70, 74, 79, 1.0)"
            );
          } else {
            addGroupDom.style.color = "#333";
            addGroupDom.style.background = white;
            addGroupDom.style.borderColor = '#ccc';
            addGroupDom.style.boxShadow = '0 0 5px #999';
            document.documentElement.style.setProperty(
              "--exportMenuColor",
              "#333333"
            );
            document.documentElement.style.setProperty(
              "--exportMenuBg",
              "#eee"
            );
            document.documentElement.style.setProperty(
              "--exportMenuDashedColor",
              "#ccc"
            );
            document.documentElement.style.setProperty(
              "--styleScrollbarThumbColor",
              "rgba(225, 225, 225, 1.0)"
            );
            document.documentElement.style.setProperty(
              "--styleScrollbarTackColor",
              "rgba(246, 246, 246, 1.0)"
            );
            document.documentElement.style.setProperty(
              "--styleScrollbarTackBorderColor",
              "rgba(232, 232, 232, 1.0)"
            );
          }
        }
      });
    },
  },
  watch: {
    showMenu(newVal) {
      this.showPage = newVal;
    },
    "$store.state.mapEditIsDardarkMode": {
      //导图编辑页面是否为深色模式
      handler(newVal, oldVal) {
        this.setDardarkMode();
      },
      deep: true,
    },
    "$store.state.showDaGangModels"(newshowDaGangModels) {
      //大纲
      this.setDardarkMode();
    },
    // "$store.state.groupCreatedMenu"(newModelAccout) {    //如果把所有参数都放在vuex里面，会遇到获取dom宽高还有位置延迟的问题，导致计算不准。还有深度监听的频繁触发的问题
    //     this.showPage = newModelAccout;
    //     // this.showPage = true;
    // },
    pointObj(val) {
      //一开始先移入了节点，这时显示菜单的位置不对，获取不到菜单的宽高，所以先让菜单透明，并且由于菜单的一部分刚好出现在鼠标下，
      //这时又触发了鼠标移出事件。这时能获取到菜单的大小了。所以能正常显示菜单的位置了。
      // let menudom = this.$refs.addGroup.getBoundingClientRect();
      // if (menudom.width == 0) {   //为了避免在其它位置闪一下。
      //     this.opacity = 0;
      // } else {
      //     this.opacity = 1;
      // }
      this.opacity = 1;

      // this.$nextTick(() => {
      // console.log('this.menuPoint.val1',this.menuPoint.val1);
      // console.log('this.menuPoint.val2',this.menuPoint.val2);
      // console.log('val.y',val.y);
      // console.log('menudom.width',menudom.width);

      // // if (this.menuPoint.val1 == 'bottom' && this.menuPoint.val2 == 'left') {
      // //     this.pointTop = val.y + val.height;
      // //     this.pointLeft = val.x - menudom.width + val.width;
      // //     // this.hiddenLeft = menudom.width - val.width;
      // //     // this.hiddenTop = -val.height;
      // //     // this.hiddenWidth = val.width;
      // //     // this.hiddenHeight = val.height;
      // //     console.log('进入了准确位置1')
      // // } else if(this.menuPoint.val1 == 'top' && this.menuPoint.val2 == 'left'){
      // //     this.pointTop = val.y - menudom.height;
      // //     this.pointLeft = val.x + val.width - menudom.width;
      // //     // this.hiddenLeft = menudom.width - val.width;
      // //     // this.hiddenTop = menudom.height;
      // //     // this.hiddenWidth = val.width;
      // //     // this.hiddenHeight = val.height;
      // // } else if (this.menuPoint.val1 == 'left' && this.menuPoint.val2 == 'bottom') {
      // //     this.pointTop = val.y ;
      // //     this.pointLeft = val.x - menudom.width;
      // //     // this.hiddenLeft = menudom.width ;
      // //     // this.hiddenTop = 0;
      // //     // this.hiddenWidth = val.width;
      // //     // this.hiddenHeight = val.height;
      // // } else if (this.menuPoint.val1 == 'bottom' && this.menuPoint.val2 == 'left') {
      // //     this.pointTop = val.y + val.height;
      // //     this.pointLeft = val.x + val.width - menudom.width;

      // //     console.log('进入了准确位置')
      // //     // this.hiddenLeft = menudom.width - val.width;
      // //     // this.hiddenTop = -val.height;
      // //     // this.hiddenWidth = val.width;
      // //     // this.hiddenHeight = val.height;
      // // }

      // })

      // let that = this;
      // let  clickFunction = function(e) {
      //     console.log('触发了单击事件')
      //     if (!that.$el.contains(e.target)) {
      //         console.log('进入了判断条件')

      //         that.showPage = false
      //     }
      // };
      // if (this.showMenu == true) {
      //     document.addEventListener('click', clickFunction)
      // } else {
      //     document.removeEventListener('click', clickFunction)
      // }
    },
  },
};
</script>

<style lang="less" scoped>
.add-group {
  position: fixed;
  left: 200px;
  top: 200px;
  // padding: 16px 0;
  border-radius: 8px;
  background-color: #fff;
  color: #333;
  z-index: 3;
  border: 1px solid #ccc;
  box-shadow: 0 0 5px #999;
  box-sizing: content-box;
  .menulist-each-big-box {
    padding: 0 30px;
    position: relative;
    border-radius: 6px;
    // margin-bottom: 10px;
    // display: flex;
    // align-items: center;
    .menulist-each {
      padding: 0;
      max-height: 54px;
      height: 54px;
      line-height: 54px;
      font-size: 16px;
      white-space: nowrap;
      display: flex;
      align-items: center;
      border-bottom: 1px dashed var(--exportMenuDashedColor);
      cursor: pointer;
      img {
        margin-right: 11px;
        width: 22px;
        height: 22px;
      }
    }
    .need-member {
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
    }
    // &:last-child {
    //     // margin-bottom: 0;
    // }
    &:last-child .menulist-each {
      // margin-bottom: 0;
      border-bottom: 1px dotted rgba(255, 255, 255, 0);
    }
    &:hover {
      //   background-color: #2b2b2b;
      background-color: var(--exportMenuBg);
      color: var(--exportMenuColor);
    }
  }

  // .hidden-dom {
  //     background-color:slateblue;
  // }
}
</style>