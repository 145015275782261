import MindElementFullType from "../../../viewmodel/datatype/MindElementFullType";

class NodeBackgroundFullShapeImg{
    constructor() {
        this.shapeStyleList = [
            {
              imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/nodeBackgroundFull.svg"),
              imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/nodeBackgroundFullWhite.svg"),
              type: MindElementFullType.FULL,
            },
            {
              imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/nodeBackgroundDrawOblique.svg"),
              imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/nodeBackgroundDrawObliqueWhite.svg"),
              type: MindElementFullType.DrawOblique,
            },
            {
              imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/nodeBackgroundDrawHorizontal.svg"),
              imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/nodeBackgroundDrawHorizontalWhite.svg"),
              type: MindElementFullType.DrawHorizontal,
            },
            {
              imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/nodeBackgroundDrawGrid.svg"),
              imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/nodeBackgroundDrawGridWhite.svg"),
              type: MindElementFullType.DrawGrid,
            },
            {
              imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/nodeBackgroundGrid.svg"),
              imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/nodeBackgroundGridWhite.svg"),
              type: MindElementFullType.Grid,
            },
            {
              imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/nodeBackgroundGradient.svg"),
              imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/nodeBackgroundGradientWhite.svg"),
              type: MindElementFullType.Gradient,
            },
            {
              imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/nodeBackgroundFull.svg"),
              imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/nodeBackgroundFullWhite.svg"),
              type: MindElementFullType.GradientLR,
            },
          ];
    }
}

export default NodeBackgroundFullShapeImg