<template>
  <div v-clickoutside="outsideClose"  class="dropmain">
    <div @click="clickbutton" class="dropbutton">
      测试下拉菜单
    </div>
    <div class="dropdown" v-show="show">
      <P>点击下拉菜单显示菜单内容，点击外部区域可关闭下拉菜单</P>
    </div>
  </div>
</template>

<script>
  export default {
    name: "DropDown",
    data() {
      return {
        show: false,
      }
    },
    directives: {
      clickoutside: {
        bind(el, binding, vnode) {
          function documentHandler(e) {
            if (el.contains(e.target)) {
              return false
            }
            if (binding.expression) {
              binding.value(e)
            }
          }

          function KeyUp(e) {
            if (e.keyCode == 27) {
              if (binding.expression) {
                binding.value(e)
              }
            }
          }
          el.__vueClickOutSize__ = documentHandler
          el.__vueKeyup__ = KeyUp
          
          document.addEventListener('keyup', KeyUp)
          document.addEventListener('click', documentHandler)
        },
        unbind(el, binding) {
          document.removeEventListener('click', el.__vueClickOutSize__)
          delete  el.__vueClickOutSize__

          document.removeEventListener('keyup', el.__vueKeyup__)
          delete  el.__vueKeyup__
        }
      }
    },
    methods: {
        clickbutton(){
            this.show = !this.show
        },
      outsideClose() {
        this.show = false
      }
    }
  }
</script>

<style scoped>
  [v-cloak] {
    display: none;
  }

  .dropmain {
    width: 150px;
  }

  .dropbutton {
    display: block;
    width: 100%;
    padding: 5px;
    color: #fff;
    background-color: #39f;
    text-align: center;
    font-size: 12px;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
    user-select: none;
  }

  .dropdown {
    width: 100%;
    padding: 5px;
    font-size: 12px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 6px rgba(0, 0, 0, .2);
    text-align: left;
    margin-top: 2px;
  }

</style>

