<template>
  <!-- 链接 -->
  <div id="link-box" class="complie-addmodel-remark">
    <div class="complie-titel-top">
      <div class="complie-titel-center">
        <div>
          <img
            src="../../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/add_link_af.png"
            alt=""
          />
        </div>
        <div class="complie-titel-text">
          {{ getString(strings.User_Message_Link) }}
        </div>
      </div>

      <div class="complie-right-hide">
        <!-- <img
          src="../../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/click_hide.png"
          alt=""
        /> -->
        <!-- <a-icon type="close" :style="{ fontSize: '16px' }" /> -->
        <a-button type="primary" @click="onSubmit">
          {{ getString(strings.Mind_Group_Accomplish) }}
        </a-button>
      </div>
    </div>
    <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
      <div class="addLinkMessage">
        <a-form-model-item>
          <a-input
            v-model="form.linkData"
            style="resize: none"
            class="complie-content"
            :placeholder="getString(strings.Mind_Edit_Please_Enter_The_Link)"
            @focus="focusInput()"
            @blur="blurInput()"
          />
        </a-form-model-item>
        <a-form-model-item>
          <a-button
            style="height: 38px"
            type="primary"
            @click="onAddLinkMessageList"
          >
            {{ getString(strings.Mind_Edit_Add) }}
          </a-button>
        </a-form-model-item>
      </div>
      <div class="link-Message-List">
        <div class="link-message-top" v-show="titelAccout">
          <div v-if="radioAccout">
            <div class="link-message-edit" @click="onRadioClickAccout">
              {{ getString(strings.Global_Edit) }}
            </div>
          </div>
          <div v-else>
            <div class="link-message-edit" @click="onRadioRemove">
              {{ getString(strings.Mind_Mindmap_Deleted_Forever) }}
            </div>
          </div>
          <div
            class="link-message-cancel"
            v-if="!radioAccout"
            @click="onRadioClickAccout"
          >
            {{ getString(strings.Global_Cancel) }}
          </div>
        </div>
        <div
          class="link-message-list-box"
          v-for="(item, index) in linkListData"
          :key="index"
        >
          <a-form-model-item>
            <div
              class="link-Message-Content"
              @click="hrefNewLink(item.linkData)"
            >
              {{ item.linkData }}
            </div>
            <a-checkbox v-if="!radioAccout" v-model="item.linkCheckd" />
          </a-form-model-item>
          <div class="link-message-list-bottom"></div>
        </div>
        <div class="link-hide-data" v-show="!titelAccout">
          <img
            src="../../../../assets/img/mapIcon/res/mipmap-xhdpi/hide_link.png"
            alt=""
          />
        </div>
      </div>

      <!-- <div class="complie-btn">
        <a-form-model-item>
          <a-button @click="onHideLinkModel">
            {{ getString(strings.Global_Cancel) }}
          </a-button>
          <a-button type="primary" @click="onSubmit">
            {{ getString(strings.Global_Ok) }}
          </a-button>
        </a-form-model-item>
      </div> -->
    </a-form-model>
  </div>
</template>

<script>
if (
  typeof navigator === "object" &&
  typeof navigator.userAgent === "string" &&
  navigator.userAgent.indexOf("Electron") >= 0
) {
  var { ipcRenderer } = window.require("electron");
}
import { mapMutations } from "vuex";
import complieAddModelRemark from "../../../../assets/css/complieAddModelRemark/complieAddModelRemark.less";
import getString from "../../../../common/lang/language";
import strings from "../../../../common/lang/strings";
export default {
  data() {
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 20 },
      form: {
        linkData: null,
      },
      linkListData: [],
      linkListKey: 0,
      radioAccout: false,
      titelAccout: false,
      strings,
    };
  },
  mounted() {},
  methods: {
    ...mapMutations([
      "setShowAddLink",
      "setAddLinkContent",
      "setRemoveLinkContent",
      "setNodeContentMessage",
      "setUseShortcut",
    ]),
    getString(i) {
      return getString(i);
    },
    //隐藏链接模态框
    onHideLinkModel() {
      this.setShowAddLink(false);
      this.form.linkData = null;
    },
    //添加链接
    onAddLinkMessageList() {
      if (this.form.linkData != null) {
        let linkData = this.form.linkData;
        this.linkListData.push({
          linkData: linkData,
          linkCheckd: false,
        });
        this.setAddLinkContent(linkData);
        this.bus.$emit("setAddLink", linkData);
        this.form.linkData = null;
        this.titelAccout = true;
        this.radioAccout = true;
      }
    },
    //删除连接按钮是否显示
    onRadioClickAccout() {
      this.radioAccout = !this.radioAccout;
    },
    //删除链接
    onRadioRemove() {
      let that = this;
      let newList = [];
      for (let index = that.linkListData.length - 1; index >= 0; index--) {
        if (that.linkListData[index].linkCheckd == true) {
          this.setRemoveLinkContent(that.linkListData[index].linkData);
        } else {
          newList.push(that.linkListData[index]);
        }
      }
      this.linkListData = newList;
      if (this.linkListData.length == 0) {
        this.titelAccout = false;
      }
    },
    //确认
    onSubmit() {
      this.setShowAddLink(false);
      this.form.linkData = null;
    },
    //获取节点链接内容
    getLinkContent(newLinkContent) {
      this.linkListData.push({
        linkData: newLinkContent,
        linkCheckd: false,
      });
    },
    //显示编辑文字
    isShowEdit() {
      if (this.linkListData.length != 0) {
        this.titelAccout = true;
        this.radioAccout = true;
      } else {
        this.titelAccout = false;
        this.radioAccout = false;
      }
    },
    //点击链接到新页面
    hrefNewLink(newLink) {
      if (!newLink.startsWith("http") && !newLink.startsWith("https")) {
        newLink = "http://" + newLink;
      }
      if (this.$tools.isElectron()) {
        ipcRenderer.send("open-url", newLink);
      } else {
        this.createATagJump(newLink);
      }
    },
    //将vuex存储链接对象置空
    nodeContentMessage() {
      this.setNodeContentMessage(null);
    },
    focusInput() {
      this.setUseShortcut(false);
    },
    blurInput() {
      this.setUseShortcut(true);
    },
  },
  watch: {
    "$store.state.nodeContentMessage"(newNodeContent, olNodeContent) {
      if (newNodeContent == null) {
        return;
      }
      if (
        newNodeContent.linkElementContent == null ||
        newNodeContent.linkElementContent.links.length == 0
      ) {
        return;
      }
      this.linkListData = newNodeContent.linkElementContent.links;
      this.isShowEdit();
    },
    "$store.state.showAddLink"(newshowAddLinkAccout, olshowAddLinkAccout) {
      if (newshowAddLinkAccout == false) {
        this.linkListData = [];
        this.nodeContentMessage();
      }
      this.isShowEdit();
    },
    "$store.state.linkList"(newLinkContent, olLinkContent) {
      this.linkListData = [];
      for (var i = 0; i < this.$store.state.linkList.length; i++) {
        var str = this.$store.state.linkList[i];
        this.linkListData.push({
          linkData: str,
          linkCheckd: false,
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.complie-addmodel-remark {
  width: 524px;
  height: 474px;
  border-radius: 9px;
  z-index: 9999;
  position: absolute;
  left: 50%;
  right: 50%;
  transform: translate(-50%, 50%);
  box-shadow: 2px 2px 10px 6px rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  .link-message-list-bottom {
    border-top: 1px solid #e8e8e8;
    width: 100%;
  }
  .link-hide-data {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .link-message-top {
    position: relative;
    height: 20px;
    .link-message-edit {
      font-size: 16px;
      position: absolute;
      left: 0;
      transition: 0.5s;
      cursor: pointer;
    }
    .link-message-edit:hover {
      font-size: 16px;
      position: absolute;
      left: 0;
      color: #fd492b;
      transition: 0.5s;
    }
    .link-message-cancel {
      font-size: 16px;
      position: absolute;
      right: 0;
      transition: 0.5s;
      cursor: pointer;
    }
    .link-message-cancel:hover {
      font-size: 16px;
      position: absolute;
      right: 0;
      color: #fd492b;
      transition: 0.5s;
    }
  }
  .complie-titel-top {
    width: 524px;
    height: 60px;
    position: relative;
    .complie-titel-center {
      display: flex;
      // justify-content: center;
      background: #efefef;
      padding: 0 15px;
      align-items: center;
      .complie-titel-text {
        width: 40px;
        height: 25px;
        font-size: 18px;
        /*font-weight: bold;*/
        color: #333333;
        opacity: 1;
        margin: 10px;
        display: flex;
        align-items: center;
      }
    }
    .complie-right-hide {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 6.5px;
      right: 15px;
      cursor: pointer;
      transition: 0.3s;
    }
    .complie-right-hide:hover {
      background-color: #ff765e;
    }
  }
  .complie-content {
    width: 402px;
    height: 38px;
    position: absolute;
    top: 0;
    left: 30px;
  }
  .complie-btn {
    width: 524px;
    position: absolute;
    bottom: 20px;
    button {
      height: 32px;
      border-radius: 2px;
    }
  }
}

.link-Message-List {
  margin: 10px 30px;
  width: 464px;
  height: 340px;
  overflow: hidden;
  position: absolute;
  top: 100px;
  .ant-form-item-children {
    width: 464px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    .link-Message-Content {
      width: 349px;
      height: 58px;
      border-radius: 8px;
      cursor: pointer;
      display: flex;
      align-items: center;
      // border: none;
      // outline: none !important;
    }
    .ant-input:focus {
      border: none;
      outline: none !important;
      box-shadow: none;
    }
    label {
      position: absolute;
      right: 0px;
      line-height: 58px;
    }
  }
}
.ant-form-item {
  margin-bottom: 0px;
}
</style>