<template>
<!-- 标题位置 -->
  <div class="menu-content-border-box" id="title-position-box">
    <div class="menu-use-content-inside" ref="titlePositionBox">
      <ul class="menu-use-box">
        <li
          v-for="(item, index) in encircleLayoutStyleList"
          :key="index"
          @click="getNodeTypeAccout(item.nodeType,mapEditDarkMode?item.imgWhite:item.layoutIcon)"
        >
          <img v-if="mapEditDarkMode" :src="item.imgWhite" />
          <img v-else :src="item.layoutIcon" />
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapMutations,mapState } from "vuex";
import EncircleTitleLayoutType from '../../../viewmodel/datatype/EncircleTitleLayoutType';
import EditMindmapVM from '../../../viewmodel/mindelementdata/vm/EditMindmapVM';
export default {
  name: "ComplieChildMenuEncircle",
  props: [
  ],
  data() {
    return {
      encircleLayoutStyleList:[
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/encircleTitle/top_left.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/encircleTitle/darkModeIcon/top_left.svg"),
          nodeType: EncircleTitleLayoutType.TOP_LEFT,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/encircleTitle/top_center.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/encircleTitle/darkModeIcon/top_center.svg"),
          nodeType: EncircleTitleLayoutType.TOP_MIDDLE,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/encircleTitle/top_reight.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/encircleTitle/darkModeIcon/top_reight.svg"),
          nodeType: EncircleTitleLayoutType.TOP_RIGHT,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/encircleTitle/center_left.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/encircleTitle/darkModeIcon/center_left.svg"),
          nodeType: EncircleTitleLayoutType.MIDDLE_LEFT,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/encircleTitle/center_center.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/encircleTitle/darkModeIcon/center_center.svg"),
          nodeType: EncircleTitleLayoutType.MIDDLE_MIDDLE,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/encircleTitle/center_reight.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/encircleTitle/darkModeIcon/center_reight.svg"),
          nodeType: EncircleTitleLayoutType.MIDDLE_RIGHT,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/encircleTitle/top_inside_full.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/encircleTitle/darkModeIcon/top_inside_full.svg"),
          nodeType: EncircleTitleLayoutType.TOP_INSIDE_FULL,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/encircleTitle/top_inside_left.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/encircleTitle/darkModeIcon/top_inside_left.svg"),
          nodeType: EncircleTitleLayoutType.TOP_INSIDE_LEFT,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/encircleTitle/top_inside_reight.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/encircleTitle/darkModeIcon/top_inside_reight.svg"),
          nodeType: EncircleTitleLayoutType.TOP_INSIDE_RIGHT,
        },
      ],
      layoutType: "",
      viewModel: null,
    };
  },
  computed: {
    ...mapState({
      mapEditDarkMode: (state) => state.mapEditIsDardarkMode.darkMode,//深色模式
    }),
  },
  created() {
  },
  mounted() {
    if (this.viewModel == null) {
      this.viewModel = this.$store.state.editMindmap;
    }
    this.setDardarkMode();
  },
  methods: {
    ...mapMutations(["setEncircleLayoutImg"]),
    getNodeTypeAccout(type,url) {
      EditMindmapVM.setEncircleTitle(type)
      //将点击的值传送到页面。
      this.bus.$emit("isShowMindMenu", false);
      this.setEncircleLayoutImg(url)
    },
    setDardarkMode() {
      // 设置深色模式
      this.$nextTick(() => {
        let boxDom = this.$refs.titlePositionBox;
        let dardarkMode = this.$store.state.mapEditIsDardarkMode;
        if (boxDom) {
          if (dardarkMode.darkMode) {
            boxDom.style.border = '1px solid rgba(255,255,255,.3)';
            boxDom.style.background = dardarkMode.bgColor;
          } else {
            boxDom.style.border = 0;
            boxDom.style.background = "#fff";
          }
        }
      });
    },
  },
  watch:{
    "$store.state.mapEditIsDardarkMode": {
      //导图编辑页面是否为深色模式
      handler(newVal, oldVal) {
        this.setDardarkMode();
      },
      deep: true,
    },
    // isShowExplain(newVal, old) {
    //   //console.log('改变了菜单状态')
    //   this.isExplain = newVal;
    // },
  }
};
</script>
<style scoped>
.explain-border-box{
  margin-top: 70px;
}
.menu-use-content-inside {
  width: 208px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 2px 2px 10px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
  background-color: #ffffff;
  position: relative;
  z-index: 9999;
}
.menu-use-content-inside ul {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  flex-wrap: wrap;
}
.menu-use-content-inside ul li {
  width: 45px;
  height: 45px;
  list-style: none;
  margin: 3px 8px;
  padding: 3px 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.menu-use-content-inside ul li:hover {
  background: #e0e0e0;
}
.menu-use-content-inside ul li img {
  width: auto;
  height: auto;
}
.menu-theme-border {
  background: #e0e0e0;
}
</style>