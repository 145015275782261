<template>
  <div
    class="ComplieLeftContent"
    :class="
      rightMenuAccout ? 'showComplieLeftContent' : 'hideComplieLeftContent'
    "
    @click="hideEditInput"
  >
    <div
      class="right-father-menu"
      :class="rightShowMenu ? 'rightshowchild' : 'righthidechild'"
    ></div>
    <div
      class="right-child-menu"
      :class="rightShowMenu ? 'rightshowfather' : 'righthidefather'"
      style="flex: 0 0 291px; max-width: 291px; min-width: 291px; width: 300px"
    >
      <a-layout-sider ref="rightMenuBox">
        <!-- 导图模式 -->
        <div class="menu-right-menu" v-if="!hideOutlineAccout">
          <div class="menu-content-header">
            <div class="menu-header-title-container"
              v-for="(item, index) in menuTitleList"
              :key="index"
              @click="setMenuHeaderTitle(index)"
            >
              <div
                class="menu-header-title-before"
                :class="menuHeaderTitle == index ? 'menu-header-title' : ''"
              >
                {{ getString(item.menuTitles) }}
              </div>
            </div>
          </div>
          <div class="right-menu-content-box menu-silder">
            <div class="menu-content">
              <div
                v-for="(item, index) in menuRightTitle"
                :key="index"
                :class="item.menuUesClass"
              >
                <div
                  class="menu-use-content hide-component"
                  :class="
                    menuHeaderTitle == index ? 'showComponent' : 'hideComponent'
                  "
                >
                  <component :is="item.ComplieChildMenu" />
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </a-layout-sider>
    </div>
  </div>
</template>
<script>
import ComplieRightMenuIcon from "./ComplieRightMenuIcon/ComplieRightMenuIcon";
import ComplieRightCanvas from "./ComplieRightMenu/ComplieRightCanvas";
import ComplieRightStlye from "./ComplieRightMenu/ComplieRightStlye";

import ComplieRightOutline from "./ComplieRightMenu/ComplieRightOutline";
import { mapActions, mapMutations, mapState } from "vuex";
import strings from '../../common/lang/strings';
import getString from '../../common/lang/language';
import EditMindmapVM from '../../viewmodel/mindelementdata/vm/EditMindmapVM';
import MindDisplayType from '../../viewmodel/datatype/MindDisplayType';

export default {
  name: "ComplieRightMenu",
  data() {
    return {
      menuHeaderTitle: 0, //菜单title
      rightShowMenu: true, //显示菜单
      rightMenuAccout: true, //菜单是否显示
      menuTitleList: [
        {
          menuTitles: strings.Mind_Edit_Right_Menu_canvas,
        },
        {
          menuTitles: strings.Mind_Edit_Right_Style,
        },
        {
          menuTitles: strings.Mind_Edit_Right_Icon,
        },
      ],//导图菜单title
      menuRightTitle: [
        {
          ComplieChildMenu: "ComplieRightCanvas",
        },
        {
          ComplieChildMenu: "ComplieRightStlye",
        },
        {
          ComplieChildMenu: "ComplieRightMenuIcon",
        },
      ],//大纲菜单组件
      hideOutlineAccout:false,//是否为大纲模式
      strings:strings
    };
  },

  components: {
    ComplieRightMenuIcon,
    ComplieRightCanvas,
    ComplieRightStlye,
    ComplieRightOutline
  },
  mounted() {
    this.bus.$on("showSetIconStyle", (msg) => {
      this.menuHeaderTitle = msg;
    });
    this.setDardarkMode();
  },
  methods: {
    ...mapMutations(["setAddIconPage"]),
    //语言包
    getString(i){
      return getString(i)
    },
    //改变菜单title
    setMenuHeaderTitle(index) {
      this.menuHeaderTitle = index;
      switch (index) {
        case 1:
          this.setAddIconPage("ADD_STYLE");
          break;
        case 2:
          this.setAddIconPage("ADD_ICON");
          break;
      }
    },
    //菜单选中状态
    setMenuRightIconAccout(accout) {
      this.setAddIconPage(accout);
    },
    //隐藏菜单
    hideRightMenu(accout) {
      this.rightMenuAccout = !this.rightMenuAccout;
      this.bus.$emit("rightMenuAccout", this.rightMenuAccout);
    },
    hideEditInput(){
      this.bus.$emit("hideEditInput", true);
    },
    setDardarkMode() {
      // 设置深色模式
      this.$nextTick(() => {
        const white = "#fbfbfb";
        let boxDom = this.$refs.rightMenuBox.$el;
        let dardarkMode = this.$store.state.mapEditIsDardarkMode;
        if (boxDom) {
          if (dardarkMode.darkMode) {
            boxDom.style.color = dardarkMode.fontColor;
            boxDom.style.background = dardarkMode.bgColor;
            boxDom.style.borderColor = dardarkMode.bgColor;
            document.documentElement.style.setProperty(
              "--rightMenuBorderColor",
              'rgba(255,255,255,.2)'
            );
            document.documentElement.style.setProperty(
              "--rMDropDownListHoverColor",
              '#666'
            );
            document.documentElement.style.setProperty(
              "--rMDropDownListBorderColor",
              'rgba(255,255,255,.2)'
            );
            document.documentElement.style.setProperty(
              "--antSwitchBg",
              "rgba(255, 255, 255, 0.3)"
            );
            document.documentElement.style.setProperty(
              "--antInputNumberColor",
              white
            );
            document.documentElement.style.setProperty(
              "--antInputNumberBg",
              'rgba(255, 255, 255, 0.1)'
            );
            document.documentElement.style.setProperty(
              "--antInputBorderColor",
              'rgba(255, 255, 255, 0.2)'
            );
          } else {
            boxDom.style.color = "#333";
            boxDom.style.background = white;
            boxDom.style.borderColor = white;
            document.documentElement.style.setProperty(
              "--antSwitchBg",
              "rgba(0,0,0,.25)"
            );
            document.documentElement.style.setProperty(
              "--rMDropDownListHoverColor",
              '#409eff'
            );
            document.documentElement.style.setProperty(
              "--rMDropDownListBorderColor",
              '#e6e6e6'
            );
            document.documentElement.style.setProperty(
              "--rightMenuBorderColor",
              '#e4e4e4'
            );
            document.documentElement.style.setProperty(
              "--antInputNumberColor",
              'rgba(0,0,0,.65)'
            );
            document.documentElement.style.setProperty(
              "--antInputNumberBg",
              white
            );
            document.documentElement.style.setProperty(
              "--antInputBorderColor",
              '#d9d9d9'
            );
          }
        }
        
      });
    },
  },
  watch: {
    "$store.state.setAddIconPage" : {
      handler(newval, olval) {
        if (newval != null) {

          if (newval.type == "ADD_ICON") {
            this.menuHeaderTitle = 2;
            // setTimeout(() => {
            //   this.setMenuRightIconAccout("ADD_ICONS");
            // }, 200);
          } else if (newval.type == "ADD_STYLE" && EditMindmapVM.mindDisplayType != MindDisplayType.Outline) {
            this.menuHeaderTitle = 1;
            // setTimeout(() => {
            //   this.setMenuRightIconAccout("ADD_STYLES");
            // }, 200);
          } else if (newval.type == "ADD_Canvas") {
            this.menuHeaderTitle = 0;
            // setTimeout(() => {
            //   this.setMenuRightIconAccout("ADD_Canvas");
            // }, 200);
          }
        }

      },
      deep:true
    },
    //大纲
    "$store.state.showDaGangModels"(newshowDaGangModels) {
      this.hideOutlineAccout = newshowDaGangModels;
      this.menuHeaderTitle = 0
    },
    "$store.state.mapEditIsDardarkMode": {
      //导图编辑页面是否为深色模式
      handler(newVal, oldVal) {
        this.setDardarkMode();
      },
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped>
.hideComponent {
  display: none !important;
}
.right-menu-content-box {
  height: calc(100vh - 120px);
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 0px;
  }
  &::-webkit-scrollbar-track {
    background: rgb(239, 239, 239);
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background: #bfbfbf;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #bfbfbf;
  }
}

.right-child-menu {
  position: relative;
}
.right-menu-content-box .rigth-accout-btn {
  width: 34px;
  height: 56px;
  background: #f5f5f5;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 10px 0px 0px 10px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -36px;
  top: 300px;
  cursor: pointer;
}
.right-menu-content-box {
  position: relative;
  width: 300px;
  // border-top: 1px solid var(--rightMenuBorderColor);
}
.right-menu-content-box .menu-content-header .menu-right-menu{
  width: 288px;
  height: 32px;
  background-color: #e0e0e0;
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: center;
}
/**背景色 */
.ant-layout-sider {
  background-color: #f5f5f5;
  border-right: 1px solid #dedede;
}
/*右侧控制菜单栏目按钮*/
aside {
  flex: 0 0 291px !important;
  max-width: 291px !important;
  min-width: 291px !important;
  width: 300px !important;
}
.ant-checkbox-wrapper .ant-checkbox-checked::after {
  background-color: #fd492b !important;
  border-color: #fd492b !important;
}
.menu-content-header {
  display: flex;
}
.menu-right-menu .menu-content-header  .menu-header-title {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #5e5e5ed0;
  width: 93px;
  height: 28px;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
  transition: 0.5s;
}
.menu-header-title-before {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 93px;
  height: 28px;
  font-size: 14px;
  // font-weight: 700;
  /* color: #666666; */
  // border-top: 1px solid var(--rightMenuBorderColor);
  // border-right: 1px solid var(--rightMenuBorderColor);
  background: var(--rightMenuBorderColor);
  border-radius: 4px 4px 0 0;
  cursor: pointer;
}
.menu-header-title-container {
  // border-right: 1px solid var(--rightMenuBorderColor);
  padding: 0 2px 2px 2px;
}

.ant-layout-sider {
  height: calc(100vh - 65px);
}
/**折叠 */
.ComplieLeftContent {
  // box-shadow: -6px 0px 10px rgba(0, 0, 0, 0.1);
}
.hideComplieLeftContent {
  position: absolute;
  right: -291px;
  transition: 0.5s;
}
.showComplieLeftContent {
  position: absolute;
  right: 0px;
  top: -3px;
  transition: 0.5s;
  z-index: 200;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
}
.showRightMenuIcon {
  transform: rotate(360deg);
  transition: 0.5s;
}
.hideRightMenuIcon {
  transform: rotate(540deg);
  transition: 0.5s;
}
/deep/.ant-switch {
  background: var(--antSwitchBg);
}
/deep/.ant-switch-checked {
    background-color: #FD492B;
}
/deep/.ant-input-number{
  background: var(--antInputNumberBg);
  color: var(--antInputNumberColor);
  border: 1px solid var(--antInputBorderColor);
}

</style>