// package mind.yushu.com.mindmap.main.edit.tools.style.data;

import MindElementType from "../../../viewmodel/datatype/MindElementType";
import NodeLayoutType from "../../../viewmodel/datatype/NodeLayoutType";
import LayoutImg from "./LayoutImg";

/**
 * ProjectName: MindMap
 * Created by tony on 2020/6/17
 * Copyright(c) 2020 mindyushu.com
 */

class LayoutData {
    constructor() {
        this.lineMindTypeItemDatas = [
            {
                MindElementType: MindElementType.LAYOUT_CIRCLE
            },
            {
                MindElementType: MindElementType.LAYOUT_LEFT_RIGHT
            },
            {
                MindElementType: MindElementType.LAYOUT_RIGHT_LEFT
            },
            {
                MindElementType: MindElementType.LAYOUT_RIGHT_LEFT_CLOCKWISE
            },
            {
                MindElementType: MindElementType.LAYOUT_RADIATION_MAP
            },
            {
                MindElementType: MindElementType.LAYOUT_LEFT
            },
            {
                MindElementType: MindElementType.LAYOUT_RIGHT
            },
            {
                MindElementType: MindElementType.LAYOUT_BOTTOM
            },
            {
                MindElementType: MindElementType.LAYOUT_TOP
            },
            {
                MindElementType: MindElementType.LAYOUT_BRACKETS_LEFT
            },
            {
                MindElementType: MindElementType.LAYOUT_BRACKETS_RIGHT
            },
            {
                MindElementType: MindElementType.LAYOUT_TREE_LEFT_RIGHT
            },
            {
                MindElementType: MindElementType.LAYOUT_TREE_LEFT
            },
            {
                MindElementType: MindElementType.LAYOUT_TREE_RIGHT
            },
            {
                MindElementType: MindElementType.LAYOUT_TOP_TREE_LEFT_RIGHT
            },
            {
                MindElementType: MindElementType.LAYOUT_TOP_TREE_RIGHT
            },
            {
                MindElementType: MindElementType.LAYOUT_TOP_TREE_LEFT
            },
            {
                MindElementType: MindElementType.LAYOUT_HORIZONTAL_RIGHT
            },
            {
                MindElementType: MindElementType.LAYOUT_VERTICAL_RIGHT
            },
            {
                MindElementType: MindElementType.LAYOUT_FISH_RIGHT
            },
            {
                MindElementType: MindElementType.LAYOUT_FISH_LEFT
            },
            {
                MindElementType: MindElementType.LAYOUT_FORM
            },
            {
                MindElementType: MindElementType.LAYOUT_TRIANGLE
            },
        ];

        this.timeMindTypeItemDatas = [
            {
                MindElementType: MindElementType.LAYOUT_VERTICAL
            },
            {
                MindElementType: MindElementType.LAYOUT_HORIZONTAL
            },
            {
                MindElementType: MindElementType.LAYOUT_HORIZONTAL_S
            },
            {
                MindElementType: MindElementType.LAYOUT_LADDER
            },
            {
                MindElementType: MindElementType.LAYOUT_HORIZONTAL_CRISSCROSS
            },
        ];
    
        this.bubbleMindTypeItemDatas = [
            {
                MindElementType: MindElementType.LAYOUT_BUBBLE
            },
            {
                MindElementType: MindElementType.LAYOUT_FORM
            },
        ];
    
        this.doubleBubbleMindTypeItemDatas = [
            {
                MindElementType: MindElementType.LAYOUT_BUBBLE
            }
        ];
    };



    // nodeLayoutTypeIcons = new HashMap<>();


    // public ToolItemData[] getToolsData(MindType mindType) {
    //     switch (mindType) {
    //         case LINE_MAP:
    //         return lineMindTypeItemDatas;
    //         case TIME_MAP:
    //         return timeMindTypeItemDatas;
    //         case BUBBLE_MAP:
    //         return bubbleMindTypeItemDatas;
    //         case DOUBLE_BUBBLE_MAP:
    //         return doubleBubbleMindTypeItemDatas;
    //         default:
    //             return lineMindTypeItemDatas;
    //     }
    // }

    // public int getLayoutIcon(NodeLayoutType type) {
    //     for (ToolItemData cell : lineMindTypeItemDatas) {
    //         if (cell.nodeLayoutType == type) {
    //             return cell.resIcon;
    //         }
    //     }
    //     for (ToolItemData cell : timeMindTypeItemDatas) {
    //         if (cell.nodeLayoutType == type) {
    //             return cell.resIcon;
    //         }
    //     }
    //     for (ToolItemData cell : bubbleMindTypeItemDatas) {
    //         if (cell.nodeLayoutType == type) {
    //             return cell.resIcon;
    //         }
    //     }
    //     for (ToolItemData cell : doubleBubbleMindTypeItemDatas) {
    //         if (cell.nodeLayoutType == type) {
    //             return cell.resIcon;
    //         }
    //     }
    //     return R.mipmap.layout_right_icon;
    // }

    getImgData(noLayout) {  //根据layout的值获取MindElement的类型，再获取图片列表。
        //双气泡图，在MindElementType里面没有。让其等于气泡图
        if(noLayout == NodeLayoutType.LAYOUT_DOUBLE_BUBBLE){
            noLayout = NodeLayoutType.LAYOUT_BUBBLE;
        }
        
        let nodeLayoutKeyString = "";
        for(let key in NodeLayoutType) {
            if (NodeLayoutType[key] == noLayout) {
                nodeLayoutKeyString = key;
            }
        }

        let MindElementTypeVal;
        for(let key in MindElementType) {
            if (key == nodeLayoutKeyString) {
                MindElementTypeVal = MindElementType[key];
            }
        }

        let layoutImg = new LayoutImg();
        for (let mealName of Object.values(layoutImg)) {
            if (this.isArray(mealName)) {
                let mealNameLen = mealName.length;
                for (let index = 0; index < mealNameLen; index++) {
                    if (mealName[index].nodeLayoutType == MindElementTypeVal) {
                        return mealName[index]
                    }
                }
            }
        }
        return "";
    }

    isArray(arr) {
        const toString = Object.prototype.toString
        const isArray = Array.isArray || function (arg) { return toString.call(arg) === '[object Array]' }
        return isArray(arr)
    }

    getChangeLayoutDatas(node, parentNode) {
        let list = new Array();

        if (node.isEmpty()) {
            return list;
        }
        let referenceLayout = parentNode.layout;
        if (node.type == MindElementType.CONTENT_GENERALIZATION) {
            referenceLayout = node.layout;
        } else {
            if (parentNode.isEmpty()) {
                // for (ToolItemData cell : lineMindTypeItemDatas) {
                //     list.add(cell);
                // }
                this.lineMindTypeItemDatas.forEach(cell => {
                    list.push(cell);
                })
                return list;
            }
        }

        if (referenceLayout == NodeLayoutType.LAYOUT_LEFT_RIGHT ||
                referenceLayout == NodeLayoutType.LAYOUT_RIGHT_LEFT ||
                referenceLayout == NodeLayoutType.LAYOUT_RIGHT_LEFT_CLOCKWISE ||
                referenceLayout == NodeLayoutType.LAYOUT_TREE_LEFT_RIGHT||
                referenceLayout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT_RIGHT ||
                referenceLayout == NodeLayoutType.LAYOUT_FREE_TREE) {
            referenceLayout = node.layout;
        }
        switch (referenceLayout) {
            case NodeLayoutType.LAYOUT_TREE_RIGHT:
                list.push({
                    MindElementType: MindElementType.LAYOUT_RIGHT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_BRACKETS_RIGHT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_TREE_RIGHT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_FISH_RIGHT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_FORM
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_FORM_HORIZONTAL
                });
                break;
            case NodeLayoutType.LAYOUT_TOP_TREE_RIGHT:
                list.push({
                    MindElementType: MindElementType.LAYOUT_RIGHT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_BRACKETS_RIGHT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_TOP_TREE_RIGHT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_FISH_RIGHT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_FORM
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_FORM
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_FORM_HORIZONTAL
                });
                break;
            case NodeLayoutType.LAYOUT_RIGHT:
            case NodeLayoutType.LAYOUT_BRACKETS_RIGHT:
            case NodeLayoutType.LAYOUT_FISH_RIGHT:
            case NodeLayoutType.LAYOUT_FISH_LEFT:
                list.push({
                    MindElementType: MindElementType.LAYOUT_RIGHT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_BRACKETS_RIGHT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_TREE_RIGHT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_TOP_TREE_RIGHT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_FISH_RIGHT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_FISH_LEFT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_FORM
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_FORM_HORIZONTAL
                });
                break;
            case NodeLayoutType.LAYOUT_TREE_LEFT:
                list.push({
                    MindElementType: MindElementType.LAYOUT_LEFT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_BRACKETS_LEFT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_TREE_LEFT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_FORM
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_FORM_HORIZONTAL
                });
                break;
            case NodeLayoutType.LAYOUT_TOP_TREE_LEFT:
                list.push({
                    MindElementType: MindElementType.LAYOUT_LEFT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_BRACKETS_LEFT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_TOP_TREE_LEFT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_FORM
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_FORM_HORIZONTAL
                });
                break;
            case NodeLayoutType.LAYOUT_CIRCLE:
                list.push({
                    MindElementType: MindElementType.LAYOUT_CIRCLE
                });
                break;
            case NodeLayoutType.LAYOUT_LEFT:
            case NodeLayoutType.LAYOUT_BRACKETS_LEFT:
                list.push({
                    MindElementType: MindElementType.LAYOUT_LEFT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_BRACKETS_LEFT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_TREE_LEFT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_TOP_TREE_LEFT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_FORM
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_FORM_HORIZONTAL
                });
                // list.add(new ToolItemData(R.mipmap.left_layout_icon, MindElementType.LAYOUT_LEFT, NodeLayoutType.LAYOUT_LEFT));
                // list.add(new ToolItemData(R.mipmap.tree_left_layout_icon, MindElementType.LAYOUT_TREE_LEFT, NodeLayoutType.LAYOUT_TREE_LEFT));
                break;
            case NodeLayoutType.LAYOUT_BOTTOM:
                list.push({
                    MindElementType: MindElementType.LAYOUT_BOTTOM
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_TREE_LEFT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_TREE_RIGHT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_TREE_LEFT_RIGHT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_FORM
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_FORM_HORIZONTAL
                });
                break;
            case NodeLayoutType.LAYOUT_TOP:
                list.push({
                    MindElementType: MindElementType.LAYOUT_TOP
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_TOP_TREE_LEFT_RIGHT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_TOP_TREE_RIGHT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_TOP_TREE_LEFT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_FORM
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_FORM_HORIZONTAL
                });
                break;

            case NodeLayoutType.LAYOUT_TOP_TREE_LEFT_RIGHT:
                list.push({
                    MindElementType: MindElementType.LAYOUT_TOP_TREE_LEFT_RIGHT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_TOP_TREE_RIGHT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_TOP_TREE_LEFT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_FORM
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_FORM_HORIZONTAL
                });
                break;
            case NodeLayoutType.LAYOUT_TREE_LEFT_RIGHT:
                list.push({
                    MindElementType: MindElementType.LAYOUT_TREE_LEFT_RIGHT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_TREE_LEFT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_TREE_RIGHT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_FORM
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_FORM_HORIZONTAL
                });
                break;
            case NodeLayoutType.LAYOUT_HORIZONTAL_RIGHT:
                list.push({
                    MindElementType: MindElementType.LAYOUT_TOP
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_BOTTOM
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_TREE_LEFT_RIGHT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_TREE_LEFT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_TREE_RIGHT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_TOP_TREE_LEFT_RIGHT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_TOP_TREE_RIGHT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_TOP_TREE_LEFT
                });
                break;
            case NodeLayoutType.LAYOUT_VERTICAL_RIGHT:
                list.push({
                    MindElementType: MindElementType.LAYOUT_LEFT_RIGHT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_RIGHT_LEFT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_RIGHT_LEFT_CLOCKWISE
                });

                list.push({
                    MindElementType: MindElementType.LAYOUT_LEFT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_BRACKETS_LEFT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_RIGHT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_BRACKETS_RIGHT
                });           
                list.push({
                    MindElementType: MindElementType.LAYOUT_FISH_RIGHT
                }); 
                break;
            case NodeLayoutType.LAYOUT_LEFT_RIGHT:
            case NodeLayoutType.LAYOUT_RIGHT_LEFT:
            case NodeLayoutType.LAYOUT_RIGHT_LEFT_CLOCKWISE:
            case NodeLayoutType.LAYOUT_FREE_TREE:
                break;
            case NodeLayoutType.LAYOUT_TRIANGLE:
                list.push({
                    MindElementType: MindElementType.LAYOUT_TRIANGLE
                });
                // list.add(new ToolItemData(R.mipmap.triangle_layout_icon, MindElementType.LAYOUT_TRIANGLE, NodeLayoutType.LAYOUT_TRIANGLE));
                break;
            case NodeLayoutType.LAYOUT_FORM:
                list.push({
                    MindElementType: MindElementType.LAYOUT_FORM
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_LEFT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_BRACKETS_LEFT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_RIGHT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_BRACKETS_RIGHT
                }); 
                list.push({
                    MindElementType: MindElementType.LAYOUT_TOP
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_BOTTOM
                });          
                list.push({
                    MindElementType: MindElementType.LAYOUT_FISH_RIGHT
                }); 
                list.push({
                    MindElementType: MindElementType.LAYOUT_FISH_LEFT
                }); 
                list.push({
                    MindElementType: MindElementType.LAYOUT_TREE_LEFT_RIGHT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_TREE_LEFT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_TREE_RIGHT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_TOP_TREE_LEFT_RIGHT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_TOP_TREE_RIGHT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_TOP_TREE_LEFT
                });
                break;
            case NodeLayoutType.LAYOUT_FORM_HORIZONTAL:
                list.push({
                    MindElementType: MindElementType.LAYOUT_FORM_HORIZONTAL
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_LEFT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_BRACKETS_LEFT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_RIGHT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_BRACKETS_RIGHT
                }); 
                list.push({
                    MindElementType: MindElementType.LAYOUT_TOP
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_BOTTOM
                });          
                list.push({
                    MindElementType: MindElementType.LAYOUT_FISH_RIGHT
                }); 
                list.push({
                    MindElementType: MindElementType.LAYOUT_FISH_LEFT
                }); 
                list.push({
                    MindElementType: MindElementType.LAYOUT_TREE_LEFT_RIGHT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_TREE_LEFT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_TREE_RIGHT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_TOP_TREE_LEFT_RIGHT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_TOP_TREE_RIGHT
                });
                list.push({
                    MindElementType: MindElementType.LAYOUT_TOP_TREE_LEFT
                });
                break;
            default:
        }
        return list;
    }
}


export default new LayoutData()
