<template>
  <li class="wzc_option" :class="sizeSliderCurrent==label?'wzc_option_select':''" :style="styleVar" @click="currentSelect">
    <div class="wzc_option_dropdown_item">{{ (precision && label%1 == 0) ? label + ".0" : label }}</div>
  </li>
</template>

<script>
export default {
  name: "wzc_select",
  components: {},
  props: {
    width: {
      type: Number,
      default: -1,
    },
    height: {
      type: Number,
      default: 34,
    },
    label: {
      type: Number,
    },
    optionid: {
      type: Number,
    },
    sizeSliderCurrent:{
      type: Number,
    },
    precision: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  watch: {},
  computed: {
    styleVar() {
      return {
        "--option-height": this.height + "px",
        "--option-width": this.width == -1? "100%" : this.width + "px",
        
      };
    },
  },
  methods: {
    currentSelect() {
      this.$parent.label = this.label;
      this.$parent.optionid = this.optionid;
      this.$parent.isListShow = !this.$parent.isListShow;
      this.$emit('changeVal', this.label);
      // this.$emit('slot-content', {label: this.label, optionid: this.optionid} );
    }
  },
};
</script>
<style scoped>
.wzc_option {
  list-style: none;
  height: var(--option-height);
  width: var(--option-width);
}
.wzc_option:hover {
  color: var(--rMDropDownListHoverColor);
  font-weight: 700;
  background-color: #f5f7fa;
}
.wzc_option_dropdown_item {
  height: 100%;
  width: calc(100%);
  line-height: var(--option-height);
  cursor: pointer;
  margin: 0 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  white-space: nowrap;
}
.wzc_option_select{
  color: var(--rMDropDownListHoverColor);
  font-weight: 700;
  background-color: #f5f7fa;
}

</style>
