import LineLayout from "../../../viewmodel/datatype/LineLayout";
import MindElementType from "../../../viewmodel/datatype/MindElementType";

// 导图的布局结构列表,这个类里面在constructor定义数组要注意，因为外部会遍历这个类里面所有的数组。

class BorderLineImg {
    constructor() {
      this.borderStyleList = [
        {
          borderIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/lightModeIcon/straight_line_icon.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/darkModeIcon/straightLineWhite.svg"),
          borderStyle: MindElementType.STRAIGHT_LINE,
          LineType: LineLayout.STRAIGHT_LINE,
        },
        {
          borderIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/lightModeIcon/straight_line2_icon.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/darkModeIcon/straight_line2_icon.svg"),
          borderStyle: MindElementType.STRAIGHT_LINE_2,
          LineType: LineLayout.STRAIGHT_LINE_2,
        },
        {
          borderIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/lightModeIcon/curve_line_icon.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/darkModeIcon/curve_line_icon.svg"),
          borderStyle: MindElementType.CURVE_LINE,
          LineType: LineLayout.CURVE_LINE,
        },
        {
          borderIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/lightModeIcon/straight_curve_line_icon.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/darkModeIcon/straight_curve_line_icon.svg"),
          borderStyle: MindElementType.STRAIGHT_CURVE_LINE,
          LineType: LineLayout.STRAIGHT_CURVE_LINE,
        },
        {
          borderIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/lightModeIcon/straight_line_icon_124.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/darkModeIcon/straight_line_icon_124.svg"),
          borderStyle: MindElementType.CURVE_LINE_CIRCULAR,
          LineType: LineLayout.CURVE_LINE_CIRCULAR,
        },
        {
          borderIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/lightModeIcon/curve_line_2_icon.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/darkModeIcon/curve_line_2_icon.svg"),
          borderStyle: MindElementType.CURVE_LINE_2,
          LineType: LineLayout.CURVE_LINE_2,
        },
        {
          borderIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/lightModeIcon/straight_line_icon_123.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/darkModeIcon/straight_line_icon_123.svg"),
          borderStyle: MindElementType.CURVE_LINE_AVERAGE,
          LineType: LineLayout.CURVE_LINE_AVERAGE,
        },
        {
          borderIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/lightModeIcon/right_angle_corner_line_icon.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/darkModeIcon/right_angle_corner_line_icon.svg"),
          borderStyle: MindElementType.RIGHT_ANGLE_CORNER_LINE,
          LineType: LineLayout.RIGHT_ANGLE_CORNER_LINE,
        },
        {
          borderIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/lightModeIcon/right_angle_corner_icon.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/darkModeIcon/right_angle_corner_icon.svg"),
          borderStyle: MindElementType.RIGHT_ANGLE_LINE,
          LineType: LineLayout.RIGHT_ANGLE_LINE,
        },
        {
          borderIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/lightModeIcon/full_right_angle_corner_line.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/darkModeIcon/full_right_angle_corner_line.svg"),
          borderStyle: MindElementType.FULL_RIGHT_ANGLE_CORNER_LINE,
          LineType: LineLayout.FULL_RIGHT_ANGLE_CORNER_LINE,
        },
        {
          borderIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/lightModeIcon/right_angle_corner_arrow_line_icon.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/darkModeIcon/right_angle_corner_arrow_line_icon.svg"),
          borderStyle: MindElementType.RIGHT_ANGLE_CORNER_ARROW_LINE,
          LineType: LineLayout.RIGHT_ANGLE_CORNER_ARROW_LINE,
        },
        {
          borderIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/lightModeIcon/full_right_angle_corner_arrow_line.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/darkModeIcon/full_right_angle_corner_arrow_line.svg"),
          borderStyle: MindElementType.FULL_RIGHT_ANGLE_CORNER_ARROW_LINE,
          LineType: LineLayout.FULL_RIGHT_ANGLE_CORNER_ARROW_LINE,
        },
      ];
    }
}

export default BorderLineImg