<template>
  <!-- 常规图标 -->
  <div id="general-icon-box" class="ComplieRightMenuIcon">
    <div class="MenuIcon-box">
      <!-- 优先级 -->
      <div class="MenuIcon-priority-bigbox">
        <div class="shape-icon-white shape-icon-white2 stop-propagation"></div>
        <span >{{ getString(strings.Mind_Style_Add_Icon_Priority) }}</span>
        <div class="MenuIcon-priority-box">
          <div
            v-for="(item, index) in MenufristIcons"
            :key="index"
            class="MenuIcon-priority-content"
            @click="fristAdd(item.key)"
          >
            <img :src="item.head" alt ref="priority" />
          </div>
        </div>
      </div>
      <!-- 表情 -->
      <div class="MenuIcon-priority-bigbox">
        <div class="shape-icon-white shape-icon-white2 stop-propagation"></div>
        <span >{{ getString(strings.Mind_Expression) }}</span>
        <div class="MenuIcon-priority-box">
          <div
            v-for="(item, index) in emojisIcons"
            :key="index"
            class="MenuIcon-priority-content"
            @click="fristAdd(item.key)"
          >
            <img :src="item.head" alt :ref="`emojis${index}`" />
          </div>
        </div>
      </div>
      <!-- 进度 -->
      <div class="MenuIcon-priority-bigbox MenuIcon-priority-bigbox-height" id="color-Changing-Icon-Height-Box">
        <div class="shape-icon-white stop-propagation"></div>

        <span class="position-loading-title">{{
          getString(strings.Mind_Style_Add_Icon_Progress)
        }}</span>
        <div class="MenuIcon-plan-box">
          <div v-if="!isMacComputed" class="MenuIcon-priority-right MenuIcon-priority-right-position stop-propagation">
            <div
              v-for="(item, index) in MenuIconColor"
              :key="index"
              :style="{ 'background-color': item.IconColor }"
              class="MenuIcon-chunk-color"
              @click="showPlanBgColor(index)"
            ></div>
          </div>
          <div class="MenuIcon-priority-box MenuIcon-priority-box-position">
            <div
              v-for="(item, index) in MenuPlanIcons"
              :key="index"
              class="MenuIcon-priority-content"
              @click="scheduleAdds(item.key, $event)"
            >
              <img
                v-if="isMacComputed"
                :src="item.plan"
                @click.stop="fristAdd(item.key)"
                alt
              />
              <img
                v-else
                :src="item.plan"
                alt
                :class="
                  PlanBgColor == 0
                    ? 'menuIcon-icon-data-red'
                    : PlanBgColor == 1
                    ? 'menuIcon-icon-data-yellow'
                    : PlanBgColor == 2
                    ? 'menuIcon-icon-data-green'
                    : PlanBgColor == 3
                    ? 'menuIcon-icon-data-blue'
                    : 'menuIcon-icon-data-white'
                "
              />
            </div>
          </div>
          <!-- <div class="filter-test">
              <img class="text-img" :src="MenuPlanIcons[0].plan" alt="">
            </div> -->
        </div>
      </div>
      <!-- 占位符，变色图标需要脱离文档流 -->
      <!-- <div class="MenuIcon-priority-bigbox" :style="{height:colorChangingIconHeight}"></div> -->
      <!-- 旗帜 -->
      <div class="MenuIcon-priority-bigbox">
        <div class="shape-icon-white shape-icon-white2 stop-propagation"></div>
        <span >{{ getString(strings.Mind_Flag) }}</span>
        <div class="MenuIcon-priority-box">
          <div
            v-for="(item, index) in flagIcons"
            :key="index"
            class="MenuIcon-priority-content"
            @click="fristAdd(item.key)"
          >
            <img :src="item.head" alt :ref="`flag${index}`" />
          </div>
        </div>
      </div>
      <!-- 星星 -->
      <div class="MenuIcon-priority-bigbox">
        <div class="shape-icon-white shape-icon-white2 stop-propagation"></div>
        <span>{{ getString(strings.Mind_Style_Add_Icon_Stars) }}</span>
        <div class="MenuIcon-priority-box">
          <div
            v-for="(item, index) in MenuStarIcons"
            :key="index"
            class="MenuIcon-priority-content"
            @click="starAdd(item.key)"
          >
            <img :src="item.head" alt ref="priority" />
          </div>
        </div>
      </div>
       <!-- 人像 -->
      <div class="MenuIcon-priority-bigbox">
        <div class="shape-icon-white shape-icon-white2 stop-propagation"></div>
        <span>{{ getString(strings.Mind_Portrait) }}</span>
        <div class="MenuIcon-priority-box">
          <div
            v-for="(item, index) in MenuHeadIcons"
            :key="index"
            class="MenuIcon-priority-content"
            @click="priorityAdd(item.key)"
          >
            <img :src="item.head" alt ref="priority" />
          </div>
        </div>
      </div>
      <!-- 箭头 -->
      <div class="MenuIcon-priority-bigbox">
        <div class="shape-icon-white shape-icon-white2 stop-propagation"></div>
        <span>{{ getString(strings.Mind_Arrow) }}</span>
        <div class="MenuIcon-priority-box">
          <div
            v-for="(item, index) in arrowIcons"
            :key="index"
            class="MenuIcon-priority-content"
            @click="priorityAdd(item.key)"
          >
            <img :src="item.head" alt />
          </div>
        </div>
      </div>
      <!-- 学术 -->
      <div class="MenuIcon-priority-bigbox">
        <div class="shape-icon-white shape-icon-white2 stop-propagation"></div>
        <span>{{ getString(strings.Mind_Learning) }}</span>
        <div class="MenuIcon-priority-box">
          <div
            v-for="(item, index) in mathIcons"
            :key="index"
            class="MenuIcon-priority-content"
            @click="priorityAdd(item.key)"
          >
            <img :src="item.head" alt />
          </div>
        </div>
      </div>
      <!-- 社交 -->
      <div class="MenuIcon-priority-bigbox">
        <div class="shape-icon-white shape-icon-white2 stop-propagation"></div>
        <span>{{ getString(strings.Mind_Social_Contact) }}</span>
        <div class="MenuIcon-priority-box">
          <div
            v-for="(item, index) in socialContactIcons"
            :key="index"
            class="MenuIcon-priority-content"
            @click="priorityAdd(item.key)"
          >
            <img :src="item.head" alt />
          </div>
        </div>
      </div>
      <!-- 符号 -->
      <div class="MenuIcon-priority-bigbox">
        <div class="shape-icon-white shape-icon-white2 stop-propagation"></div>
        <span>{{ getString(strings.Mind_Symbol) }}</span>
        <div class="MenuIcon-priority-box">
          <div
            v-for="(item, index) in symbolIcons"
            :key="index"
            class="MenuIcon-priority-content"
            @click="priorityAdd(item.key)"
          >
            <img :src="item.head" alt />
          </div>
        </div>
      </div>
      <!-- 月份 -->
      <div class="MenuIcon-priority-bigbox">
        <div class="shape-icon-white shape-icon-white2 stop-propagation"></div>
        <span>{{ getString(strings.Mind_Month_Icon) }}</span>
        <div class="MenuIcon-priority-box">
          <div
            v-for="(item, index) in monthIcons"
            :key="index"
            class="MenuIcon-priority-content"
            @click="priorityAdd(item.key)"
          >
            <img :src="item.head" alt />
          </div>
        </div>
      </div>
      <!-- 星期 -->
      <div class="MenuIcon-priority-bigbox">
        <div class="shape-icon-white shape-icon-white2 stop-propagation"></div>
        <span>{{ getString(strings.Mind_Style_Add_Icon_Week) }}</span>
        <div class="MenuIcon-priority-box">
          <div
            v-for="(item, index) in MenuWeekIcons"
            :key="index"
            class="MenuIcon-priority-content"
            @click="starAdd(item.key)"
          >
            <img :src="item.week" alt ref="priority" />
          </div>
        </div>
      </div>
      <!-- <div class="MenuIcon-priority-bigbox MenuIcon-priority-bigbox-height">
        <div class="shape-icon-white stop-propagation"></div>
        <span class="position-loading-title">{{
          getString(strings.Mind_Style_Add_Icon_Week)
        }}</span>
        <div class="MenuIcon-plan-box">
          <div v-if="isSafari == false" class="MenuIcon-priority-right MenuIcon-priority-right-position stop-propagation">
            <div
              v-for="(item, index) in MenuIconColor"
              :key="index"
              :style="{ 'background-color': item.IconColor }"
              class="MenuIcon-chunk-color"
              @click="showWeelBgColor(index)"
            ></div>
          </div>
          <div class="MenuIcon-priority-box MenuIcon-priority-box-position">
            <div
              v-for="(item, index) in MenuWeekIcons"
              :key="index"
              class="MenuIcon-priority-content"
              @click="
                scheduleAdd(item.key, item.styleColor, item.tintColor, $event)
              "
            >
              <img
                v-if="isSafari && !isEelctron"
                :src="item.week"
                alt
                ref="priority"
              />
              <img
                v-else
                :src="item.week"
                alt
                ref="priority"
                :class="
                  weekBgColorIndex == 0
                    ? 'menuIcon-icon-data-red'
                    : weekBgColorIndex == 1
                    ? 'menuIcon-icon-data-yellow'
                    : weekBgColorIndex == 2
                    ? 'menuIcon-icon-data-green'
                    : weekBgColorIndex == 3
                    ? 'menuIcon-icon-data-blue'
                    : 'menuIcon-icon-data-white'
                "
              />
            </div>
          </div>
        </div>
      </div> -->
      
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { mapMutations } from "vuex";
import MindIconsManager from "../../../../viewmodel/core/tools/MindIconsManager";
import Template from "../../../../views/template/Template.vue";
import strings from "../../../../common/lang/strings";
import getString from "../../../../common/lang/language";
import { iconChangedColor } from "../../../../assets/js/GlobalVariable.js";
export default {
  components: { Template },
  data() {
    return {
      MenuHeadIcons: [],
      MenuWeekIcons: [],
      MenuStarIcons: [],
      MenuPlanIcons: [],
      MenufristIcons: [],
      emojisIcons: [],
      flagIcons: [],
      arrowIcons: [],
      mathIcons: [],
      socialContactIcons: [],
      symbolIcons: [],
      monthIcons: [],
      MenuIconColor: [
        {
          IconColor: "#ff0000",
        },
        {
          IconColor: "#f76f20",
        },
        {
          // IconColor: "#00be84",
          IconColor: "#1a9c84",
        },
        {
          IconColor: "#2992c9",
        },
        // {
        //   IconColor: "#ffffff",
        // },
      ],
      PlanBgColor: "",
      weekBgColorIndex: "",
      viewModel: null,
      viewIcon: null,
      isEelctron: false,
      isMacComputed: false,
      strings: strings,
      isSafari: false, //是safari浏览器。
      colorChangingIconHeight:'89px',//变色图标的高度
    };
  },
  created() {
    //是否是mac
    this.isMacComputed = this.$tools.isMacComputed();
    this.isSafari = this.$tools.isMacSafari();
    this.isEelctron = this.$tools.isElectron();
  },
  mounted() {
    this.MenuIconForFrist();
    this.MenuIconPlan();
    this.MenuIconStar();
    this.MenuIconWeek();
    this.MenuIconHead();
    this.MenuIconEmojis();
    this.MenuIconFlag();
    this.MenuIconArrow();
    this.MenuIconMath();
    this.MenuIconSocialContact();
    this.MenuIconSymbol();
    this.MenuIconMonth();

    if (this.viewModel == null) {
      this.viewModel = this.$store.state.editMindmap;
    }
    //阻止事件冒泡
    const stopPropagation = document.getElementsByClassName("stop-propagation");
    for (const item of stopPropagation) {
      item.addEventListener('click',event=>event.stopPropagation(),false);
    }
    
  },
  methods: {
    getString(i) {
      return getString(i);
    },
    fristAdd(key) {
      this.viewModel.addIconToNode(key);
    },
    priorityAdd(key) {
      this.viewModel.addIconToNode(key);
    },
    scheduleAdd(key, styleColor, tintColor, e) {
      if (this.isSafari && !this.isEelctron) {
        let color = (r, g, b) => {
          return ((r << 16) | (g << 8) | b).toString(16);
        };
        this.viewModel.addIconToNode(key, parseInt("0x" + color(255, 0, 0)));
      } else {
        let styleName = e.target.children[0].className;

        let colorStyle = window.getComputedStyle(
          document.querySelector("." + styleName),
          null
        ).filter;
        let colorStryleAccout = colorStyle.substring(
          colorStyle.indexOf("rgb(") + 4,
          colorStyle.indexOf(")")
        );
        let r = colorStryleAccout.substring(
          colorStryleAccout.indexOf(""),
          colorStryleAccout.indexOf(",")
        );
        let g = colorStryleAccout.substring(
          colorStryleAccout.indexOf(",") + 1,
          colorStryleAccout.lastIndexOf(",")
        );
        let b = colorStryleAccout.substring(
          colorStryleAccout.lastIndexOf(",") + 1,
          colorStryleAccout.lastIndexOf("")
        );
        let color = (r, g, b) => {
          return ((r << 16) | (g << 8) | b).toString(16);
        };
        this.viewModel.addIconToNode(key, parseInt("0x" + color(r, g, b)));
      }
    },
    starAdd(key) {
      this.viewModel.addIconToNode(key);
    },
    scheduleAdds(key, e) {
      let tintColor = {
        0:iconChangedColor.red,
        1:iconChangedColor.yellow,
        2:iconChangedColor.green,
        3:iconChangedColor.blue,
      }[this.PlanBgColor];
      if (this.isSafari && !this.isEelctron) {
        let color = (r, g, b) => {
          return ((r << 16) | (g << 8) | b).toString(16);
        };

        this.viewModel.addIconToNode(key, parseInt("0x" + color(255, 0, 0)));
      } else {
        let styleName = e.target.children[0].className;

        let colorStyle = window.getComputedStyle(
          document.querySelector("." + styleName),
          null
        ).filter;
        let colorStryleAccout = colorStyle.substring(
          colorStyle.indexOf("rgb(") + 4,
          colorStyle.indexOf(")")
        );
        let r = colorStryleAccout.substring(
          colorStryleAccout.indexOf(""),
          colorStryleAccout.indexOf(",")
        );
        let g = colorStryleAccout.substring(
          colorStryleAccout.indexOf(",") + 1,
          colorStryleAccout.lastIndexOf(",")
        );
        let b = colorStryleAccout.substring(
          colorStryleAccout.lastIndexOf(",") + 1,
          colorStryleAccout.lastIndexOf("")
        );
        let color = (r, g, b) => {
          return ((r << 16) | (g << 8) | b).toString(16);
        };

        this.viewModel.addIconToNode(key, parseInt("0x" + color(r, g, b)),tintColor);
      }
    },

    MenuIconForFrist() {
      let fristIcon = MindIconsManager.mindIcons[0];
      for (var i = 0; i < fristIcon.cells.length; i++) {
        let fristIconList = fristIcon.cells[i].key;
        this.MenufristIcons.push({
          head: require("../../../../../static/img/mapContent/assets/icons/" +
            fristIconList +
            ".imageset/" +
            fristIconList +
            ".png"),
          key: fristIconList,
          title: fristIconList.title,
        });
      }
    },
    MenuIconEmojis() {
      let itemIcon = MindIconsManager.mindIcons[1];
      for (let i = 0; i < itemIcon.cells.length; i++) {
        let itemIconList = itemIcon.cells[i].key;
        this.emojisIcons.push({
          head: require("../../../../../static/img/mapContent/assets/icons/" +
            itemIconList +
            ".imageset/" +
            itemIconList +
            ".png"),
          key: itemIconList,
          title: itemIconList.title,
        });
      }
    },
    MenuIconPlan() {
      let planIcon = MindIconsManager.mindIcons[2];
      for (var i = 0; i < planIcon.cells.length; i++) {
        let planIconList = planIcon.cells[i].key;
        this.MenuPlanIcons.push({
          plan: require("../../../../../static/img/mapContent/assets/icons/" +
            planIconList +
            ".imageset/" +
            planIconList +
            ".png"),
          key: planIconList,
          title: planIconList.title,
        });
      }
    },
    MenuIconFlag() {
      let itemIcon = MindIconsManager.mindIcons[3];
      for (let i = 0; i < itemIcon.cells.length; i++) {
        let itemIconList = itemIcon.cells[i].key;
        this.flagIcons.push({
          head: require("../../../../../static/img/mapContent/assets/icons/" +
            itemIconList +
            ".imageset/" +
            itemIconList +
            ".png"),
          key: itemIconList,
          title: itemIconList.title,
        });
      }
    },
    MenuIconStar() {
      let starIcon = MindIconsManager.mindIcons[4];
      for (var i = 0; i < starIcon.cells.length; i++) {
        let starIconList = starIcon.cells[i].key;
        this.MenuStarIcons.push({
          head: require("../../../../../static/img/mapContent/assets/icons/" +
            starIconList +
            ".imageset/" +
            starIconList +
            ".png"),
          key: starIconList,
          title: starIconList.title,
        });
      }
    },
    MenuIconHead() {
      let manIcon = MindIconsManager.mindIcons[5];
      for (var i = 0; i < manIcon.cells.length; i++) {
        let manIconList = manIcon.cells[i].key;
        this.MenuHeadIcons.push({
          head: require("../../../../../static/img/mapContent/assets/icons/" +
            manIconList +
            ".imageset/" +
            manIconList +
            ".png"),
          key: manIconList,
          title: manIcon.title,
        });
      }
    },
     MenuIconArrow() {
      let manIcon = MindIconsManager.mindIcons[6];
      for (var i = 0; i < manIcon.cells.length; i++) {
        let manIconList = manIcon.cells[i].key;
        this.arrowIcons.push({
          head: require("../../../../../static/img/mapContent/assets/icons/" +
            manIconList +
            ".imageset/" +
            manIconList +
            ".png"),
          key: manIconList,
          title: manIcon.title,
        });
      }
    },
    MenuIconMath() {
      let manIcon = MindIconsManager.mindIcons[7];
      for (var i = 0; i < manIcon.cells.length; i++) {
        let manIconList = manIcon.cells[i].key;
        this.mathIcons.push({
          head: require("../../../../../static/img/mapContent/assets/icons/" +
            manIconList +
            ".imageset/" +
            manIconList +
            ".png"),
          key: manIconList,
          title: manIcon.title,
        });
      }
    },
    MenuIconSocialContact() {
      let manIcon = MindIconsManager.mindIcons[8];
      for (var i = 0; i < manIcon.cells.length; i++) {
        let manIconList = manIcon.cells[i].key;
        this.socialContactIcons.push({
          head: require("../../../../../static/img/mapContent/assets/icons/" +
            manIconList +
            ".imageset/" +
            manIconList +
            ".png"),
          key: manIconList,
          title: manIcon.title,
        });
      }
    },
    MenuIconSymbol() {
      let manIcon = MindIconsManager.mindIcons[9];
      for (var i = 0; i < manIcon.cells.length; i++) {
        let manIconList = manIcon.cells[i].key;
        this.symbolIcons.push({
          head: require("../../../../../static/img/mapContent/assets/icons/" +
            manIconList +
            ".imageset/" +
            manIconList +
            ".png"),
          key: manIconList,
          title: manIcon.title,
        });
      }
    },
    MenuIconMonth() {
      let manIcon = MindIconsManager.mindIcons[10];
      for (var i = 0; i < manIcon.cells.length; i++) {
        let manIconList = manIcon.cells[i].key;
        this.monthIcons.push({
          head: require("../../../../../static/img/mapContent/assets/icons/" +
            manIconList +
            ".imageset/" +
            manIconList +
            ".png"),
          key: manIconList,
          title: manIcon.title,
        });
      }
    },
    MenuIconWeek() {
      let WeekIcon = MindIconsManager.mindIcons[11];
      for (var i = 0; i < WeekIcon.cells.length; i++) {
        let WeekIconList = WeekIcon.cells[i].key;
        this.MenuWeekIcons.push({
          week: require("../../../../../static/img/mapContent/assets/icons/" +
            WeekIconList +
            ".imageset/" +
            WeekIconList +
            ".png"),
          key: WeekIconList,
          styleColor: WeekIcon.cells.styleColor,
          tintColor: WeekIcon.cells.tintColor,
          title: WeekIcon.title,
        });
      }
    },
    
    showPlanBgColor(index) {
      this.PlanBgColor = index;
    },
    showWeelBgColor(index) {
      this.weekBgColorIndex = index;
    },
  },
  watch: {
    "$store.state.editMindmap"(newval, olval) {
      this.viewModel = newval;
    },
  },
};
</script>
</script>
<style scoped>
.ComplieRightMenuIcon {
  margin-top: 40px;
}
/**色块 */
.MenuIcon-chunk-color {
  width: 25px;
  height: 15px;
  border-radius: 3px;
  margin: 0px 1px;
  cursor: pointer;
}
.MenuIcon-priority-right {
  position: absolute;
  right: 7px;
  display: flex;
  top: 6px;
}
.MenuIcon-chunk-color:last-of-type {
  border: 1px solid #8a8a8a;
}
/**优先级 */
.MenuIcon-priority-bigbox {
  margin-bottom: 20px;
  position: relative;
  /* overflow: hidden; */
  width: 261px;
}
.MenuIcon-priority-bigbox span {
  font-weight: 700;
  font-size: 14px;
  line-height: 25px;
  margin: 0;
}

.MenuIcon-priority-bigbox-height {
  /* width: 100%; */
  height: 119px;/* 脱离文档流，需要设置高度 和colorChangingIconHeight保持一致*/
  /* position: absolute;
  z-index: 1000; */
  /* background-color: rgba(255,0,0,1);
  margin-bottom: 72px; */
}
.position-loading-title {
  z-index: 2;
  position: absolute;
  left: 0;
  top: 0;
}
.shape-icon-white {
  position: absolute;
  width: 100%;
  height: 20px;
  /* background-color: #fff; */
  left: 0;
  top: 0;
  z-index: 2;
}
.shape-icon-white2 {
  background-color: transparent;
}
.MenuIcon-priority-box-position {
  width: 100%;
  height: 30px;
  position: absolute;
  top: 30px;
}
.MenuIcon-priority-right-position {
  position: absolute;
  right: 0;
  top: 4px;
  z-index: 2;
}

.MenuIcon-priority-box {
  display: flex;
  flex-wrap: wrap;
  /* margin-top: 15px; */
  justify-content: flex-start;
}
.MenuIcon-priority-box img {
  width: 30px;
  height: 30px;
}
.MenuIcon-priority-content {
  padding: 6px;
  cursor: pointer;
  overflow: hidden;
}
.MenuIcon-priority-content img {
  width: 30px;
  height: 30px;
}
.MenuIcon-priority-content:hover {
  /* background-color: #e0e0e0;               */
  padding: 5px;
}
.menuIcon-icon-data-red {
  filter: drop-shadow(#ff0000 0 30px);
  /* filter: drop-shadow(#d7091d 0 28px); */
  transform: translateY(-36px);
  /* margin-left: 20px; */
}
.menuIcon-icon-data-yellow {
  /* filter: drop-shadow(#fcb238 0 28px); */
  filter: drop-shadow(#f76f20 0 30px);
  transform: translateY(-36px);
}
.menuIcon-icon-data-green {
  filter: drop-shadow(#1a9c84 0 30px);
  transform: translateY(-36px);
}
.menuIcon-icon-data-blue {
  /* filter: drop-shadow(#2992c9 0 28px); */
  filter: drop-shadow(#2992c9 0 30px);
  transform: translateY(-36px);
}
.menuIcon-icon-data-white {
  filter: drop-shadow(#e0e0e0 0 30px);
  transform: translateY(-36px);
}

.filter-test {
  /* display: flex; */
  /* position: relative; */
  height: 50px;
}
.text-img {
  position: fixed;
  width: 50px;
  height: 50px;
  z-index: 99999;
  /* right: -20px;
  filter: drop-shadow(#e0e0e0 -100px 0); */
  left: -100px;
  filter: drop-shadow(#e0e0e0 100px 0);
}
</style>
