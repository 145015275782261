<template>
  <div class="wzc_select" :style="styleVar">
    <div class="divSelect" :class="{ drop_down: isListShow }" ref="divSelect">
      <div class="divSelectinput" @click="dropDownSelect">
        <!-- 选中后的内容 -->
        <input
          class="selectinfos"
          type="string"
          @focus="prohibitFast"
          @blur="loseFocus"
          :id="inputIdName"
          v-on:input="changeInputBox()"
          :title="label"
          :value="label"
          :class="{ no_select: label == '请选择'}"
        >
          <!-- {{ label }} -->
        <!-- </div> -->
        <!-- 三角形图标 -->
        <!-- <i class="imgthree fa fa-caret-up" :class="{ 'is-reverse': isListShow }"></i> -->
        <a-icon
          class="imgthree"
          :class="{ 'is-reverse': isListShow }"
          type="caret-up"
        />
      </div>
    </div>
    <!-- 下拉框列表 -->
    <transition name="drop-down">
      <div class="Selectlist" v-show="isListShow" ref="dropDown">
        <!-- <div class="select_triangle"></div> -->
        <ul class="wzc_option_list" ref="wzcOptionList" id="sizeScroll">
          <slot name="wzc_option"></slot>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
  name: "wzc_select",
  components: {},
  props: {
    placeholder: {
      type: String,
      default: 1,
    },
    width: {
      type: Number,
      default: 180,
    },
    height: {
      type: Number,
      default: 40,
    },
    maxHeight: {
      default: -1,
      type: Number,
    },
    itemList: {
      default: Array,
      type: Array,
    },
    inputIdName:{
      type: String,
      default: '',
    }
  },
  data() {
    return {
      label: "",
      isListShow: false,
      optionid: "",
      // isDarkColor:false,
    };
  },
  created() {
    this.label = this.placeholder;
  },
  mounted() {
    let _this = this;
    document.addEventListener("click", function (e) {
      if (_this.$refs.divSelect) {
        if (
          !!_this.$refs.divSelect.contains(e.target) ||
          !!_this.$refs.dropDown.contains(e.target)
        )
          return;
        else _this.isListShow = false;
      }
    });
    this.selectScroll();
    this.setDardarkMode();
  },
  computed: {
    styleVar() {
      return {
        "--select-height": this.height + "px",
        "--select-width": this.width + "px",
        "--option-max-height":
          this.maxHeight == -1 ? "100%" : this.maxHeight + "px",
      };
    },
  },
  methods: {
    ...mapMutations([
      "useShortcut",
    ]),
    dropDownSelect() {
      this.isListShow = !this.isListShow;
      if (this.isListShow) {
        this.selectScroll();
      }
    },
    selectScroll() {
      if (this.itemList.length > 0) {
        let vul = 3;
        let count = 0;
        let index = 0;
        for(var i = 0; i< this.itemList.length; i++){
          if(this.itemList[i].name >= this.label){
            index = i;
            count = (i - vul) * 34;
            break;
          }
        }
        if (index > vul) {
          this.$nextTick(() => {
            setTimeout(() => {
              var scrollObj = this.$el.querySelector(".wzc_option_list");
              scrollObj.scrollTop = count;
            }, 20);
          });
        }
      }
    },
    setDardarkMode() {
      // 设置深色模式
      this.$nextTick(() => {
        let boxDom = this.$refs.wzcOptionList;
        let dardarkMode = this.$store.state.mapEditIsDardarkMode;
        let inputObj = document.getElementById(this.inputIdName);
        if (boxDom) {
          if (dardarkMode.darkMode) {
            boxDom.style.borderColor = 'rgba(255,255,255,0.2)';
            boxDom.style.background = dardarkMode.bgColor;
            inputObj.style.background = dardarkMode.bgColor;
          } else {
            boxDom.style.borderColor = "#e4e7ed";
            boxDom.style.background = "#fbfbfb";
            inputObj.style.background = "#fff";
          }
        }
      });
    },
    changeInputBox(){
      let obj = document.getElementById(this.inputIdName);
      
      if(obj.value == "" || obj.value == null  || obj.value == NaN){
        return;
      }
      this.$emit('changeInputVal', obj.value);
    },
    prohibitFast(){
      this.useShortcut(false);
    },
    loseFocus(){
      this.useShortcut(true);
    },
  },
  watch: {
    placeholder: {
      handler(newVal, oldVal) {
        this.label = newVal;
      },
    },
    "$store.state.mapEditIsDardarkMode": {
      //导图编辑页面是否为深色模式
      handler(newVal, oldVal) {
        this.setDardarkMode();
      },
      deep: true,
    },
  },
};
</script>
<style scoped lang="less">
.wzc_select {
  border: 1px solid var(--rMDropDownListBorderColor);
  /* border-radius: 5px; */
  height: var(--select-height);
  width: var(--select-width);
  line-height: var(--select-height);
}
.divSelect {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.drop_down {
  /* box-shadow: 0px 0px 6px #eee; */
}
.divSelectinput {
  width: calc(100%);
  height: 100%;
  padding: 0 5px 0 5px;
  display: flex;
}
.selectinfos {
  width: 87.5%;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 0px;
}
.selectinfos:focus {//获取焦点
  outline: 0px solid #51c9ff;//边框不用border，用outline
  // background: rgba(3, 16, 28, 0);//背景色
}
.no_select {
  color: #d3dce6;
}
// .dark_color{
//   color:#000000
// }
.imgthree {
  line-height: var(--select-height);
  text-align: center;
  transform: rotate(180deg);
  transition: all 0.3s;
}
.imgthree:before {
  cursor: pointer;
  color: #d3dce6;
}
.imgthree.is-reverse {
  transform: rotate(0deg);
}

.Selectlist {
  margin-top: 0px;
  z-index: 800;
  position: relative;
  // background-color: #fff;
}
.wzc_option_list {
  border-radius: 5px;
  border: 1px solid #e4e7ed;
  width: 100%;
  padding: 3px 0px;
  /* box-shadow: 0px 0px 6px #eee; */
  background-color: #fff;
  margin: 0;
  max-height: var(--option-max-height);
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background: rgb(239, 239, 239);
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background: #bfbfbf;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #bfbfbf;
  }
}
/* .select_triangle {
        width: 14px;
        height: 7px;
        position: relative;
        left: 15px;
    }
    .select_triangle::before {
        position: absolute;
        content: "";
        left: 0px;
        width: 0;
        height: 0;
        border-top: 0px solid transparent;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-bottom: 8px solid #EBEEF5;
    }
    .select_triangle::after {
        position: absolute;
        left: 2px;
        top: 2px;
        content: "";
        width: 0;
        height: 0;
        border-top: 0px solid transparent;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 8px solid #fff;  
    } */
.drop-down-enter {
  opacity: 0;
  transform: translate(0px, -80px) scaleY(0.2);
}
.drop-down-leave-to {
  opacity: 0;
  transform: translate(0px, -80px) scaleY(0.2);
}
.drop-down-enter-active {
  transition: all 0.3s ease-in;
}
.drop-down-leave-active {
  transition: all 0.3s ease;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
input[type="number"]{
    -moz-appearance: textfield;
}
</style>
