<template>
    <div @click.stop="hidden(false)" v-show="showModal" class="group-add-new-group-box">
        <div @click.stop="hidden(true)" class="center-writer">
            <div class="group-setting-administrator-header">
                <!-- <div class="administrator-header-left">
                </div> -->
                <div class="administrator-header-main">
                    {{ "共享组列表" }}
                </div>
                <div  class="administrator-header-right">
                    <img class="hidden-icon close-button-img" style="margin-top: 5px;" @click.stop="hidden(false)" src="../../../assets/img/popupsImg/colse_new.svg" alt="">
                </div>
            </div>
            <div class="group-name-list" id="groupNameList" >
                <div class="menu-item-child-box" id="groupNameListChidren" > 
                    <div v-for="(item,index) in myGroupData" @click.stop="checkGroupFile(item)" :key="index"
                    class="group-message-box clearfix"
                    :class="checkGroupData!=null && checkGroupData.id == item.id ? 'group-message-box-check' : ''">
                        <div
                            class="group-new-img"
                            :style="{
                                    width: 45 + 'px',
                                    height: 45 + 'px',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center center',
                                    overflow: 'hidden',
                                    backgroundImage: 'url('+ httpImageToPrefix(item.cover) +')',
                                }"
                        >
                            <div class="group-selected-img" v-if="checkGroupData!=null && checkGroupData.id == item.id">
                                <img src="../../../assets/img/groupImg/selectActive.svg" alt="">
                            </div>
                        </div>
                        <div class="group-new-message">
                            <div class="group-name"> 
                                <div class="group-name-text">
                                {{ item.name }}
                                </div>
                                <div v-if="item.isTop == true " class="group-top">
                                    {{ $getStringObj.getString($Strings.Mind_Group_Stick) }}
                                </div>
                            </div>
                            <div class="group-new-time">
                                {{ timestampToTimeDHM(item.updateTime ) }}
                            </div>
                            <div class="group-new-time">
                                {{ item.subtitle }}
                            </div>
                        </div>
                        <!-- <div class="more-menu">
                            <img class="more-menu-icon" src="../../../assets/img/groupImg/moreMenu.png"  alt="">
                        </div> -->
                        
                        <div v-if="item.unread  == true " class="message-hint">
                            <img src="../../../assets/img/groupImg/redFlag.png" alt="">
                            {{ $getStringObj.getString($Strings.Mind_Group_There_Is_A_New_Content) }}
                        </div> 

                        
                        <div v-if="index != myGroupData.length - 1" class="group-bottom-line">
                        </div>
                    </div>

                </div>
            </div>
              <div class="move-footer">
                  <div @click.stop="hidden(false)" class="button-class button-class-colse">
                      {{$getStringObj.getString($Strings.Global_Cancel)}}
                  </div>
                  <div @click.stop="addMindGroupBefore()" class="button-class button-class-ok">
                      {{$getStringObj.getString($Strings.Global_Ok)}}
                  </div>
                <!-- <button @click.stop="hideMoveFile(false,1)">确定</button> <button  @click.stop="hideMoveFile(false,2)">取消</button> -->
              </div>
        </div>
    </div>
</template>

<script>
// import { postGroupMembers, postGroupSearchGmembers, postGroupDeleteGmembers} from "../../../common/netWork/group_api";
import { mapMutations } from "vuex";
import { postGroupMyGroups, postGroupImportGmap } from "../../../common/netWork/group_api";

import httpImageToPrefix from '../../../utils/httpImageToPrefix';
import timestampToTime from "../../../utils/timestampToTime";
// import GroupMembersModalMenu from "./GroupMembersModalMenu";
// import GroupInviteRembers from "./GroupInviteRembers";
// import GroupremoveMembersAlter from "./GroupremoveMembersAlter"
export default {
    components: {
        // GroupMembersModalMenu,
        // GroupInviteRembers,
        // GroupremoveMembersAlter
    },
    props: {
        show: {
            type: Boolean,
            defualt() {
                return false;
            },
        },
        mindItem: {
            type: Object,
            defualt() {
                return {};
            },
        },
    },
    data() {
        return {
            showModal: false,
            isScroll: true, //当前是否可以拖动
            myGroupData: [], //我的共享组数据
            page: 1, //当前所在的分页
            checkGroupData: null,
        }
    },
    created() {
        // console.log('1',this.$getStringObj.getString(this.$Strings.Mind_Group_Invite_The_Successful));
        // console.log(this.$Strings.Mind_Group_Immediately_Create);
        // {{ this.$Strings.Mind_Group_Immediately_Create }}
    },
    mounted() {
    },
    beforeUpdate() {    //每次打开弹窗执行

    },
    methods: {
        // ...mapMutations([
        //     "setgroupAdminMenu",
        // ]),

        pullMyGroup(obj) {
            return new Promise((resolve, reject) => {
                postGroupMyGroups(
                obj,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    this.$message.error(error.desc);
                    reject(error);
                }
                );
            });
        },

        addMindGroup(obj) {
          //获取当前文件夹信息
          // let that = this;
          return new Promise((resolve, reject) => {
            postGroupImportGmap(
              obj,
              (res) => {
                resolve(res);
              },
              (error) => {
                this.$message.error(error.desc);
                reject(error);
              }
            );
          });
        },

        refreshList() { //刷新我的共享组
            this.page = 1;
            this.pullMyGroup({page: this.page}).then(res => {
                // this.showDataType = 'myGroup'
                //如果群组有数据就先展示第一个数据。
                if(res != null && res.myGroups != null && res.myGroups.length > 0){
                    this.myGroupData = res.myGroups ;
                    // this.setGroupData = res;
                } else {
                    this.myGroupData = [] 
                }

                this.$nextTick(() => {
                    let scrollDom = document.getElementById("groupNameList");
                    if (scrollDom != undefined) {
                        scrollDom.removeEventListener("scroll", this.scrollMoreData); //解除绑定
                        scrollDom.addEventListener("scroll", this.scrollMoreData, false); //添加绑定
                    }
                });

            })
        },

        scrollMoreData() {
            //滚动事件
            let scrollDom = document.getElementById("groupNameList");
            let child = document.getElementById("groupNameListChidren");
            if( scrollDom == null || child == null) {
                return;
            }
            const scrollTopHeight = scrollDom.scrollTop ; //滚动高度
            const scrollHeight = scrollDom.clientHeight; //父盒子高度
            const childHeight = child.offsetHeight ; //网页可见区域高(包括边线的宽)
            if (
                scrollTopHeight + scrollHeight >= childHeight &&
                this.isScroll
            ) {
                this.isScroll = false;
                this.scrollPullList()
            }
        },

        scrollPullList() {
            let obj = { page: this.page + 1 };
            this.pullMyGroup(obj).then(res => {

                if (res !=  null && res.myGroups != null && res.myGroups.length > 0) {
                  this.page++;
                  this.myGroupData = this.myGroupData.concat(res.myGroups);
                }

            }).finally(res=> {
                this.isScroll = true;
            })
        },

        httpImageToPrefix(src) {    //拼接图片地址
           return httpImageToPrefix(src)
        },

        timestampToTimeDHM(timestamp) {
            return timestampToTime.formatTime(timestamp);
        },

        checkGroupFile(item) {
            this.checkGroupData = item;
            // let that = this;
            // this.$confirm({
            //     title: "添加到共享组",
            //     content: "确定要添加吗？" ,
            //     centered: true,
            //     style: {
            //     borderRadius: "20px",
            //     },
            //     onOk() {

            //     // let obj = {};
            //     // obj.entityId = val.item.id;
            //     // that
            //     //     .deletFile(obj)
            //     //     .then((data) => {
            //     //     that.$message.success("删除成功");
            //     //     return that.pullAllMind(that.fileObj.id);
            //     //     })
            //     //     .then((res) => {
            //     //     that.data = res.entities;
            //     //     });


            //     },
            //     onCancel() {},
            // });


        },


        addMindGroupBefore() {
            if (this.checkGroupData == null) {
              this.$message.warning('请选择需要上传的文件');
              return
            }
            let parentId = '';
            const obj = { 
              groupId: this.checkGroupData.id, 
              mindMapId: this.mindItem.mindMapId, 
              parentId: parentId 
            }
            if (this.$route.name == "Complie" || this.$route.name == "arvhiveMap") {//从导图，归档导图中导入到共享组
               obj.mindMapId = this.mindItem.id;
            }
            // console.log(obj);
            this.addMindGroup(obj).then(res => {
                this.hidden(false);
                this.$message.success(this.$getStringObj.getString(this.$Strings.Added_Successfully));
            })
        },

        //隐藏模态框
        hidden(show) {
            this.showModal = show
                if (show == false) {
                let item = {
                    show: false
                }
                this.$emit("hiddenMindAddGroup", item);
            }
        },
    },
    watch: {
        show(newVal) {
            this.showModal = newVal

            if (newVal == true) { //显示模态框请求一次数据
                // this.refreshGroupMembers();
                this.refreshList();
                this.checkItem = null
            }
        },
    }
}
</script>

<style lang="less" scoped>
    .group-add-new-group-box {
        position: fixed;
        left: 0; 
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.1);
        z-index: 1000;
        .center-writer {
            width: 614px;
            min-height: 400px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            background-color: #fff;
            border-radius: 10px;
            .group-setting-administrator-header{
                background: #efefef;
                display: flex;
                padding: 6px 11px 6px 20px;
                border-radius: 10px 10px 0 0;
                .administrator-header-left{
                    flex: 0 0 100px;
                    font-size: 18px;
                }

                .administrator-header-main{
                    flex: 1;
                    width: 100%;
                    // text-align: center;
                    color: #333;
                    font-size: 18px;
                    font-weight: 550;
                }

                .administrator-header-right{
                    flex: 0 0 100px;
                    text-align: right;
                    font-size: 12px;
                    img {
                        margin-right: 0px;
                    }
                    .hidden-icon {
                        cursor: pointer;
                    }
                }

            }
            .close-modal {
                position: absolute;
                right: 0;
                top: 0;
                width: 40px;
                height: 40px;
                text-align: center;
                line-height: 40px;
                cursor: pointer;
                font-size: 16px;
                color: #333;
                border-radius: 0 10px 0 0;
            }

            .group-name-list {
                margin-top: 20px;
                height: 300px;
                overflow-y: auto;
                background: #f8f8f8;
                width: 574px;
                margin-left: 20px;
                .menu-item-child-box{
                    .group-message-box {
                        padding-top: 11px;
                        padding-bottom: 11px;
                        padding-left: 20px;
                        padding-right: 11px;
                        display: flex;
                        position: relative;
                        align-items: center;
                        cursor: pointer;
                        background: #f8f8f8;
                        .group-new-img{
                            display: block;
                            width: 53px;
                            height: 53px;
                            border-radius: 4px;
                            position: relative;
                            .group-selected-img{
                                width: 100%;
                                height: 100%;
                                background: rgba(0, 0, 0, .15);
                                position: absolute;
                                top: 0;
                                left: 0;
                                z-index: 2;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                        }
                        .group-new-message{
                            margin-left: 10px;
                            .group-name {
                                display: flex;
                                align-items: center;
                                .group-name-text {
                                    max-width: 251px;
                                    overflow: hidden;
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                    font-size: 14px;
                                    color: #666666;
                                }
                                .group-top{
                                    font-size: 10px;
                                    color: #FD492B;
                                    background-color: rgba(0, 0, 0, 0.05);
                                    border-radius: 2px;
                                    padding-top: 3px;
                                    padding-bottom: 3px;
                                    padding-left: 6px;
                                    padding-right: 6px;
                                    margin-left: 16px;
                                }
                            }
                            .group-new-time {
                                max-width: 251px;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                font-size: 10px;
                                color:#999999;
                            }

                        }
                        .more-menu {
                            display: block;
                            position: absolute;
                            right: 11px;
                            top: 11px;

                            .more-menu-icon {
                                position: absolute;
                                display: block;
                                right: 0;
                                top: 0;
                                width: 20px;
                                height: 20px;
                            }
                        }
                        .message-hint{
                            display: block;
                            position: absolute;
                            right: 15px;
                            bottom: 11px;
                            font-size: 10px;
                            color: #FD492B;
                            img {
                                margin-right: 3px;
                            }
                        }

                        .group-bottom-line {
                            width: 100%;
                            height: 1px;
                            position: absolute;
                            right: 0;
                            bottom: 0;
                            background-color: #eee;
                        }
                        &:hover {
                            background-color: #f1f1f1;
                        }
                    }
                    .group-message-box-check {
                        background-color: #ebebeb;
                        border: 1px solid #FD492B;
                    }
                }
                &::-webkit-scrollbar{
                    width: 3px;
                    // display: none;
                }
                &::-webkit-scrollbar-track {
                    background: rgb(239, 239, 239);
                    border-radius: 2px;
                }
                &::-webkit-scrollbar-thumb {
                    background: #bfbfbf;
                    border-radius: 5px;
                }
                &::-webkit-scrollbar-thumb:hover {
                    background: #bfbfbf;
                }
            }


            .move-footer {
                display:flex;
                justify-content: center;
                .button-class {
                    margin: 0 20px;
                    margin-top: 10px;
                    margin-bottom: 20px;
                    width: 60px;
                    height: 30px;
                    border-radius: 2px;
                    text-align: center;
                    line-height: 30px;
                    font-size: 16px;
                    // background-color: #F0F2F8;
                    cursor: pointer;
                }
                // .button-class:hover {
                //     background-color: #ff7354;
                //     color: #fff;
                // }
                .button-class-ok{
                    background: #FD492B;
                    color: #fff;
                }
                .button-class-colse{
                    border: 1px solid #F0F2F8;
                    color: #999999;
                // background-color: #F0F2F8;
                }
                .button-class-colse:hover {
                    color: #FD492B;
                    border: 1px solid #FD492B;
                    // background-color: #ff7354;
                    // color: #fff;
                }
                .button-class-ok:hover{
                    background: #ff7354;
                }
            }


        }

    }
.close-button-img{
    width:18px;
    cursor: pointer;
    opacity: 0.5;
}
.close-button-img:hover{
  opacity: 0.8;
}
</style>