<template>
  <div class="complie-header-menu">
    <div
      v-if="initMindGroupViewData != null"
      class="group-view-header-left-icon"
    >
      <img :src="httpImageToPrefix(initMindGroupViewData.groupCover)" alt="" />
      <div>{{ initMindGroupViewData.groupName }}</div>
    </div>
    <section
      v-if="true"
      v-clickoutside="outsideCloseSwitchMap"
      style="margin-left: 10px"
    >
      <div
        class="each-button-box"
        ref="switchbuttonboxchild"
        @click="switchMapMenu($event)"
      >
        <div class="each-button-box-img">
          <img
            class="each-button-box-center-img"
            src="../../assets/img/mindNewEditMenuIocn/cutMind.svg"
            alt=""
          />
        </div>
        <div class="each-button-box-text">
          {{ $getStringObj.getString($Strings.Mind_Edit_Page_Switch_Mapping) }}
        </div>
      </div>
      <ComplieSwitchMapMenu
        :showSwitchMapMenu="showSwitchMapMenu"
        :gmap="gmap"
        :groupId="initMindGroupViewData.groupId"
        :parentId="parentId"
        :menuPoint="menuPoint"
        :pointObj="pointObj"
        @outsideCloseSwitchMap="outsideCloseSwitchMap"
      />
    </section>
    <div class="group-view-header-right">
      <!-- <NewTemplateTest /> -->
      <div
        v-if="
          initMindGroupViewData != null && initMindGroupViewData.status != ''
        "
        class="group-content-box"
      >
        <img src="../../assets/img/gMap/now-update.png" alt="" />
        <div>{{ initMindGroupViewData.status }}</div>
      </div>
      <img
        @click="mindmapPlay()"
        class="group-play"
        src="../../assets/img/gMap/play-mind.svg"
        alt=""
      />

      <div
        v-if="!getMindmapPattern()"
        @click="setAccoutTitel(0)"
        class="group-lineout-icon mindicon"
      >
        &#58884;
      </div>
      <div
        v-else
        @click="setAccoutTitel(1)"
        class="group-lineout-icon iconfont"
      >
        &#58882;
      </div>
      <div @click="groupUpdate()" class="group-update">
        <div>{{ $getStringObj.getString($Strings.Global_Edit) }}</div>
      </div>
      <div v-clickoutside="outsideClose" class="group-update-menu">
        <img
          @click="checkMenu($event)"
          src="../../assets/img/gMap/more-menu.svg"
          alt=""
        />
        <ComplieHeaderMoreMenu
          :showMenu="showMenu"
          :showData="menuList"
          :menuPoint="menuPoint"
          :pointObj="pointObj"
          @checkMenuItem="checkMenuItem"
        />
      </div>
    </div>

    <ComplieMindmapPlayModal />

    <!-- 举报模态框 -->
    <a-modal
      class="report-crisps-model"
      :centered="true"
      v-model="reportCrispsVisible"
      :title="getString($Strings.Mind_Storm_Report)"
      @ok="reportCrispsHandleOk"
      :cancelText="getString($Strings.Global_Cancel)"
      :okText="getString($Strings.Global_Ok)"
    >
      <div class="report-crisps-style-box">
        <div
          class="report-crisps-style"
          v-for="(item, index) in reportCrispsContentList"
          :key="index"
          @click="reportCrispsClick(index)"
        >
          <div class="report-crisps-left">
            {{ getString(item) }}
          </div>
          <div class="report-crisps-right">
            <div
              class="report-crisps-defualt-click"
              :class="
                reportCrispsContentListNumber == index
                  ? 'report-defualt-click'
                  : ''
              "
            ></div>
            <img
              v-if="reportCrispsContentListNumber == index"
              src="../../../static/img/userData/crisps-reporet-click.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </a-modal>

    <GroupFileChangeHistory
      v-show="showModal"
      :showModal="showModal"
      :initMindData="initMindGroupViewData"
      @hiddenModal="hiddenModal"
    />
    <!-- <ComplieHeaderMoreMenu :showMenu="showMenu" :showData="menuList" :menuPoint="menuPoint" :pointObj="pointObj" @checkMenuItem="checkMenuItem"  /> -->
  </div>
</template>
<script>
import { mapMutations } from "vuex";

import MindOperateUIControllerView from "./tools/MindOperateUIControllerView";

import ComplieAudio from "./ComplieHeader/ComplieAudio.vue";
import ComplieHeaderImg from "./ComplieHeader/ComplieHeaderImg";
import ComplieHeaderMind from "./ComplieHeader/ComplieHeaderMind";
import CommonBgColor from "../common/CommonBgColor/CommonBgColor.vue";
import ComplieChildMenuTheme from "./ComplieHeader/ComplieChildMenuTheme";
import ComplieRightMenuIcon from "./ComplieRightMenuIcon/ComplieRightMenuIcon.vue";
import ComplieMindmapPlayModal from "./ComplieHeader/ComplieMindmapPlayModal";

import Logger from "../../utils/Logger";
import colorChangeClass from "../../utils/ColorToSystem";

import strings from "../../common/lang/strings";
import getString from "../../common/lang/language";

import Point from "../../viewmodel/core/base/Point";
import MindType from "../../viewmodel/datatype/MindType";
import MindElementType from "../../viewmodel/datatype/MindElementType";
import EditMindmapVM from "../../viewmodel/mindelementdata/vm/EditMindmapVM";
import { postMindmapPlay } from "../../common/netWork/mind_map_api";

import ComplieHeaderMoreMenu from "./ComplieHeader/ComplieHeaderMoreMenu";
import NewTemplateTest from "./newTemplateTest";
import httpImageToPrefix from "../../utils/httpImageToPrefix";
import ToImage from "../../utils/ToImage";
import MindDisplayType from "../../viewmodel/datatype/MindDisplayType";

import {
  postGroupReportGfile,
  postGroupSaveGmap,
  postGroupGmapEditPing,
} from "../../common/netWork/group_api";
import ExportFile from "../../core/core/dataformat/exportFile/ExportFile"
import ConfigurationItem from "../../assets/js/ConfigurationItem.js";
const { menuListType } = ConfigurationItem;
import GroupFileChangeHistory from "../groupManagementChildren/groupFile/GroupFileChangeHistory";
import ComplieSwitchMapMenu from "./ComplieHeader/ComplieSwitchMapMenu";

export default {
  props: {
    initMindGroupViewData: {
      type: Object,
      defualt() {
        return {};
      },
    },
    parentId: String,
    gmap: {
      type: Boolean,
      defualt() {
        return false; //gmap: true为共享组，false为普通导图
      },
    },
  },
  data() {
    return {
      nowElectron: false,
      //线性结构
      headerMenuStyle: [
        {
          menuStyleIcon: require("../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/headerMenuIcon (1).png"),
          menuText: getString(strings.Mind_Edit_Revoke),
          menuStyle: MindElementType.BACKWARD_RETREAT,
          // imgHeight:
        },
        {
          menuStyleIcon: require("../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/headerMenuIcon (2).png"),
          menuText: getString(strings.Mind_Edit_Restore),
          menuStyle: MindElementType.FORWARD,
        },
        {
          menuStyleIcon: require("../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/headerMenuIcon (3).png"),
          menuText: getString(strings.Mind_Edit_Subtopic),
          menuStyle: MindElementType.ADD,
        },
        {
          menuStyleIcon: require("../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/headerMenuIcon (4).png"),
          menuText: getString(strings.Mind_Edit_Top_Subtopic),
          menuStyle: MindElementType.ADD_BROTHER_TOP,
        },
        {
          menuStyleIcon: require("../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/headerMenuIcon (5).png"),
          menuText: getString(strings.Mind_Edit_Bottom_Subtopic),
          menuStyle: MindElementType.ADD_BROTHER_BOTTOM,
        },
        {
          menuStyleIcon: require("../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/headerMenuIcon (6).png"),
          menuText: getString(strings.Mind_Edit_Free_Node),
          menuStyle: MindElementType.FREE_NODE,
        },
        {
          menuStyleIcon: require("../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/headerMenuIcon (7).png"),
          menuText: getString(strings.Mind_Edit_Summary),
          menuStyle: MindElementType.CONTENT_GENERALIZATION,
        },
        {
          menuStyleIcon: require("../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/headerMenuIcon (8).png"),
          menuText: getString(strings.Mind_Edit_Block),
          menuStyle: MindElementType.BAOWEI_VIEW,
        },
      ],
      //气泡 时间线结构
      headerBubbelMenuStyle: [
        {
          menuStyleIcon: require("../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/headerMenuIcon (1).png"),
          menuText: getString(strings.Mind_Edit_Revoke),
          menuStyle: MindElementType.BACKWARD_RETREAT,
        },
        {
          menuStyleIcon: require("../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/headerMenuIcon (2).png"),
          menuText: getString(strings.Mind_Edit_Restore),
          menuStyle: MindElementType.FORWARD,
        },
        {
          menuStyleIcon: require("../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/headerMenuIcon (3).png"),
          menuText: getString(strings.Mind_Edit_Subtopic),
          menuStyle: MindElementType.ADD,
        },
        {
          menuStyleIcon: require("../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/headerMenuIcon (4).png"),
          menuText: getString(strings.Mind_Edit_Top_Subtopic),
          menuStyle: MindElementType.ADD_BROTHER_TOP,
        },
        {
          menuStyleIcon: require("../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/headerMenuIcon (5).png"),
          menuText: getString(strings.Mind_Edit_Bottom_Subtopic),
          menuStyle: MindElementType.ADD_BROTHER_BOTTOM,
        },
        {
          menuStyleIcon: require("../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/headerMenuIcon (6).png"),
          menuText: getString(strings.Mind_Edit_Free_Node),
          menuStyle: MindElementType.FREE_NODE,
        },
      ],
      //大纲模式
      headerOutline: {
        leftMenu: [
          {
            menuStyleIcon: require("../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/indent.svg"),
            menuText: strings.Outline_Menu_Indent,
            menuStyle: MindElementType.NODE_MOVE_BACKWARD,
          },
          {
            menuStyleIcon: require("../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/decrease_indent.svg"),
            menuText: strings.Outline_Menu_Decrease_Indent,
            menuStyle: MindElementType.NODE_MOVE_FORWARD,
          },
          {
            menuStyleIcon: require("../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/headerMenuIcon (7).png"),
            menuText: strings.Mind_Edit_Summary,
            menuStyle: MindElementType.CONTENT_GENERALIZATION,
          },
        ],

        fontStyleMenu: [
          {
            menuStyleIcon: "&#58885", //require("../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/font_style_b.svg"),
            menuText: strings.Font_Style_Bold,
            fontStyleAccout: false,
          },
          {
            menuStyleIcon: "&#58886", //require("../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/font_style_i.svg"),
            menuText: strings.Font_Style_Italics,
            fontStyleAccout: false,
          },
          {
            menuStyleIcon: "&#58887", //require("../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/font_style_u.svg"),
            menuText: strings.Font_Style_Delete_Line,
            fontStyleAccout: false,
          },
        ],

        // {
        //   menuStyleIcon: require("../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/outline_delete.svg"),
        //   menuText: strings.Mind_Mindmap_Deleted_Forever,
        //   menuStyle: "Delete_Node",
        // },
      },
      //对齐菜单
      alignmentMenu: [
        {
          alignIcon: require("../../assets/img/mapIcon/res/mipmap-icon/mindmap-header-align-icon/align_top.svg"),
          aligntype: "topAlignment",
        },
        {
          alignIcon: require("../../assets/img/mapIcon/res/mipmap-icon/mindmap-header-align-icon/align_center.svg"),
          aligntype: "centreHorizontalAlignment",
        },
        {
          alignIcon: require("../../assets/img/mapIcon/res/mipmap-icon/mindmap-header-align-icon/align_button.svg"),
          aligntype: "bottomAlignment",
        },
        {
          alignIcon: require("../../assets/img/mapIcon/res/mipmap-icon/mindmap-header-align-icon/align_vertical.svg"),
          aligntype: "averageDistributionVertical",
        },
        {
          alignIcon: require("../../assets/img/mapIcon/res/mipmap-icon/mindmap-header-align-icon/align_left.svg"),
          aligntype: "leftAlignment",
        },
        {
          alignIcon: require("../../assets/img/mapIcon/res/mipmap-icon/mindmap-header-align-icon/align_vertical_center.svg"),
          aligntype: "centreVerticalAlignment",
        },
        {
          alignIcon: require("../../assets/img/mapIcon/res/mipmap-icon/mindmap-header-align-icon/align_right.svg"),
          aligntype: "averageDistributionHorizontal",
        },
        {
          alignIcon: require("../../assets/img/mapIcon/res/mipmap-icon/mindmap-header-align-icon/align_center_hotizontally.svg"),
          aligntype: "rightAlignment",
        },
      ],
      viewModel: null,
      MindOperateUIControllerView: null,
      iconCompoenntAccout: false,
      mindComponentAccout: false,
      mindStyleComponentAccout: false,
      mindAudioComponentAccout: false,
      headerMenuAccout: {
        linelayout: false,
        timelayout: false,
        bubbelLayout: false,
        doubelBubbleLayout: false,
        outlineLayout: false,
      }, //当前导图类型
      rightMenuIcon: [
        {
          Icon: "&#58882;",
          text: getString(strings.Public_Header_Outline),
          type: "OutlineOrMindmap",
        },
        // {
        //   Icon: "&#58919;",
        //   text: getString(strings.Mind_Edit_Right_Icon),
        //   type: "Icon",
        // },
        // {
        //   Icon: "&#58643;",
        //   text: getString(strings.Mind_Edit_Right_Style),
        //   type: "Style",
        // },
      ], //右侧菜单Icon
      rightMenuIconOutline: [
        {
          Icon: "&#58884;",
          text: getString(strings.Public_Header_Mindmap_Mind),
          type: "OutlineOrMindmap",
        },
      ], //大纲模式下
      menuIconClickIndex: 2, //Icon索引
      menuIconClickAccout: true, //菜单是否显示
      isMac: false, //是否为mac os环境
      hideOutlineAccout: false, //是否为大纲模式
      strings: strings, //语言包
      isShowAlignButton: false, //显示对齐按钮
      isShowOutline: false, //是否显示大纲
      isShowIconDetialsMenu: false, //是否显示图标菜单
      isShowFontColorModal: false, //是否显示字体颜色面板
      fontTextColor: "#ffffff", //文字颜色
      nodeAccout: null, //节点数据
      editAccout: {
        text: "",
        url: "",
      }, //保存状态
      routerData: this.$router.history.current.name, //路由状态
      mindmapPlayButton: false, //播放状态(true播放/false关闭)
      isShowMindmapPlaySetUp: false, //显示播放导图模态框
      nowElectron: false, //当前是否在electron环境中\

      showMenu: false, //展示菜单
      menuList: [], //菜单要展示的内容
      pointObj: null, //节点dom数据
      menuPoint: null, //菜单的位置
      // titelAccout: 0, //当前时导图还是大纲。
      editMindmapContent: EditMindmapVM, //vm对象

      reportCrispsVisible: false, //举报面板
      reportCrispsContentList: [
        //举报理由列表
        this.$Strings.Mind_Storm_Pornographic, //色情低俗
        this.$Strings.Mind_Storm_Advertising_Harassment, //广告骚扰
        this.$Strings.Mind_Storm_Political_Religion, //政治宗教
        this.$Strings.Mind_Storm_Infringement, //侵权（肖像、诽谤、抄袭、冒用）
        this.$Strings.Mind_Storm_Prohibited_Content, //违禁内容
        this.$Strings.Mind_Storm_Spread_Rumors, //造谣传谣
      ],
      reportCrispsContentListNumber: 0, //举报选项
      reportCrispsClickContent: this.$Strings.Mind_Storm_Pornographic, //举报选中的内容
      showModal: false, //是否展示历史记录模态框
      showSwitchMapMenu: false, //切换导图菜单
    };
  },
  components: {
    ComplieAudio,
    CommonBgColor,
    ComplieHeaderImg,
    ComplieHeaderMind,
    ComplieRightMenuIcon,
    ComplieChildMenuTheme,
    ComplieMindmapPlayModal,
    ComplieSwitchMapMenu,
    ComplieHeaderMoreMenu,
    NewTemplateTest,
    GroupFileChangeHistory,
  },
  created() {
    this.isMac = !this.$tools.isMac();
    this.nowElectron = this.$tools.isElectron();
  },
  mounted() {
    // document.addEventListener("click", (e) => {
    //   var hideTag = e.target.tagName.toUpperCase();
    //   if (
    //     e.target.className != "comple-menu-accout" &&
    //     e.target.className != "complie-header-menu-content" &&
    //     e.target.className != "style-img" &&
    //     e.target.className != "complie-header-imgbox" &&
    //     e.target.className != "icon-detials-box" &&
    //     e.target.className != "menu-content" &&
    //     e.target.className != "complie-icon" &&
    //     e.target.className != "MenuIcon-priority-bigbox" &&
    //     e.target.className != "MenuIcon-chunk-color" &&
    //     e.target.className != "complie-rule-content-box" &&
    //     e.target.className != "complie-rule-header" &&
    //     e.target.className != "MenuIcon-priority-box" &&
    //     e.target.className != "menu-text" &&
    //     e.target.className != "complie-rule-menu-box active" &&
    //     e.target.className != "menu-right-border-box" &&
    //     e.target.className != "menu-right-linecolor" &&
    //     e.target.className != "menu-right-accout" &&
    //     e.target.className != "menu-use-box" &&
    //     e.target.className != "menu-right-accout-ly" &&
    //     e.target.className != "menu-right-bgcolor" &&
    //     e.target.className != "menu-linecolor-box" &&
    //     e.target.className != "menu-left-accout" &&
    //     e.target.className != "complie-color" &&
    //     e.target.className != "comple-menu-ico-text" &&
    //     e.target.className != "menu-left-accout" &&
    //     e.target.className != "color-content-max-titel" &&
    //     e.target.className != "vc-sketch-presets" &&
    //     e.target.className != "vc-saturation" &&
    //     e.target.className != "vc-hue-container" &&
    //     e.target.className != "vc-alpha-picker" &&
    //     e.target.className != "vc-input__input" &&
    //     e.target.className != "vc-alpha-container" &&
    //     e.target.className != "vc-saturation--black" &&
    //     e.target.className != "vc-sketch" &&
    //     e.target.className != "vc-sketch-sliders" &&
    //     e.target.className != "vc-hue-picker" &&
    //     e.target.className != "shape-icon-white" &&
    //     e.target.className != "MenuIcon-box" &&
    //     hideTag != "SPAN"
    //   ) {
    //     this.iconCompoenntAccout = false;
    //     this.mindComponentAccout = false;
    //     this.mindStyleComponentAccout = false;
    //     this.isShowIconDetialsMenu = false;
    //     this.isShowFontColorModal = false;
    //   }
    // });

    if (this.viewModel == null) {
      this.viewModel = this.$store.state.editMindmap;
    }
    this.getHeaderMenuAccout(this.$store.state.initData);
    this.bus.$on("isShowIconDetialsMenu", (msg) => {
      this.isShowIconDetialsMenu = msg;
    });

    // if () {

    // }
    // this.titelAccout = EditMindmapVM.mindDisplayType
  },

  // directives: {
  //   clickoutside: {
  //     bind(el, binding, vnode) {
  //       function documentHandler(e) {
  //         if (el.contains(e.target)) {
  //           return false
  //         }
  //         if (binding.expression) {
  //           binding.value(e)
  //         }
  //       }

  //       function KeyUp(e) {
  //         if (e.keyCode == 27) {
  //           if (binding.expression) {
  //             binding.value(e)
  //           }
  //         }
  //       }
  //       el.__vueClickOutSize__ = documentHandler
  //       el.__vueKeyup__ = KeyUp

  //       document.addEventListener('keyup', KeyUp)
  //       document.addEventListener('click', documentHandler)
  //     },
  //     unbind(el, binding) {
  //       document.removeEventListener('click', el.__vueClickOutSize__)
  //       delete  el.__vueClickOutSize__

  //       document.removeEventListener('keyup', el.__vueKeyup__)
  //       delete  el.__vueKeyup__
  //     }
  //   }
  // },
  methods: {
    ...mapMutations([
      "getTextStlye",
      "setHeaderMenu",
      "showLoginModel",
      "setAddIconPage",
      "alignNodeStyle",
      "showDaGangModel",
      "markColorAccout",
      "mindmapRightMenu",
      "isShowShoppingModel",
      "saveEditAccoutToHeader",
      "mindmapPlayPauseShowSetup",

      "imgTypeModelAccout",
      "showCropperImg",
      "showCropperImgUrl",
      "sharePotatoChipsModel",
    ]),

    hiddenModal(item) {
      if (item.show == false) {
        this.showModal = false;
      }
    },

    //打开举报面板
    reportCrisps(index, id) {
      this.reportCrispsVisible = true;
      // this.chipsFeedsList[index].visible = false;
      // this.crispsClickId = { index, id };
    },

    //举报
    reportCrispsHandleOk() {
      //未登录
      let userData = localStorage.getItem("meMessage");
      if (userData == null || userData == "") {
        this.showLoginModel(true);
        return;
      }
      var gfileId = this.initMindGroupViewData.gfileId; // this.crispsClickId.id;
      var reason = getString(this.reportCrispsClickContent);
      postGroupReportGfile(
        { gfileId, reason },
        (res) => {
          // this.chipsFeedsList.splice(this.crispsClickId.index, 1);
          this.$message.success(
            getString(this.$Strings.Mind_Storm_Report_Successful)
          );
          this.reportCrispsVisible = false;
        },
        (error) => {
          this.$message.error(error.desc);
        }
      );
    },

    //举报选项
    reportCrispsClick(index) {
      this.reportCrispsContentListNumber = index;
      this.reportCrispsClickContent = this.reportCrispsContentList[index];
    },

    groupUpdate() {
      // if (this.initMindGroupViewData != null && this.initMindGroupViewData.status != '') {
      //   this.$message.warning('已有用户在编辑导图');
      //   return;
      // }
      this.$emit("groupUpdate", true);
    },

    //当前导图模式
    getMindmapPattern() {
      return EditMindmapVM.mindDisplayType == MindDisplayType.MindMap
        ? true
        : false;
    },

    // 大纲&导图
    setAccoutTitel(index) {
      // if (this.accoutList.length == 1) {
      //   return;
      // }
      //大纲
      // this.$store.state.editMindmap;

      // this.titelAccout = index;
      this.MindOperateUIControllerView = new MindOperateUIControllerView(
        this.viewModel,
        this.viewModel
      );
      this.MindOperateUIControllerView.onSelected(MindElementType.OUTLINE);
      this.mobileIsOutline();
    },

    //移动端大纲
    mobileIsOutline() {
      this.$nextTick(function () {
        let headerDom = document.getElementsByClassName(
          "content-header-user-message"
        );
        if (headerDom[0] == undefined) {
          return;
        }
        if (
          navigator.userAgent.match(
            /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
          ) &&
          EditMindmapVM.mindDisplayType == MindDisplayType.Outline
        ) {
          headerDom[0].style.backgroundColor = "#FFF2F2";
        } else {
          headerDom[0].style.backgroundColor = "transparent";
        }
      });
    },

    httpImageToPrefix(src) {
      //拼接图片地址
      return httpImageToPrefix(src);
    },

    outsideClose() {
      //点击菜单中的选项
      this.showMenu = false;
    },

    checkMenuItem(val) {
      this.outsideClose();
      this.setMenuAccout(val.item.menuType);
    },

    setMenuAccout(type) {
      switch (type) {
        case "save_Type":
          if (!this.allowExport()) {
            return
          }
          this.saveImgType();
          break;
        case "save_Mind":
          this.saveMind();
          break;
        case "save_Pdf":
          if (!this.allowExport()) {
            return
          }
          this.savePdfType();
          break;
        case menuListType.exportWord:
          if (!this.allowExport()) {
            return
          }
          this.exportWordFun();
          break;
        case menuListType.exportTxt:
          if (!this.allowExport()) {
            return
          }
          this.exportTXTFun();
          break;  
        // case "share_link":
        //   this.saveLinkType();
        //   break;
        case "share_PotatoChips":
          this.sharePc();
          break;
        // case "save_Sourse_Type":
        //   this.saveSourseType();
        //   break;
        // case "unlock_Full_Version":
        //   this.saveFullType();
        //   break;
        case "save_Printf":
          this.savePrintf();
          break;
        case "update_History":
          // this.savePrintf();
          this.showModal = true;
          break;
        // case "help":
        //   this.helpModal(true);
        //   break;
        case "report":
          this.reportCrisps();
          break;
        default:
          break;
      }
    },

    //保存导图
    saveMind() {
      let id = this.$route.query.id;
      if (id) {
        this.setGroupSaveGmap({ gmapId: id }).then((res) => {
          this.$message.success(
            this.$getStringObj.getString(
              this.$Strings.Message_Tips_Save_Success
            )
          );
        });
      }
    },
    // 允许保存图片/PDF/Word/TXT
    allowExport(){
      const allowSave = this.initMindGroupViewData.allowSaveImage
      if (!allowSave) {
         this.$message.error(
            this.$getStringObj.getString(
              this.$Strings.No_Permission_Do_This
            )
          );
      }
      return allowSave;
    },
    //保存图片
    getSaveImgType(getType) {
      let rect = this.editMindmapContent.getMindContentRect();
      let name = this.editMindmapContent.getTitle();
      let bgColor = this.$store.state.saveImageBgcolor;
      let toImage = new ToImage();
      let type = "download";
      let outType = getType; // png webp jpeg
      if (EditMindmapVM.mindDisplayType == MindDisplayType.MindMap) {
        toImage.toImage(rect, bgColor, type, outType, name, EditMindmapVM.isSettingFontFamily());
      } else if (EditMindmapVM.mindDisplayType == MindDisplayType.Outline) {
        toImage.outlineToImage(16777215, type, outType, name);
      }
    },

    //打开选择img type面板
    saveImgType() {
      let userData = localStorage.getItem("meMessage");
      if (userData == null || userData == "") {
        this.showLoginModel(true);
        return;
      }
      this.imgTypeModelAccout({ accout: true, type: "" });
    },

    //保存pdf
    savePdfType() {
      let userData = localStorage.getItem("meMessage");
      if (userData == null || userData == "") {
        this.showLoginModel(true);
        return;
      }
      let rect = this.editMindmapContent.getMindContentRect();
      let name = this.editMindmapContent.getTitle();
      let bgColor = this.$store.state.saveImageBgcolor;
      let toImage = new ToImage();
      let type = "savePdf";
      let outType = "png"; // png
      if (EditMindmapVM.mindDisplayType == MindDisplayType.MindMap) {
        toImage.toImage(rect, bgColor, type, outType, name, EditMindmapVM.isSettingFontFamily());
      } else if (EditMindmapVM.mindDisplayType == MindDisplayType.Outline) {
        toImage.outlineToImage(16777215, type, outType, name);
      }
    },
    // 导出Word
    exportWordFun(){
      if(this.isMember()){
        let content = EditMindmapVM.outTextContent();
        new ExportFile(this).wordExport(content);
      }else{
        this.isShowShoppingModel(true);
      }
    },
    
    // 导出TXT
    exportTXTFun(){
      if(this.isMember()){
        let content = EditMindmapVM.outTextContent();
        new ExportFile(this).txtExport(content);
      }else{
        this.isShowShoppingModel(true);
      }
    },
    //分享风暴截图
    sharePc() {
      let userData = localStorage.getItem("meMessage");
      if (userData == null || userData == "") {
        this.showLoginModel(true);
        return;
      }

      let rect = this.editMindmapContent.getMindContentRect();

      let wh_s = 5 / 4;
      let orgWHS = rect.width() / rect.height();
      if (wh_s > orgWHS) {
        let width = rect.height() * wh_s;
        let changeWidth = width - rect.width();
        rect.width(width);
        rect.x = rect.x - changeWidth / 2;
      } else {
        let height = rect.width() / wh_s;
        let changeHeight = height - rect.height();
        rect.height(height);
        rect.y = rect.y - changeHeight / 2;
      }
      rect.y = Math.max(rect.y, 0);
      rect.x = Math.max(rect.x, 0);

      let name = this.editMindmapContent.getTitle();
      let bgColor = this.$store.state.saveImageBgcolor;
      let toImage = new ToImage();
      let type = "storm";
      let outType = "png"; // png
      let image = toImage.toImage(rect, bgColor, type, outType, name, EditMindmapVM.isSettingFontFamily());
      image.then((image) => {
        this.showCropperImg(true);
        this.showCropperImgUrl({ img: image, type: "storm" });
      });
    },
    //截取风暴封面图片
    setSsharePc(image) {
      const cover = image;
      const name = this.editMindmapContent.getTitle().replace(/[\r\n]/g, "");
      const subtitle = this.editMindmapContent.getSubtitle();
      this.sharePotatoChipsModel({
        accout: true,
        shareData: { cover, name, subtitle },
      });
    },

    checkMenu(e, show) {
      this.menuList = [
         {
          image: require("../../assets/img/gMap/save-mind.svg"),
          name: this.$getStringObj.getString(
            this.$Strings.Mind_Group_Save_The_Mapping
          ),
          menuType: "save_Mind",
        },
        {
          image: require("../../assets/img/gMap/save-img.svg"),
          name: this.$getStringObj.getString(
            this.$Strings.Mind_Edit_Left_Menu_Save_Picture
          ),
          menuType: "save_Type",
        },
        {
          image: require("../../assets/img/gMap/derive-pdf.svg"),
          name: this.$getStringObj.getString(
            this.$Strings.Mind_Edit_Left_Menu_Export_Pdf
          ),
          menuType: "save_Pdf",
        },
        {
          image: require("../../assets/img/export/exportWord.svg"),
          name: this.$getStringObj.getString(this.$Strings.Mind_Edit_Left_Menu_Export_Word),
          menuType: menuListType.exportWord,
          needMember: true,
        }, 
        {
          image: require("../../assets/img/export/exportTXT.svg"),
          name: this.$getStringObj.getString(this.$Strings.Mind_Edit_Left_Menu_Export_TXT),
          menuType: menuListType.exportTxt,
          needMember: true,
        },
        {
          image: require("../../assets/img/gMap/share-scrips.svg"),
          name: this.$getStringObj.getString(
            this.$Strings.Mind_Edit_Left_Menu_Share_The_Storm
          ),
          menuType: "share_PotatoChips",
        },
        // {
        //   image: require("../../assets/img/gMap/update-history.svg"),
        //   name: this.$getStringObj.getString(this.$Strings.Mind_Group_Update_Record),
        //   menuType: "update_History",
        // },
        {
          image: require("../../assets/img/gMap/report.svg"),
          name: this.$getStringObj.getString(this.$Strings.Mind_Storm_Report),
          menuType: "report",
        },
      ];
      //当前的页面是否可以导出txt,world
      let mindType = this.$store.state.initData.mindType;
      if (mindType != MindType.LINE_MAP && mindType != MindType.BUBBLE_MAP) {
        this.menuList = this.menuList.filter(item => item.menuType !== menuListType.exportWord);
        this.menuList = this.menuList.filter(item => item.menuType !== menuListType.exportTxt);
      }
      this.menuPoint = {
        val1: "bottom",
        val2: "left",
        type: "eachMenu",
      };

      this.pointObj = e.target.getBoundingClientRect();
      this.showMenu = !this.showMenu;
      // if (show) {
      //     this.showMenu = true;
      // } else {
      //     this.showMenu = false
      // }
    },

    movEachMenu(e, show) {
      //鼠标放在了文件夹菜单按钮
      this.menuList = [
        {
          name: "置顶",
        },
        {
          name: "保存",
        },
        {
          name: "重命名",
        },
        {
          name: "删除",
        },
      ];
      this.pointObj = e.target.getBoundingClientRect();

      this.menuPoint = {
        val1: "bottom",
        val2: "left",
        type: "eachMenu",
      };
      if (show) {
        this.showMenu = true;
      } else {
        this.showMenu = false;
      }
    },

    //语言包
    getString(i) {
      return getString(i);
    },
    isElectron() {
      if (
        typeof navigator === "object" &&
        typeof navigator.userAgent === "string" &&
        navigator.userAgent.indexOf("Electron") >= 0
      ) {
        return true;
      }
      return false;
    },
    //保存导图数据
    saveMindData() {
      EditMindmapVM.saveDataForClosePage();
      setTimeout(() => {
        this.editAccout.url = "";
      }, 2000);
    },
    //保存已更改状态
    saveEditAccout(accout) {
      var time = setTimeout(() => {
        this.saveEditAccoutToHeader(null);
      }, 100);
      if (accout == "saveSuccess") {
        this.saveEditAccoutToHeader("saveSuccess");
        time;
      } else if (accout == "alreadyEdit") {
        this.saveEditAccoutToHeader("alreadyEdit");
        time;
        //this.checkAddIconColor(this.DrawingBoardBackgroundColor);
      }
    },
    //根据导图类型渲染菜单
    getHeaderMenuAccout(borderAccoutData) {
      if (borderAccoutData.mindType == MindType.BUBBLE_MAP) {
        this.headerMenuAccout.linelayout = false;
        this.headerMenuAccout.timelayout = false;
        this.headerMenuAccout.bubbelLayout = true;
        this.headerMenuAccout.doubelBubbleLayout = false;
        this.rightMenuIcon.shift(0, 1);
        this.headerMenuAccout.outlineLayout = true;
      } else if (borderAccoutData.mindType == MindType.LINE_MAP) {
        this.headerMenuAccout.linelayout = true;
        this.headerMenuAccout.timelayout = false;
        this.headerMenuAccout.bubbelLayout = false;
        this.headerMenuAccout.doubelBubbleLayout = false;
        this.headerMenuAccout.outlineLayout = true;
      } else if (borderAccoutData.mindType == MindType.TIME_MAP) {
        this.headerMenuAccout.linelayout = false;
        this.headerMenuAccout.timelayout = true;
        this.headerMenuAccout.bubbelLayout = true;
        this.headerMenuAccout.doubelBubbleLayout = false;
        this.headerMenuAccout.outlineLayout = false;
        this.rightMenuIcon.shift(0, 1);
      } else if (borderAccoutData.mindType == MindType.DOUBLE_BUBBLE_MAP) {
        this.headerMenuAccout.linelayout = false;
        this.headerMenuAccout.timelayout = false;
        this.headerMenuAccout.bubbelLayout = true;
        this.headerMenuAccout.doubelBubbleLayout = true;
        this.headerMenuAccout.outlineLayout = false;
        this.rightMenuIcon.shift(0, 1);
      }
      this.$nextTick((_) => {
        //code
        this.setHeaderMenu(this.$refs.complieheadermenubox);
      });
    },
    //导图播放
    mindmapPlay() {
      this.mindmapPlayPauseShowSetup({
        showSetup: false,
        playAccout: "SHOW_PLAY_SETUP",
      });
    },
    getHeadermenuType(type, index) {
      if (type == "Delete_Node") {
        this.$emit("clickDeleteNode");
        return;
      }
      if (type == "Icon_Details") {
        this.isShowIconDetialsMenu = !this.isShowIconDetialsMenu;
        this.isShowFontColorModal = false;
        return;
      }
      if (type == "Font_Color") {
        this.markColorAccout(2);
        this.isShowFontColorModal = !this.isShowFontColorModal;
        this.isShowIconDetialsMenu = false;
        return;
      }
      if (type == "Font_Style") {
        this.headerOutline.fontStyleMenu[index].fontStyleAccout = !this
          .headerOutline.fontStyleMenu[index].fontStyleAccout;
        this.getTextStlye(index);
        return;
      }

      this.MindOperateUIControllerView = new MindOperateUIControllerView(
        this.viewModel,
        this.viewModel
      );
      this.MindOperateUIControllerView.onSelected(type);
    },
    setIconComponent() {
      this.iconCompoenntAccout = !this.iconCompoenntAccout;
      this.mindComponentAccout = false;
      this.mindStyleComponentAccout = false;
      this.mindAudioComponentAccout = false;
    },
    setMindComponent() {
      this.mindComponentAccout = !this.mindComponentAccout;
      this.iconCompoenntAccout = false;
      this.mindStyleComponentAccout = false;
      this.mindAudioComponentAccout = false;
    },
    setMindStyleComponent() {
      this.mindStyleComponentAccout = !this.mindStyleComponentAccout;
      this.iconCompoenntAccout = false;
      this.mindComponentAccout = false;
      this.mindAudioComponentAccout = false;
    },
    setMindAudioComponent() {
      let userData = localStorage.getItem("meMessage");
      if (userData == null || userData == "") {
        this.showLoginModel(true);
        return;
      }
      this.mindAudioComponentAccout = !this.mindAudioComponentAccout;
      this.iconCompoenntAccout = false;
      this.mindComponentAccout = false;
      this.mindStyleComponentAccout = false;
    },
    audioComponentAccout(accout) {
      this.mindAudioComponentAccout = accout;
    },
    setMenuShowDetial(index, type) {
      //调用vuex方法
      switch (type) {
        case "OutlineOrMindmap":
          this.MindOperateUIControllerView = new MindOperateUIControllerView(
            this.viewModel,
            this.viewModel
          );
          this.MindOperateUIControllerView.onSelected(MindElementType.OUTLINE);
          break;
        case "Icon":
          this.setAddIconPage("ADD_ICON");
          break;
        case "Style":
          this.setAddIconPage("ADD_STYLE");
          break;
        default:
          break;
      }

      //大纲 导图
      if (type == "OutlineOrMindmap") {
        if (this.isShowOutline) {
          this.isShowOutline = false;
        } else {
          this.isShowOutline = true;
        }
      }

      //图标 样式
      if (index == this.menuIconClickIndex && index != 0) {
        if (
          index == this.menuIconClickIndex &&
          this.menuIconClickAccout == true
        ) {
          this.$emit("menuIsShow", false);
          this.mindmapRightMenu(false);
          this.menuIconClickAccout = false;
        } else {
          this.$emit("menuIsShow", true);
          this.menuIconClickAccout = true;
          this.mindmapRightMenu(true);
        }
      } else {
        this.$emit("menuIsShow", true);
        this.mindmapRightMenu(true);
      }
      this.menuIconClickIndex = index;
    },
    setAlignIcon(type) {
      this.alignNodeStyle(type);
      setTimeout(() => {
        this.alignNodeStyle(null);
      }, 100);
    },

    setGroupSaveGmap: function (obj) {
      //获取所有模板信息
      let that = this;
      return new Promise(function (resolve, reject) {
        postGroupSaveGmap(
          obj,
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
            that.$message.error(error.desc);
          }
        );
      });
    },
    outsideCloseSwitchMap() {
      this.showSwitchMapMenu = false;
    },
    switchMapMenu(e, show) {
      this.pointObj = this.$refs.switchbuttonboxchild.getBoundingClientRect();
      this.showSwitchMapMenu = !this.showSwitchMapMenu;
      this.menuPoint = {
        val1: "bottom",
        val2: "right",
        type: "eachMenu",
      };
    },
  },
  watch: {
    "$store.state.initData"(newInitData, olInitData) {
      this.getHeaderMenuAccout(newInitData);
    },
    "$store.state.editMindmap"(newval, olval) {
      this.viewModel = newval;
    },
    "$store.state.showShopping"(newval, olval) {
      this.mindStyleComponentAccout = !newval;
    },
    //撤销
    "$store.state.isRevocationMap"(newval, olval) {
      this.getHeadermenuType(MindElementType.BACKWARD_RETREAT);
    },
    //恢复
    "$store.state.isRecoverMap"(newval, olval) {
      this.getHeadermenuType(MindElementType.FORWARD);
    },
    //大纲
    "$store.state.showDaGangModels"(newshowDaGangModels) {
      this.hideOutlineAccout = newshowDaGangModels;
    },
    //对齐按钮
    "$store.state.isShowAlignButton"(isShowAlignButton) {
      this.isShowAlignButton = isShowAlignButton;
    },
    //当前节点数据
    "$store.state.nodeContentMessage"(newNodeMessage, olval) {
      if (newNodeMessage != null) {
        if (newNodeMessage.generalizationContent != null) {
          this.nodeAccout = newNodeMessage;
        }
        //节点文本内容
        if (newNodeMessage.textContent != null) {
          this.nodeAccout = newNodeMessage;
          this.fontTextColor = colorChangeClass.SystemToColor(
            newNodeMessage.textContent.textColor
          );
          if (
            newNodeMessage.iconElementContents != null &&
            newNodeMessage.iconElementContents.length > 0
          ) {
            this.iconFontSize = newNodeMessage.iconElementContents[0].width;
            this.iconPlaceAccout =
              newNodeMessage.iconElementContents[0].layoutType - 1;
          }
          if (newNodeMessage.textContent !== null) {
            this.headerOutline.fontStyleMenu[0].fontStyleAccout =
              newNodeMessage.textContent.textBold;
            this.headerOutline.fontStyleMenu[1].fontStyleAccout =
              newNodeMessage.textContent.textItalics;
            this.headerOutline.fontStyleMenu[2].fontStyleAccout =
              newNodeMessage.textContent.textStrikethrough;
          }
        }
      }
    },

    //保存编辑
    "$store.state.saveAndEditAccout"(newSaveAndEdit, olSaveAndEdit) {
      if (newSaveAndEdit == null) {
        return;
      }
      if (newSaveAndEdit == "saveSuccess") {
        this.editAccout.url = "check-circle";
        this.editAccout.text = getString(strings.Header_Mind_Tips_Update_Saved);
        setTimeout(() => {
          this.editAccout.url = "";
        }, 2000);
      } else if (newSaveAndEdit == "alreadyEdit") {
        this.editAccout.url = "exclamation-circle";
        this.editAccout.text = getString(strings.Header_Mind_Tips_Edit);
      }
      // this.checkAddIconColor(this.DrawingBoardBackgroundColor);
    },

    "$store.state.textColor"(newval, olval) {
      if (newval == null) {
        return;
      }
      this.fontTextColor = colorChangeClass.nodeUpdataBeforeColor(newval);
    },

    "$store.state.mindmapPlayPauseShowSetup.playAccout"(newPlayAccout) {
      switch (newPlayAccout) {
        case "PAUSE":
          break;
        case "OVER":
          this.mindmapPlayButton = false;
          break;
        case "PLAY":
          this.mindmapPlayButton = true;
          break;
        default:
          break;
      }
    },

    "$store.state.imgTypeModelAccout.type"(newImgType, olImgType) {
      if (newImgType == "") {
        return;
      }
      this.getSaveImgType(newImgType);
    },

    "$store.state.urlDomImg.imageBase64"(newval, olval) {
      if (newval == null || this.$store.state.urlDomImg.type == "uploadImg") {
        return;
      }
      this.setSsharePc(newval);
    },
  },
};
</script>

<style lang="less" scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}

// 导图播放Icon
@font-face {
  font-family: "iconfontMindmapPlay";
  src: url("../../assets/font-icon/mindmap_play/iconfont.eot");
  src: url("../../assets/font-icon/mindmap_play/iconfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/font-icon/mindmap_play/iconfont.woff2") format("woff2"),
    url("../../assets/font-icon/mindmap_play/iconfont.woff") format("woff"),
    url("../../assets/font-icon/mindmap_play/iconfont.ttf") format("truetype"),
    url("../../assets/font-icon/mindmap_play/iconfont.svg#iconfont")
      format("svg");
}
.iconfont-mindmap-play {
  font-family: "iconfontMindmapPlay" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Outline";
  src: url("../../assets/font-icon/font-style-detials/iconfont.eot");
  src: url("../../assets/font-icon/font-style-detials/iconfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/font-icon/font-style-detials/iconfont.woff2")
      format("woff2"),
    url("../../assets/font-icon/font-style-detials/iconfont.woff")
      format("woff"),
    url("../../assets/font-icon/font-style-detials/iconfont.ttf")
      format("truetype"),
    url("../../assets/font-icon/font-style-detials/iconfont.svg#iconfont")
      format("svg");
}
.Outline {
  font-family: "Outline" !important;
  font-size: 21px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: "mindicon";
  src: url("../../assets/font-icon/mind_icon/iconfont.eot");
  src: url("../../assets/font-icon/mind_icon/iconfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/font-icon/mind_icon/iconfont.woff2") format("woff2"),
    url("../../assets/font-icon/mind_icon/iconfont.woff") format("woff"),
    url("../../assets/font-icon/mind_icon/iconfont.ttf") format("truetype"),
    url("../../assets/font-icon/mind_icon/iconfont.svg#iconfont") format("svg");
}
.mindicon {
  font-family: "mindicon" !important;
  font-size: 21px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: "iconfont";
  src: url("../../assets/font-icon/outline_Icon/iconfont.eot");
  src: url("../../assets/font-icon/outline_Icon/iconfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/font-icon/outline_Icon/iconfont.woff2") format("woff2"),
    url("../../assets/font-icon/outline_Icon/iconfont.woff") format("woff"),
    url("../../assets/font-icon/outline_Icon/iconfont.ttf") format("truetype"),
    url("../../assets/font-icon/outline_Icon/iconfont.svg#iconfont")
      format("svg");
}

@font-face {
  font-family: "iconfont";
  src: url("../../assets/font-icon/rightStyleTitleIcon/iconfont.eot");
  src: url("../../assets/font-icon/rightStyleTitleIcon/iconfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/font-icon/rightStyleTitleIcon/iconfont.woff2")
      format("woff2"),
    url("../../assets/font-icon/rightStyleTitleIcon/iconfont.woff")
      format("woff"),
    url("../../assets/font-icon/rightStyleTitleIcon/iconfont.ttf")
      format("truetype"),
    url("../../assets/font-icon/rightStyleTitleIcon/iconfont.svg#iconfont")
      format("svg");
}
.iconfont {
  font-family: "iconfont" !important;
  font-size: 21px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: "iconfont";
  src: url("../../assets/font-icon/font_SetStyleIcon/iconfont.eot");
  src: url("../../assets/font-icon/font_SetStyleIcon/iconfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/font-icon/font_SetStyleIcon/iconfont.woff2")
      format("woff2"),
    url("../../assets/font-icon/font_SetStyleIcon/iconfont.woff") format("woff"),
    url("../../assets/font-icon/font_SetStyleIcon/iconfont.ttf")
      format("truetype"),
    url("../../assets/font-icon/font_SetStyleIcon/iconfont.svg#iconfont")
      format("svg");
}
.iconfont {
  font-family: "iconfont" !important;
  font-size: 21px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.complie-header-menu {
  height: 66px;
  // min-width: 1375px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background: #f5f5f5;
  color: #666666;
  font-size: 16px;
  //不知到代码中出了什么bug，在距离顶部67像素的位置就出现了一个白色盒子，怎么也去不掉。用的div嵌套太多，也找不出来。
  // position: fixed;
  // width: 100%;
  .group-view-header-left-icon {
    display: flex;
    align-items: center;
    img {
      width: 50px;
      height: 50px;
      border-radius: 4px;
      margin-left: 40px;
      margin-right: 10px;
    }
  }
  .each-button-box {
    padding: 0 10px;
    cursor: pointer;
    position: relative;
    //图标于文字开始
    .each-button-box-img {
      display: block;
      width: 24px;
      height: 24px;
      margin: 0 auto;
      position: relative;
      .each-button-box-center-img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
    .each-button-box-text {
      font-size: 12px;
      text-align: center;
      white-space: nowrap;
    }
    //图标于文字结束
  }
  .group-view-header-right {
    display: flex;
    align-items: center;
    margin-left: auto;
    .group-content-box {
      display: flex;
      align-items: center;
      margin-right: 40px;
      img {
        width: 22px;
        height: 22px;
        margin-right: 10px;
      }
    }
    .group-play {
      width: 24px;
      height: 24px;
      margin-right: 40px;
      cursor: pointer;
    }
    .group-lineout-icon {
      font-size: 20px;
      margin-right: 40px;
      cursor: pointer;
      width: 44px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      color: #333;
      font-weight: 700;
      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
        color: #fff;
      }
    }

    .group-update {
      margin-right: 40px;
      background-color: #ec705a;
      color: #fff;
      height: 32px;
      line-height: 32px;
      border-radius: 16px;
      padding: 0 16px;
      cursor: pointer;
    }
    .group-update-menu {
      margin-right: 40px;
      width: 22px;
      height: 22px;
      cursor: pointer;
      img {
        width: 22px;
        height: 22px;
      }
    }
  }
}
</style>
