<template>
    <div>
        <div v-show="showPage" :style="{left:pointLeft + 'px',top: pointTop + 'px',opacity: opacity}" ref="addGroup"  class="add-group">
            <!-- <div :style="{position:'absolute',left: hiddenLeft + 'px',
            top: hiddenTop + 'px',width:hiddenWidth + 'px',height: hiddenHeight + 'px'}" class="hidden-dom"></div> -->
            <div v-for="(item,index) in showData" :key="index"
                @click="checkMenuItem(item)" class="menulist-each-big-box">
                <div class="menulist-each">
                    <img v-if="item.image != null && item.image != ''" :src="item.image"  :alt="item.name">
                    <span>
                        {{item.name}}
                    </span>
                </div>
                <img v-if="item.needMember && !isMember()" src="../../../assets/img/user/icon/vip.svg" class="need-member" alt="vip">
            </div>
        </div>
        <!-- <GroupAddGroupModal :show="showModal" @hidden="hidden" /> -->
    </div>
</template>

<script>
import { mapMutations } from "vuex";
// import GroupAddGroupModal from "./GroupAddGroupModal"
export default {
    components: {
        // GroupAddGroupModal
    },
     props: [
        "showData",
        "pointObj",
        "menuPoint",
        "showMenu",
    ],
    data() {
        return {
            showPage: false,
            pointLeft: 0,   //大盒子相对于整个页面的位置
            pointTop: 0,
            // hiddenLeft: 0, //透明盒子相对与父盒子的位置
            // hiddenTop: 0,
            // hiddenWidth: 0,
            // hiddenHeight: 0,
            opacity: 0,
            showAnimal:false,
            topSpace: 15, //菜单距离顶部的距离
            // showModal: false,
        }
    },
    mounted() {


    },
    updated() {
        let menudom = this.$refs.addGroup.getBoundingClientRect();
        // console.log('menudom.width',menudom.width);
                    if (this.menuPoint.val1 == 'bottom' && this.menuPoint.val2 == 'left') {
            this.pointTop = this.pointObj.y + this.pointObj.height + this.topSpace;
            this.pointLeft = this.pointObj.x - menudom.width + this.pointObj.width;
            // this.hiddenLeft = menudom.width - this.pointObj.width;
            // this.hiddenTop = -this.pointObj.height;
            // this.hiddenWidth = this.pointObj.width;
            // this.hiddenHeight = this.pointObj.height;
            // console.log('进入了准确位置1')
        } else if(this.menuPoint.val1 == 'top' && this.menuPoint.val2 == 'left'){
            this.pointTop = this.pointObj.y - menudom.height;
            this.pointLeft = this.pointObj.x + this.pointObj.width - menudom.width;
            // this.hiddenLeft = menudom.width - this.pointObj.width;
            // this.hiddenTop = menudom.height;
            // this.hiddenWidth = this.pointObj.width;
            // this.hiddenHeight = this.pointObj.height;
        } else if (this.menuPoint.val1 == 'left' && this.menuPoint.val2 == 'bottom') {
            this.pointTop = this.pointObj.y ;
            this.pointLeft = this.pointObj.x - menudom.width;
            // this.hiddenLeft = menudom.width ;
            // this.hiddenTop = 0;
            // this.hiddenWidth = this.pointObj.width;
            // this.hiddenHeight = this.pointObj.height;
        } else if (this.menuPoint.val1 == 'bottom' && this.menuPoint.val2 == 'center') {
            this.pointTop = this.pointObj.y + this.pointObj.height + this.topSpace ;
            this.pointLeft = this.pointObj.x + this.pointObj.width/2 - menudom.width/2;

        } else if (this.menuPoint.val1 == 'bottom' && this.menuPoint.val2 == 'right') {
            this.pointTop = this.pointObj.y + this.pointObj.height + this.topSpace;
            this.pointLeft = this.pointObj.x;// + this.pointObj.width/2 - menudom.width/2;

        }
    },
    methods: {
        ...mapMutations([
            "setgroupCreatedMenu",
        ]),
        moveMenu (show) {
            if (show) {
                // this.setgroupCreatedMenu(true);
                this.showPage = true
            } else {
                this.showPage = false
                // this.setgroupCreatedMenu(false);
            }
        },
        checkMenuItem (item) {
            // if (item.name == this.$getStringObj.getString(this.$Strings.Mind_Group_Join_The_Group)) {
            //     this.showModal = true;
            // }
            this.$emit('checkMenuItem', {type:this.menuPoint.type, item:item });

        },
        hidden(obj) {
            // if (obj.show == false){
            //     this.showModal =false
            // }
        },
    },
    watch: {
        showMenu(newVal) {
            this.showPage = newVal;
        },
        // "$store.state.groupCreatedMenu"(newModelAccout) {    //如果把所有参数都放在vuex里面，会遇到获取dom宽高还有位置延迟的问题，导致计算不准。还有深度监听的频繁触发的问题
        //     this.showPage = newModelAccout;
        //     // this.showPage = true;
        // },
        pointObj(val) { 
            //一开始先移入了节点，这时显示菜单的位置不对，获取不到菜单的宽高，所以先让菜单透明，并且由于菜单的一部分刚好出现在鼠标下，
            //这时又触发了鼠标移出事件。这时能获取到菜单的大小了。所以能正常显示菜单的位置了。
            // let menudom = this.$refs.addGroup.getBoundingClientRect();
            // if (menudom.width == 0) {   //为了避免在其它位置闪一下。
            //     this.opacity = 0;
            // } else {
            //     this.opacity = 1;
            // }
            this.opacity = 1;

            // this.$nextTick(() => {
            // console.log('this.menuPoint.val1',this.menuPoint.val1);
            // console.log('this.menuPoint.val2',this.menuPoint.val2);
            // console.log('val.y',val.y);
            // console.log('menudom.width',menudom.width);


            // // if (this.menuPoint.val1 == 'bottom' && this.menuPoint.val2 == 'left') {
            // //     this.pointTop = val.y + val.height;
            // //     this.pointLeft = val.x - menudom.width + val.width;
            // //     // this.hiddenLeft = menudom.width - val.width;
            // //     // this.hiddenTop = -val.height;
            // //     // this.hiddenWidth = val.width;
            // //     // this.hiddenHeight = val.height;
            // //     console.log('进入了准确位置1')
            // // } else if(this.menuPoint.val1 == 'top' && this.menuPoint.val2 == 'left'){
            // //     this.pointTop = val.y - menudom.height;
            // //     this.pointLeft = val.x + val.width - menudom.width;
            // //     // this.hiddenLeft = menudom.width - val.width;
            // //     // this.hiddenTop = menudom.height;
            // //     // this.hiddenWidth = val.width;
            // //     // this.hiddenHeight = val.height;
            // // } else if (this.menuPoint.val1 == 'left' && this.menuPoint.val2 == 'bottom') {
            // //     this.pointTop = val.y ;
            // //     this.pointLeft = val.x - menudom.width;
            // //     // this.hiddenLeft = menudom.width ;
            // //     // this.hiddenTop = 0;
            // //     // this.hiddenWidth = val.width;
            // //     // this.hiddenHeight = val.height;
            // // } else if (this.menuPoint.val1 == 'bottom' && this.menuPoint.val2 == 'left') {
            // //     this.pointTop = val.y + val.height;
            // //     this.pointLeft = val.x + val.width - menudom.width;

            // //     console.log('进入了准确位置')
            // //     // this.hiddenLeft = menudom.width - val.width;
            // //     // this.hiddenTop = -val.height;
            // //     // this.hiddenWidth = val.width;
            // //     // this.hiddenHeight = val.height;
            // // }


            // })

            // let that = this;
            // let  clickFunction = function(e) {
            //     console.log('触发了单击事件')
            //     if (!that.$el.contains(e.target)) {
            //         console.log('进入了判断条件')

            //         that.showPage = false
            //     }
            // };
            // if (this.showMenu == true) {
            //     document.addEventListener('click', clickFunction)
            // } else {
            //     document.removeEventListener('click', clickFunction)
            // }




        }
    }
}
</script>

<style lang="less" scoped>
    .add-group {
        position: fixed;
        left: 200px;
        top: 200px;
        // padding: 16px 0;
        border-radius: 8px;
        background-color: #fff;
        // box-shadow: 0 0 5px #eee;
        z-index: 3;
        box-shadow: 0 0 5px #999;

        .menulist-each-big-box {
            padding: 0 30px;
            position: relative;
            // margin-bottom: 10px;
            .menulist-each {
                min-width: 129px;
                height: 54px;
                line-height: 54px;
                padding: 16px 0;
                // max-height: 20px;
                // line-height: 20px;
                font-size: 16px;
                white-space: nowrap;
                display: flex;
                align-items: center;
                color: #333;
                border-bottom: 1px dashed #ccc;
                cursor: pointer;
                img {
                    margin-right: 11px;
                    width: 22px;
                    height: 22px;
                }
            }
            .need-member{
                position: absolute;
                top: 50%;
                right: 16px;
                transform: translateY(-50%);
            }
            // &:last-child {
            //     // margin-bottom: 0;
            // }
            &:last-child .menulist-each{
                // margin-bottom: 0;
                border-bottom: 1px dotted rgba(255,255,255,0);
            }
            &:hover {
                background-color: #eee;
            }
        }


        
        .hidden-dom {
            // background-color:slateblue;
        }
    }
</style>