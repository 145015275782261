<template>
  <div class="complie-header-menu" ref="complieHeader">
    <div class="complie-header-menu-style">
      <div class="complie-header-menu-style-left-box">
        <div class="complie-operation-left-box">
          <div
            class="each-button-box each-button-box-padding0"
            v-clickoutside="outsideCloseExport"
          >
            <div
              class="eachbuttonboxchild"
              ref="eachbuttonboxchild"
              @click="checkMenu($event)"
            >
              <div class="each-button-box-img">
                <img
                  v-if="darkMode"
                  class="each-button-box-center-img"
                  src="../../assets/img/mindNewEditMenuIocn/darkMode/export.svg"
                  alt=""
                />
                <img
                  v-else
                  class="each-button-box-center-img"
                  src="../../assets/img/mindNewEditMenuIocn/exportBlack.svg"
                  alt=""
                />
                <div
                  :class="darkMode ? 'menu-triangle-dark' : 'menu-triangle'"
                ></div>
              </div>
              <div class="each-button-box-text">
                {{ getString(strings.Mind_Group_Export) }}
              </div>
            </div>
            <ComplieExportMenu
              @click.stop=""
              :showMenu="showMenu"
              :showData="menuList"
              :menuPoint="menuPoint"
              :pointObj="pointObj"
              :darkMode="darkMode"
              @checkMenuItem="checkMenuItem"
            />
          </div>
          <!-- 保存 -->
          <section style="margin-left: 10px">
            <div
              class="each-button-box"
              ref="saveButtonBox"
              @click="saveMindData"
            >
              <div class="each-button-box-img">
                <img
                  v-if="darkMode"
                  class="each-button-box-center-img"
                  src="../../assets/img/mindNewEditMenuIocn/darkMode/saveMap.svg"
                  alt=""
                />
                <img
                  v-else
                  class="each-button-box-center-img"
                  src="../../assets/img/mindNewEditMenuIocn/saveMap.svg"
                  alt=""
                />
              </div>
              <div class="each-button-box-text">
                {{ getString(strings.Mind_Edit_Left_Menu_Export_Save_Cloud) }}
              </div>
            </div>
          </section>
          <section
            v-if="!isShareGroup && !hideOutlineAccout"
            v-clickoutside="outsideCloseSwitchMap"
            style="margin-left: -5px"
          >
            <div
              class="each-button-box"
              ref="switchbuttonboxchild"
              @click="switchMapMenu($event)"
            >
              <div class="each-button-box-img">
                <img
                  v-if="darkMode"
                  class="each-button-box-center-img"
                  src="../../assets/img/mindNewEditMenuIocn/darkMode/cutMind.svg"
                  alt=""
                />
                <img
                  v-else
                  class="each-button-box-center-img"
                  src="../../assets/img/mindNewEditMenuIocn/cutMind.svg"
                  alt=""
                />
              </div>
              <div class="each-button-box-text">
                {{ getString(strings.Mind_Edit_Page_Switch_Mapping) }}
              </div>
            </div>
            <ComplieSwitchMapMenu
              :showSwitchMapMenu="showSwitchMapMenu"
              :mindMapingType="mindMapingType"
              :parentId="parentId"
              :menuPoint="menuPoint"
              :pointObj="pointObj"
              @outsideCloseSwitchMap="outsideCloseSwitchMap"
            />
          </section>
        </div>
        <template v-if="!hideOutlineAccout">
          <div class="complie-operation-vertical-line"></div>
          <div class="complie-operation-right-box">
            <div
              @click="getHeaderMenuType(MindElementType.BACKWARD_RETREAT)"
              @mouseenter="getKeyboard(KeyboardValueType.REVOKE)"
              class="each-button-box"
            >
              <div class="each-button-box-img">
                <img
                  v-if="darkMode"
                  class="each-button-box-center-img"
                  src="../../assets/img/mindNewEditMenuIocn/darkMode/revocation.svg"
                  alt=""
                />
                <img
                  v-else
                  class="each-button-box-center-img"
                  src="../../assets/img/mindNewEditMenuIocn/revocation.svg"
                  alt=""
                />
              </div>
              <div class="each-button-box-text">
                {{ getString(strings.Mind_Edit_Revoke) }}
              </div>
              <div class="show-title">
                {{ getString(strings.Mind_Usinghelp_13) }}
                <!-- <template v-if="isMac">
                    [Ctrl] + [Z]
                  </template>
                  <template v-else>
                    [Command] + [Z]
                  </template> -->
                <!-- 撤销 -->
                <template> [{{ keyboard.REVOKE }}] </template>
                <div class="top-triangle"></div>
              </div>
            </div>

            <div
              @click="getHeaderMenuType(MindElementType.FORWARD)"
              @mouseenter="getKeyboard(KeyboardValueType.RECOVERY)"
              class="each-button-box"
            >
              <div class="each-button-box-img">
                <img
                  v-if="darkMode"
                  class="each-button-box-center-img"
                  src="../../assets/img/mindNewEditMenuIocn/darkMode/recover.svg"
                  alt=""
                />
                <img
                  v-else
                  class="each-button-box-center-img"
                  src="../../assets/img/mindNewEditMenuIocn/recover.svg"
                  alt=""
                />
              </div>
              <div class="each-button-box-text">
                {{ getString(strings.Mind_Edit_Restore) }}
              </div>
              <div class="show-title">
                {{ getString(strings.Mind_Usinghelp_13) }}
                <!-- <template v-if="isMac">
                    [Ctrl] + [Shift] + [Z]
                  </template>
                  <template v-else>
                    [Command] + [Shift] + [Z]
                  </template> -->
                <!-- 恢复 -->
                <template> [{{ keyboard.RECOVERY }}] </template>
                <div class="top-triangle"></div>
              </div>
            </div>
          </div>
        </template>
      </div>

      <!-- 导图 -->
      <div
        class="complie-style-center"
        :style="{ width: centerMenuWidth + 'px', marginLeft: 320 + 'px' }"
        v-if="!hideOutlineAccout"
      >
        <!-- 线性结构 -->
        <div
          v-if="headerMenuAccout.linelayout"
          ref="complieheadermenubox"
          class="complie-header-menu-content"
          :style="{ background: rightMenuBg }"
        >
          <div class="complie-style-left-box">
            <div
              v-if="headerMenuAccout.addNode"
              @click="getHeaderMenuType(MindElementType.ADD)"
              @mouseenter="getKeyboard(KeyboardValueType.ADD_CHILD_NODE)"
              class="each-button-box box-hidden-son"
              ref="subtopics"
            >
              <div class="each-button-box-img">
                <img
                  v-if="darkMode"
                  class="each-button-box-center-img"
                  src="../../assets/img/mindNewEditMenuIocn/darkMode/son.svg"
                  alt=""
                />
                <img
                  v-else
                  class="each-button-box-center-img"
                  src="../../assets/img/mindNewEditMenuIocn/son.svg"
                  alt=""
                />
              </div>
              <div class="each-button-box-text">
                {{ getString(strings.Mind_Edit_Subtopic) }}
              </div>

              <div class="show-title">
                {{ getString(strings.Mind_Usinghelp_13) }}
                <!-- <template v-if="isMac">
                  [Ctrl] + [D]
                </template>
                <template v-else>
                  [Command] + [D]
                </template> -->
                <!-- 添加子节点 -->
                <template> [{{ keyboard.ADD_CHILD_NODE }}] </template>
                <div class="top-triangle"></div>
              </div>
            </div>

            <!-- <div
              @click="getHeaderMenuType(MindElementType.ADD_BROTHER_TOP)"
              @mouseenter="getKeyboard(KeyboardValueType.ADD_TOP_CHILD_NODE)"
              class="each-button-box"
            >
              <div class="each-button-box-img">
                <img
                  v-if="darkMode"
                  class="each-button-box-center-img"
                  src="../../assets/img/mindNewEditMenuIocn/darkMode/topSon.svg"
                  alt=""
                />
                <img
                  v-else
                  class="each-button-box-center-img"
                  src="../../assets/img/mindNewEditMenuIocn/topSon.svg"
                  alt=""
                />
              </div>
              <div class="each-button-box-text">
                {{ getString(strings.Mind_Edit_Top_Subtopic) }}
              </div>
              <div class="show-title">
                {{ getString(strings.Mind_Usinghelp_13) }} -->
            <!-- <template v-if="isMac">
                  [Ctrl] + [Shift] + [D]
                </template>
                <template v-else>
                  [Command] + [Shift] + [D]
                </template> -->
            <!-- 添加上子节点 -->
            <!-- <template> [{{ keyboard.ADD_TOP_CHILD_NODE }}] </template>
                <div class="top-triangle"></div>
              </div>
            </div> -->

            <div
              v-if="headerMenuAccout.addNode"
              @click="getHeaderMenuType(MindElementType.ADD_BROTHER_BOTTOM)"
              @mouseenter="getKeyboard(KeyboardValueType.ADD_BOTTOM_CHILD_NODE)"
              class="each-button-box box-hidden-bottom"
            >
              <div class="each-button-box-img">
                <img
                  v-if="darkMode"
                  class="each-button-box-center-img"
                  src="../../assets/img/mindNewEditMenuIocn/darkMode/bottomSon.svg"
                  alt=""
                />
                <img
                  v-else
                  class="each-button-box-center-img"
                  src="../../assets/img/mindNewEditMenuIocn/bottomSon.svg"
                  alt=""
                />
              </div>
              <div class="each-button-box-text">
                {{ getString(strings.Mind_Edit_Bottom_Subtopic) }}
              </div>
              <div class="show-title">
                {{ getString(strings.Mind_Usinghelp_13) }}
                <!-- <template v-if="isMac">
                  [Shift] + [D]
                </template>
                <template v-else>
                  [Shift] + [D]
                </template> -->
                <!-- 添加下子节点 -->
                <template> [{{ keyboard.ADD_BOTTOM_CHILD_NODE }}] </template>
                <div class="top-triangle"></div>
              </div>
            </div>

            <!-- <div
              @click="getHeaderMenuType(MindElementType.FREE_NODE)"
              class="each-button-box box-hidden"
            >
              <div class="each-button-box-img">
                <img
                  v-if="darkMode"
                  class="each-button-box-center-img"
                  src="../../assets/img/mindNewEditMenuIocn/darkMode/freeNode.svg"
                  alt=""
                />
                <img
                  v-else
                  class="each-button-box-center-img"
                  src="../../assets/img/mindNewEditMenuIocn/freeNode.svg"
                  alt=""
                />
              </div>
              <div class="each-button-box-text">
                {{ getString(strings.Mind_Edit_Free_Node) }}
              </div>
            </div> -->
            <!-- 注释 -->
            <div
              @click="getHeaderMenuType(MindElementType.EXPLAIN)"
              class="each-button-box box-hidden-explain"
            >
              <div class="each-button-box-img">
                <img
                  v-if="darkMode"
                  class="each-button-box-center-img"
                  src="../../assets/img/mindNewEditMenuIocn/darkMode/floatExplain.svg"
                  alt=""
                />
                <img
                  v-else
                  class="each-button-box-center-img"
                  src="../../assets/img/mindNewEditMenuIocn/floatExplain.svg"
                  alt=""
                />
              </div>
              <div class="each-button-box-text">
                {{ getString(strings.Mind_Float_Explain) }}
              </div>
            </div>
            <!-- 摘要 -->
            <div
              @click="getHeaderMenuType(MindElementType.CONTENT_GENERALIZATION)"
              class="each-button-box box-hidden-digest"
            >
              <div class="each-button-box-img">
                <img
                  v-if="darkMode"
                  class="each-button-box-center-img"
                  src="../../assets/img/mindNewEditMenuIocn/darkMode/digest.svg"
                  alt=""
                />
                <img
                  v-else
                  class="each-button-box-center-img"
                  src="../../assets/img/mindNewEditMenuIocn/digest.svg"
                  alt=""
                />
              </div>
              <div class="each-button-box-text">
                {{ getString(strings.Mind_Edit_Summary) }}
              </div>
            </div>
            <!-- 成块 -->
            <div
              @click="getHeaderMenuType(MindElementType.BAOWEI_VIEW)"
              class="each-button-box box-hidden-blocking"
            >
              <div class="each-button-box-img">
                <img
                  v-if="darkMode"
                  class="each-button-box-center-img"
                  src="../../assets/img/mindNewEditMenuIocn/darkMode/blocking.svg"
                  alt=""
                />
                <img
                  v-else
                  class="each-button-box-center-img"
                  src="../../assets/img/mindNewEditMenuIocn/blocking.svg"
                  alt=""
                />
              </div>
              <div class="each-button-box-text">
                {{ getString(strings.Mind_Edit_Block) }}
              </div>
            </div>
            <!-- 连接线 -->
            <div
              id="node-connect-line-button"
              
              v-clickoutside="outsideClose"
              class="each-button-box box-hidden-relevance"
            >
              <div class="each-button-box-img">
                <img @click="setMindComponent"
                  v-if="darkMode"
                  class="each-button-box-center-img"
                  src="../../assets/img/mindNewEditMenuIocn/darkMode/relevance.svg"
                  alt=""
                />
                <img @click="setMindComponent"
                  v-else
                  class="each-button-box-center-img"
                  src="../../assets/img/mindNewEditMenuIocn/relevance.svg"
                  alt=""
                />
                <div  @click="showConnectLineMenu"
                  :class="darkMode ? 'menu-triangle-dark' : 'menu-triangle'"
                ></div>
              </div>
              <div @click="setMindComponent" 
              class="each-button-box-text">
                {{ getString(strings.Mind_Edit_Related) }}
              </div>
              <div
                class="complie-header-mind-comp"
                :class="
                  mindComponentAccout
                    ? 'showMindComponent'
                    : 'hideMindComponent'
                "
              >
                <transition name="slide-fade">
                  <ComplieHeaderMind :darkMode="darkMode" />
                </transition>
              </div>
            </div>
            <!-- 插入 -->
            <div
              @click="setIconComponent"
              v-clickoutside="outsideCloseIconCompoenntAccout"
              class="each-button-box"
            >
              <div class="each-button-box-img" style="width: 20px">
                <img
                  v-if="darkMode"
                  class="each-button-box-center-img"
                  src="../../assets/img/mindNewEditMenuIocn/darkMode/insert.svg"
                  alt=""
                />
                <img
                  v-else
                  class="each-button-box-center-img"
                  src="../../assets/img/mindNewEditMenuIocn/insert.svg"
                  alt=""
                />
                <div
                  :class="darkMode ? 'menu-triangle-dark' : 'menu-triangle'"
                ></div>
              </div>
              <div class="each-button-box-text">
                {{ getString(strings.Mind_Edit_Insert) }}
              </div>
              <div
                class="complie-header-img-comp"
                :class="iconCompoenntAccout ? 'showComponent' : 'hideComponent'"
              >
                <ComplieHeaderImg :darkMode="darkMode" />
              </div>
            </div>
          </div>
          <!-- <div class="complie-style-vertical-line"></div> -->
        </div>
        <!-- 气泡结构 -->
        <div
          ref="complieheadermenubox"
          v-if="headerMenuAccout.bubbelLayout"
          class="complie-header-menu-content"
          :style="{ background: rightMenuBg }"
        >
          <div class="complie-style-left-box">
            <div
              @click="getHeaderMenuType(MindElementType.ADD)"
              class="each-button-box"
            >
              <div class="each-button-box-img">
                <img
                  v-if="darkMode"
                  class="each-button-box-center-img"
                  src="../../assets/img/mindNewEditMenuIocn/darkMode/son.svg"
                  alt=""
                />
                <img
                  v-else
                  class="each-button-box-center-img"
                  src="../../assets/img/mindNewEditMenuIocn/son.svg"
                  alt=""
                />
              </div>
              <div class="each-button-box-text">
                {{ getString(strings.Mind_Edit_Subtopic) }}
              </div>
            </div>

            <div
              @click="getHeaderMenuType(MindElementType.ADD_BROTHER_TOP)"
              class="each-button-box"
            >
              <div class="each-button-box-img">
                <img
                  v-if="darkMode"
                  class="each-button-box-center-img"
                  src="../../assets/img/mindNewEditMenuIocn/darkMode/topSon.svg"
                  alt=""
                />
                <img
                  v-else
                  class="each-button-box-center-img"
                  src="../../assets/img/mindNewEditMenuIocn/topSon.svg"
                  alt=""
                />
              </div>
              <div class="each-button-box-text">
                {{ getString(strings.Mind_Edit_Top_Subtopic) }}
              </div>
            </div>

            <div
              @click="getHeaderMenuType(MindElementType.ADD_BROTHER_BOTTOM)"
              class="each-button-box"
            >
              <div class="each-button-box-img">
                <img
                  v-if="darkMode"
                  class="each-button-box-center-img"
                  src="../../assets/img/mindNewEditMenuIocn/darkMode/bottomSon.svg"
                  alt=""
                />
                <img
                  v-else
                  class="each-button-box-center-img"
                  src="../../assets/img/mindNewEditMenuIocn/bottomSon.svg"
                  alt=""
                />
              </div>
              <div class="each-button-box-text">
                {{ getString(strings.Mind_Edit_Bottom_Subtopic) }}
              </div>
            </div>

            <!-- <div
              @click="getHeaderMenuType(MindElementType.FREE_NODE)"
              class="each-button-box"
            >
              <div class="each-button-box-img">
                <img
                  v-if="darkMode"
                  class="each-button-box-center-img"
                  src="../../assets/img/mindNewEditMenuIocn/darkMode/freeNode.svg"
                  alt=""
                />
                <img
                  v-else
                  class="each-button-box-center-img"
                  src="../../assets/img/mindNewEditMenuIocn/freeNode.svg"
                  alt=""
                />
              </div>
              <div class="each-button-box-text">
                {{ getString(strings.Mind_Edit_Free_Node) }}
              </div>
            </div> -->

            <div
              @click="getHeaderMenuType(MindElementType.EXPLAIN)"
              class="each-button-box"
            >
              <div class="each-button-box-img">
                <img
                  v-if="darkMode"
                  class="each-button-box-center-img"
                  src="../../assets/img/mindNewEditMenuIocn/darkMode/floatExplain.svg"
                  alt=""
                />
                <img
                  v-else
                  class="each-button-box-center-img"
                  src="../../assets/img/mindNewEditMenuIocn/floatExplain.svg"
                  alt=""
                />
              </div>
              <div class="each-button-box-text">
                {{ getString(strings.Mind_Float_Explain) }}
              </div>
            </div>

            <div
              @click="setMindComponent"
              v-clickoutside="outsideClose"
              class="each-button-box"
            >
              <div class="each-button-box-img">
                <img
                  v-if="darkMode"
                  class="each-button-box-center-img"
                  src="../../assets/img/mindNewEditMenuIocn/darkMode/relevance.svg"
                  alt=""
                />
                <img
                  v-else
                  class="each-button-box-center-img"
                  src="../../assets/img/mindNewEditMenuIocn/relevance.svg"
                  alt=""
                />
                <div
                  :class="darkMode ? 'menu-triangle-dark' : 'menu-triangle'"
                ></div>
              </div>
              <div class="each-button-box-text">
                {{ getString(strings.Mind_Edit_Related) }}
              </div>
              <div
                class="complie-header-mind-comp"
                :class="
                  mindComponentAccout
                    ? 'showMindComponent'
                    : 'hideMindComponent'
                "
              >
                <transition name="slide-fade">
                  <ComplieHeaderMind :darkMode="darkMode" />
                </transition>
              </div>
            </div>

            <div
              @click="setIconComponent"
              v-clickoutside="outsideCloseIconCompoenntAccout"
              class="each-button-box"
            >
              <div class="each-button-box-img" style="width: 20px">
                <img
                  v-if="darkMode"
                  class="each-button-box-center-img"
                  src="../../assets/img/mindNewEditMenuIocn/darkMode/insert.svg"
                  alt=""
                />
                <img
                  v-else
                  class="each-button-box-center-img"
                  src="../../assets/img/mindNewEditMenuIocn/insert.svg"
                  alt=""
                />
                <div
                  :class="darkMode ? 'menu-triangle-dark' : 'menu-triangle'"
                ></div>
              </div>
              <div class="each-button-box-text">
                {{ getString(strings.Mind_Edit_Insert) }}
              </div>
              <div
                class=""
                :class="iconCompoenntAccout ? 'showComponent' : 'hideComponent'"
              >
                <ComplieHeaderImg :darkMode="darkMode" />
              </div>
            </div>
          </div>
          <!-- <div class="complie-style-vertical-line"></div> -->
        </div>

        <!-- 导图控制菜单Icon -->
        <div
          class="complie-header-right-menu"
          ref="complieHeaderRightMenu"
          :style="{
            height: rightMenuHeight,
            top: rightMenuTop,
            background: rightMenuBg,
          }"
        >
          <template>
            <div class="complie-style-right-box" ref="mindmapPlayAudioBox">
              <!-- AI -->
              <div
                class="each-button-box"
                :class="{ 'each-button-right-box': !isItChinese() }"
              >
                <div
                  @click.stop="setAiComponent"
                  class="each-button-box-img"
                >
                  <img
                    v-if="darkMode"
                    class="each-button-box-center-img"
                    src="../../assets/img/mindNewEditMenuIocn/darkMode/ai_icon.svg"
                    alt=""
                  />
                  <img
                    v-else
                    class="each-button-box-center-img"
                    src="../../assets/img/mindNewEditMenuIocn/ai_icon.svg"
                    alt=""
                  />
                </div>
                <div
                  @click.stop="setAiComponent"
                  class="each-button-box-text"
                >
                  {{ getString(strings.Mind_Edit_Ai_Title) }}
                </div>
                <div
                  class="complie-header-mindstyle-comp"
                  v-show="mindAudioComponentAccout"
                  style="right: 100px"
                >
                  <transition name="slide-fade">
                    <ComplieAudio @audioToHeaderShow="audioComponentAccout" />
                  </transition>
                </div>
              </div>
              <!-- 录音 -->
              <div
                class="each-button-box"
                :class="{ 'each-button-right-box': !isItChinese() }"
              >
                <div
                  @click.stop="setMindAudioComponent"
                  class="each-button-box-img"
                >
                  <img
                    v-if="darkMode"
                    class="each-button-box-center-img"
                    src="../../assets/img/mindNewEditMenuIocn/darkMode/voice.svg"
                    alt=""
                  />
                  <img
                    v-else
                    class="each-button-box-center-img"
                    src="../../assets/img/mindNewEditMenuIocn/voice.svg"
                    alt=""
                  />
                </div>
                <div
                  @click.stop="setMindAudioComponent"
                  class="each-button-box-text"
                >
                  {{ getString(strings.Mind_Edit_Recording) }}
                </div>
                <div
                  class="complie-header-mindstyle-comp"
                  v-show="mindAudioComponentAccout"
                  style="right: 100px"
                >
                  <transition name="slide-fade">
                    <ComplieAudio @audioToHeaderShow="audioComponentAccout" />
                  </transition>
                </div>
              </div>
              <!-- 导图回顾 -->
              <div
                @click="mindmapPlay('MindmapPlay')"
                class="each-button-box"
                :class="{ 'each-button-right-box': !isItChinese() }"
              >
                <div class="each-button-box-img">
                  <img
                    v-if="darkMode"
                    class="each-button-box-center-img"
                    src="../../assets/img/mindNewEditMenuIocn/darkMode/playMind.svg"
                    alt=""
                  />
                  <img
                    v-else
                    class="each-button-box-center-img"
                    src="../../assets/img/mindNewEditMenuIocn/playMind.svg"
                    alt=""
                  />
                </div>
                <div class="each-button-box-text">
                  {{ getString(strings.Mind_Edit_Page_Mind_Retrospect) }}
                </div>
              </div>
            </div>
            <!-- 一键配色 -->
            <div
              v-clickoutside="outsideCloseMindStyleComponent"
              id="one_key_discoloration"
              class="each-button-box each-button-box-padding0"
            >
              <div
                class="eachbuttonboxchild"
                :style="{ padding: `0 ${isItChinese() ? 15 : 10}px` }"
                @click.stop="setMindStyleComponent"
              >
                <div class="each-button-box-img">
                  <img
                    v-if="darkMode"
                    class="each-button-box-center-img"
                    style="position: relative; top: 2px"
                    src="../../assets/img/mindNewEditMenuIocn/oneKeyDiscoloration/darkMode/FormatBrushIcon.svg"
                    alt=""
                  />
                  <img
                    v-else
                    class="each-button-box-center-img"
                    style="position: relative; top: 2px"
                    src="../../assets/img/mindNewEditMenuIocn/oneKeyDiscoloration/lightMode/FormatBrushIcon.svg"
                    alt=""
                  />
                  <div
                    :class="darkMode ? 'menu-triangle-dark' : 'menu-triangle'"
                  ></div>
                </div>
                <div class="each-button-box-text">
                  {{ getString(strings.Mind_Edit_Node_Menu_Quick_Style) }}
                </div>
              </div>

              <div
                class="complie-header-mindstyle-comp"
                :class="
                  mindStyleComponentAccout
                    ? 'showMindStyleComponent'
                    : 'hideMindStyleComponent'
                "
              >
                <transition name="slide-fade">
                  <ComplieChildMenuTheme
                    @setMindStyleComponent="setMindStyleComponent"
                  />
                </transition>
              </div>
            </div>
            <div
              v-if="showStyleTitle"
              :class="
                isSafari ? 'style_title_frame_Safari' : 'style_title_frame'
              "
            >
              <img
                class="style_title_frame_img"
                style="position: absolute"
                src="../../assets/img/styleImg/style-title.png"
              />
              <span
                class="style_title_frame_span"
                style="
                  position: absolute;
                  line-height: 30px;
                  margin-left: 10px;
                  color: #333333;
                "
                >尝试快速美化您的导图</span
              >
            </div>
          </template>
          <!-- 保存状态 -->
          <section class="save-status" v-if="false">
            <template v-if="editAccout.url == ''">
              <!-- <div>
                <a-icon type="exclamation-circle" />
              </div>
              <div style="margin-left: 5px;">
                未编辑
              </div> -->
              <label>
                {{ getString(strings.Public_Header_Save) }}
              </label>
            </template>
            <template v-if="editAccout.url != ''">
              <!-- <div v-show="editAccout.url != ''">
                <a-icon :type="editAccout.url" />
              </div> -->
              <template v-if="editAccout.url == 'exclamation-circle'">
                <img
                  v-if="darkMode"
                  src="../../assets/img/saveMap/darkModeIcon/editedWhite.svg"
                  alt=""
                />
                <img
                  v-else
                  src="../../assets/img/saveMap/lightModeIcon/edited.svg"
                  alt=""
                />
              </template>
              <template v-else-if="editAccout.url == 'check-circle'">
                <img
                  v-if="darkMode"
                  src="../../assets/img/saveMap/darkModeIcon/savedWhite.svg"
                  alt=""
                />
                <img
                  v-else
                  src="../../assets/img/saveMap/lightModeIcon/saved.svg"
                  alt=""
                />
              </template>
              <div style="margin-left: 5px">
                {{ editAccout.text.replace("更新", "") }}
              </div>
            </template>
            <template
              v-if="editAccout.url != 'check-circle' && editAccout.url != ''"
            >
              <label
                class="save-btn save-hover-btn cursor-pointer"
                @click="saveMindData"
              >
                {{ getString(strings.Public_Header_Save) }}
              </label>
            </template>
          </section>
          <!-- <div class="complie-header-menu-box" >
            <div class="complie-menu-uniquestyle">
              <div class="comple-menu-icon" @click="setMindStyleComponent">
                <img
                  class="comple-menu-accout"
                  src="../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/header_mind_style.png"
                  alt
                />
              </div>
              <div class="comple-menu-ico-text">
                <span>{{ getString(strings.Mind_Edit_Style) }}</span>
              </div>
              <div
                class="complie-header-mindstyle-comp"
                :class="
                  mindStyleComponentAccout
                    ? 'showMindStyleComponent'
                    : 'hideMindStyleComponent'
                "
              >
                <transition name="slide-fade">
                  <ComplieChildMenuTheme />
                </transition>
              </div>
            </div>
          </div> -->

          <div
            ref="complieHeaderRightMenuBox"
            class="header-right-menu-box"
            style="display: flex"
          >
            <div
              class="menu-box icon"
              :style="{ background: rightMenuIconBg }"
              v-for="(item, index) in rightMenuIcon"
              :key="index"
              @click="setMenuShowDetial(index, item.type)"
              :class="
                menuIconClickIndex == index && darkMode ? 'active-border' : ''
              "
            >
              <div
                class="menu-box-content"
                :class="
                  menuIconClickIndex == index && !darkMode ? 'active' : ''
                "
              >
                <div
                  class="iconfont xiaolian mindicon-text"
                  v-html="item.Icon"
                ></div>
              </div>
              <div class="menu-box-text">{{ item.text }}</div>
            </div>
          </div>
        </div>
        <!-- 对齐菜单 -->
        <transition name="slide-fade">
          <div class="complie-header-align-menu" ref="multiSelectionMenuBox">
            <template v-if="isShowAlignButton">
              <div
                v-for="(item, index) in alignmentMenu"
                :key="index"
                class="menu-box-content"
                @click="setAlignIcon(item.aligntype)"
              >
                <div class="align-icon">
                  <img :src="item.alignIcon" alt="" />
                </div>
              </div>
            </template>

            <div
              @click="setAlignIcon('nodesSuchAsWide')"
              class="right-button-box"
              v-if="isShowSameWidth"
            >
              <div class="right-button">节点等宽</div>
            </div>
            <div
              @click="setAlignIcon('saveImg')"
              class="right-button-box"
              v-if="isShowSameWidth"
            >
              <!-- 保存图片 -->
              <div class="right-button">
                {{ getString(strings.Mind_Edit_Left_Menu_Save_Picture) }}
              </div>
            </div>
          </div>
        </transition>
      </div>
      <!-- 大纲 -->
      <div class="complie-style" v-else>
        <div
          v-if="headerMenuAccout.outlineLayout"
          class="complie-header-menu-content"
          style="min-width: 824px"
        >
          <div
            @click="getHeaderMenuType(MindElementType.NODE_MOVE_BACKWARD)"
            class="each-button-box"
          >
            <div class="each-button-box-img">
              <img
                class="each-button-box-center-img"
                src="../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/indent.svg"
                alt=""
              />
            </div>
            <div class="each-button-box-text">
              {{ getString(strings.Outline_Menu_Indent) }}
            </div>
          </div>
          <div
            @click="getHeaderMenuType(MindElementType.NODE_MOVE_FORWARD)"
            class="each-button-box"
          >
            <div class="each-button-box-img">
              <img
                class="each-button-box-center-img"
                src="../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/decrease_indent.svg"
                alt=""
              />
            </div>
            <div class="each-button-box-text">
              {{ getString(strings.Outline_Menu_Decrease_Indent) }}
            </div>
          </div>
          <div
            @click="getHeaderMenuType(MindElementType.CONTENT_GENERALIZATION)"
            class="each-button-box"
          >
            <div class="each-button-box-img">
              <img
                class="each-button-box-center-img"
                src="../../assets/img/mindNewEditMenuIocn/digest.svg"
                alt=""
              />
            </div>
            <div class="each-button-box-text">
              {{ getString(strings.Mind_Edit_Summary) }}
            </div>
          </div>

          <div
            @click="getHeaderMenuType('Icon_Details')"
            v-clickoutside="outsideCloseIconDetialsMenu"
            class="each-button-box"
          >
            <div class="each-button-box-img">
              <img
                class="each-button-box-center-img"
                src="../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/icon_detials.svg"
                alt=""
              />
            </div>
            <div class="each-button-box-text">
              {{ getString(strings.Mind_Edit_Right_Icon) }}
            </div>
            <div class="icon-detials-box" v-show="isShowIconDetialsMenu">
              <ComplieRightMenuIcon v-show="this.isShowIconDetialsMenu" />
            </div>
          </div>
          <div class="outline-style-vertical-line"></div>

          <div @click="getHeaderMenuType('Font_Color')" class="each-button-box">
            <div
              class="complie-color"
              :style="{ background: fontTextColor }"
            ></div>
            <div class="each-button-box-text">
              {{ getString(strings.Mind_Edit_Right_Menu_text_Color) }}
            </div>
            <div class="font-detials-box" v-if="this.isShowFontColorModal">
              <CommonBgColor />
            </div>
          </div>

          <div
            v-for="(item, index) in headerOutline.fontStyleMenu"
            :key="'font' + index"
            @click="getHeaderMenuType('Font_Style', index)"
            class="each-button-box"
          >
            <div
              class="each-button-box-icon"
              :class="
                item.fontStyleAccout ? 'font-stlye-before' : 'menu-font-stlye'
              "
            >
              <div
                class="Outline each-button-outline-text"
                v-html="item.menuStyleIcon"
              ></div>
            </div>
            <div class="each-button-box-text">
              {{ getString(item.menuText) }}
            </div>
          </div>
        </div>
        <!-- 大纲控制菜单Icon -->
        <div
          class="complie-header-right-menu"
          :style="{ height: rightMenuHeight, top: rightMenuTop }"
        >
          <!-- 保存状态 -->
          <transition name="slide-fade">
            <div
              ref="editSavebg"
              class="edit-accout"
              v-show="
                (routerData == 'Complie' ||
                  (routerData == 'gmap' && mindMapingType.value == true) ||
                  (routerData == 'arvhiveMap' &&
                    mindMapingType.value == true)) &&
                editAccout.url != ''
              "
            >
              <div v-show="editAccout.url != ''">
                <a-icon :type="editAccout.url" />
              </div>
              <div style="margin-left: 5px; margin-top: -2px">
                {{ editAccout.text }}
              </div>
              <div
                ref="saveButton"
                class="save-button"
                v-show="
                  editAccout.url != 'check-circle' && editAccout.url != ''
                "
              >
                <a-button type="primary" @click="saveMindData">{{
                  getString(strings.Public_Header_Save)
                }}</a-button>
              </div>
            </div>
          </transition>

          <div
            class="menu-box icon"
            v-for="(item, index) in rightMenuIconOutline"
            :key="index"
            @click="setMenuShowDetial(index, item.type)"
          >
            <div class="menu-box-content">
              <div class="mindicon mindicon-text" v-html="item.Icon"></div>
            </div>
            <div class="menu-box-text">{{ item.text }}</div>
          </div>
        </div>
      </div>
    </div>
    <ComplieMindmapPlayModal />
    <div
      id="printimg"
      style="
        width: 100%;
        height: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
        text-align: center;
      "
    >
      <img
        :style="{
          maxWidth: maxWidth,
          maxHeight: maxHeight,
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform:
            'translate(-50%,' + (isSafari && !isEelctron ? '0' : '-50%') + ')',
        }"
        :src="printImageSrc"
        alt=""
      />
      <!-- <img  style="maxWidth:100%; maxHeight:500px;  position: absolute; left: 50%; top: 50%;transform:translate(-50%,-50%);" :src="printImageSrc" alt=""> -->
    </div>
    <!-- 导入到共享组 -->
    <MyMindAddGroupModal
      :mindItem="initMindGroupViewData"
      :show="showMindAddGroup"
      @hiddenMindAddGroup="hiddenMindAddGroup"
    />
    <!-- 导出PDF -->
    <section
      class="export-pdf-bg"
      v-if="exportPdfType"
      @click.stop="showExportPdfType(false)"
    >
      <div class="export-pdf-box">
        <div class="export-pdf-name">
          <div class="export-pdf-title">
            {{
              $getStringObj.getString($Strings.Mind_Edit_Left_Menu_Export_Pdf)
            }}
          </div>
          <div class="export-pdf-close">
            <img
              class="close-button-img"
              src="../../assets/img/popupsImg/colse_new.svg"
              @click.stop="showExportPdfType(false)"
            />
          </div>
        </div>
        <div class="export-pdf-button">
          <a-button type="primary" @click.stop="savePdfType(1)">
            默认
          </a-button>
          <a-button
            title="另存为PDF，文字支持复制"
            @click.stop="savePdfType(2)"
          >
            高级
          </a-button>
          <img
            src="../../assets/img/user/icon/vip.svg"
            class="need-member"
            alt="vip"
          />
        </div>
      </div>
    </section>
    <SpinningAnimation :spinning="spinning" />
  </div>
</template>
<script>
import { mapMutations } from "vuex";

import MindOperateUIControllerView from "./tools/MindOperateUIControllerView";

import ComplieAudio from "./ComplieHeader/ComplieAudio.vue";
import ComplieHeaderImg from "./ComplieHeader/ComplieHeaderImg";
import ComplieHeaderMind from "./ComplieHeader/ComplieHeaderMind";
import CommonBgColor from "../common/CommonBgColor/CommonBgColor.vue";
import ComplieChildMenuTheme from "./ComplieHeader/ComplieChildMenuTheme";
import ComplieRightMenuIcon from "./ComplieRightMenuIcon/ComplieRightMenuIcon.vue";
import ComplieMindmapPlayModal from "./ComplieHeader/ComplieMindmapPlayModal";

import Logger from "../../utils/Logger";
import colorChangeClass from "../../utils/ColorToSystem";

import strings from "../../common/lang/strings";
import getString from "../../common/lang/language";

import Point from "../../viewmodel/core/base/Point";
import MindType from "../../viewmodel/datatype/MindType";
import MindElementType from "../../viewmodel/datatype/MindElementType";
import EditMindmapVM from "../../viewmodel/mindelementdata/vm/EditMindmapVM";
import { postMindmapPlay } from "../../common/netWork/mind_map_api";

import VueDataEvent from "../../core/core/basemode/VueDataEvent";
import ComplieExportMenu from "./ComplieHeader/ComplieExportMenu";
import ComplieSwitchMapMenu from "./ComplieHeader/ComplieSwitchMapMenu";
import ToImage from "../../utils/ToImage";
import Colors from "../../utils/Colors";
import MindDisplayType from "../../viewmodel/datatype/MindDisplayType";
import Template from "../../views/template/Template.vue";
import Util from "../../utils/Util";
import MindSettingManager from "../../viewmodel/core/tools/setting/MindSettingManager";
import KeyboardValueType from "../../viewmodel/core/tools/setting/KeyboardValueType";
import MyMindAddGroupModal from "../myMind/myMindChid/MyMindAddGroupModal";
import ExportFile from "../../core/core/dataformat/exportFile/ExportFile";
import ConfigurationItem from "../../assets/js/ConfigurationItem.js";
const { menuListType, newGuideNodeLocation } = ConfigurationItem;
import { getJwt } from "../../common/netWork/base";
import {
  isClickSaveMapButton,
  sessionStorageKey,
  saveImgModes,
  eventBusMethod,
} from "../../assets/js/GlobalVariable.js";
import SpinningAnimation from "./../spinningAnimation/SpinningAnimation";
import ConnectLineType from '../../viewmodel/datatype/ConnectLineType';
import UiUtil from '../../utils/UiUtil';
import LoadLocalXJMind from '../../viewmodel/core/tools/mapfile/LoadLocalXJMind';
import MindMe from '../../viewmodel/facade/MindMe';

export default {
  inject: ["reload"],
  props: {
    initMindGroupViewData: {
      type: Object,
      defualt() {
        return {};
      },
    },
    mindMapingType: {
      type: VueDataEvent,
      defualt() {
        return new VueDataEvent(false);
      },
    },
    parentId: String,
  },
  data() {
    return {
      nowElectron: false,
      //线性结构
      headerMenuStyle: [
        {
          menuStyleIcon: require("../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/headerMenuIcon (1).png"),
          menuText: getString(strings.Mind_Edit_Revoke),
          menuStyle: MindElementType.BACKWARD_RETREAT,
          // imgHeight:
        },
        {
          menuStyleIcon: require("../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/headerMenuIcon (2).png"),
          menuText: getString(strings.Mind_Edit_Restore),
          menuStyle: MindElementType.FORWARD,
        },
        {
          menuStyleIcon: require("../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/headerMenuIcon (3).png"),
          menuText: getString(strings.Mind_Edit_Subtopic),
          menuStyle: MindElementType.ADD,
        },
        {
          menuStyleIcon: require("../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/headerMenuIcon (4).png"),
          menuText: getString(strings.Mind_Edit_Top_Subtopic),
          menuStyle: MindElementType.ADD_BROTHER_TOP,
        },
        {
          menuStyleIcon: require("../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/headerMenuIcon (5).png"),
          menuText: getString(strings.Mind_Edit_Bottom_Subtopic),
          menuStyle: MindElementType.ADD_BROTHER_BOTTOM,
        },
        // {
        //   menuStyleIcon: require("../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/headerMenuIcon (6).png"),
        //   menuText: getString(strings.Mind_Edit_Free_Node),
        //   menuStyle: MindElementType.FREE_NODE,
        // },
        {
          menuStyleIcon: require("../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/headerIconFloatExplain.png"),
          menuText: getString(strings.Mind_Float_Explain),
          menuStyle: MindElementType.EXPLAIN,
        },
        {
          menuStyleIcon: require("../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/headerMenuIcon (7).png"),
          menuText: getString(strings.Mind_Edit_Summary),
          menuStyle: MindElementType.CONTENT_GENERALIZATION,
        },
        {
          menuStyleIcon: require("../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/headerMenuIcon (8).png"),
          menuText: getString(strings.Mind_Edit_Block),
          menuStyle: MindElementType.BAOWEI_VIEW,
        },
      ],
      //气泡 时间线结构
      headerBubbelMenuStyle: [
        {
          menuStyleIcon: require("../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/headerMenuIcon (1).png"),
          menuText: getString(strings.Mind_Edit_Revoke),
          menuStyle: MindElementType.BACKWARD_RETREAT,
        },
        {
          menuStyleIcon: require("../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/headerMenuIcon (2).png"),
          menuText: getString(strings.Mind_Edit_Restore),
          menuStyle: MindElementType.FORWARD,
        },
        {
          menuStyleIcon: require("../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/headerMenuIcon (3).png"),
          menuText: getString(strings.Mind_Edit_Subtopic),
          menuStyle: MindElementType.ADD,
        },
        {
          menuStyleIcon: require("../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/headerMenuIcon (4).png"),
          menuText: getString(strings.Mind_Edit_Top_Subtopic),
          menuStyle: MindElementType.ADD_BROTHER_TOP,
        },
        {
          menuStyleIcon: require("../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/headerMenuIcon (5).png"),
          menuText: getString(strings.Mind_Edit_Bottom_Subtopic),
          menuStyle: MindElementType.ADD_BROTHER_BOTTOM,
        },
        // {
        //   menuStyleIcon: require("../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/headerMenuIcon (6).png"),
        //   menuText: getString(strings.Mind_Edit_Free_Node),
        //   menuStyle: MindElementType.FREE_NODE,
        // },
        {
          menuStyleIcon: require("../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/headerIconFloatExplain.png"),
          menuText: getString(strings.Mind_Float_Explain),
          menuStyle: MindElementType.EXPLAIN,
        },
      ],
      //大纲模式
      headerOutline: {
        leftMenu: [
          {
            menuStyleIcon: require("../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/indent.svg"),
            menuText: strings.Outline_Menu_Indent,
            menuStyle: MindElementType.NODE_MOVE_BACKWARD,
          },
          {
            menuStyleIcon: require("../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/decrease_indent.svg"),
            menuText: strings.Outline_Menu_Decrease_Indent,
            menuStyle: MindElementType.NODE_MOVE_FORWARD,
          },
          {
            menuStyleIcon: require("../../assets/img/mindNewEditMenuIocn/digest.svg"),
            menuText: strings.Mind_Edit_Summary,
            menuStyle: MindElementType.CONTENT_GENERALIZATION,
          },
        ],

        fontStyleMenu: [
          {
            menuStyleIcon: "&#58885", //require("../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/font_style_b.svg"),
            menuText: strings.Font_Style_Bold,
            fontStyleAccout: false,
          },
          {
            menuStyleIcon: "&#58886", //require("../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/font_style_i.svg"),
            menuText: strings.Font_Style_Italics,
            fontStyleAccout: false,
          },
          {
            menuStyleIcon: "&#58887", //require("../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/font_style_u.svg"),
            menuText: strings.Font_Style_Delete_Line,
            fontStyleAccout: false,
          },
        ],

        // {
        //   menuStyleIcon: require("../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/outline_delete.svg"),
        //   menuText: strings.Mind_Mindmap_Deleted_Forever,
        //   menuStyle: "Delete_Node",
        // },
      },
      //对齐菜单
      alignmentMenu: [
        {
          alignIcon: require("../../assets/img/mapIcon/res/mipmap-icon/mindmap-header-align-icon/align_top.svg"),
          aligntype: "topAlignment",
        },
        {
          alignIcon: require("../../assets/img/mapIcon/res/mipmap-icon/mindmap-header-align-icon/align_center.svg"),
          aligntype: "centreHorizontalAlignment",
        },
        {
          alignIcon: require("../../assets/img/mapIcon/res/mipmap-icon/mindmap-header-align-icon/align_button.svg"),
          aligntype: "bottomAlignment",
        },
        {
          alignIcon: require("../../assets/img/mapIcon/res/mipmap-icon/mindmap-header-align-icon/align_vertical.svg"),
          aligntype: "averageDistributionVertical",
        },
        {
          alignIcon: require("../../assets/img/mapIcon/res/mipmap-icon/mindmap-header-align-icon/align_left.svg"),
          aligntype: "leftAlignment",
        },
        {
          alignIcon: require("../../assets/img/mapIcon/res/mipmap-icon/mindmap-header-align-icon/align_vertical_center.svg"),
          aligntype: "centreVerticalAlignment",
        },
        {
          alignIcon: require("../../assets/img/mapIcon/res/mipmap-icon/mindmap-header-align-icon/align_right.svg"),
          aligntype: "averageDistributionHorizontal",
        },
        {
          alignIcon: require("../../assets/img/mapIcon/res/mipmap-icon/mindmap-header-align-icon/align_center_hotizontally.svg"),
          aligntype: "rightAlignment",
        },
      ],
      viewModel: null,
      MindOperateUIControllerView: null,
      iconCompoenntAccout: false,
      mindComponentAccout: false,
      mindStyleComponentAccout: false,
      mindAudioComponentAccout: false,
      headerMenuAccout: {
        linelayout: false,
        timelayout: false,
        bubbelLayout: false,
        doubelBubbleLayout: false,
        outlineLayout: false,
        addNode: true,
      }, //当前导图类型
      rightMenuIcon: [
        {
          Icon: "&#58882;",
          text: getString(strings.Public_Header_Outline),
          type: "OutlineOrMindmap",
        },
        {
          Icon: "&#58643;",
          text: getString(strings.Mind_Edit_Right_Style),
          type: "Style",
        },
        {
          Icon: "&#58919;",
          text: getString(strings.Mind_Edit_Right_Icon),
          type: "Icon",
        },
      ], //右侧菜单Icon
      rightMenuIconOutline: [
        {
          Icon: "&#58884;",
          text: getString(strings.Public_Header_Mindmap_Mind),
          type: "OutlineOrMindmap",
        },
      ], //大纲模式下
      menuIconClickIndex: null, //Icon索引
      menuIconClickAccout: false, //菜单是否显示
      isMac: false, //是否为mac os环境
      hideOutlineAccout: false, //是否为大纲模式
      strings, //语言包
      isShowAlignButton: false, //显示对齐按钮
      isShowSameWidth: false, //是否显示节点等宽按钮
      isShowOutline: false, //是否显示大纲
      isShowIconDetialsMenu: false, //是否显示图标菜单
      isShowFontColorModal: false, //是否显示字体颜色面板
      fontTextColor: "#ffffff", //文字颜色
      nodeAccout: null, //节点数据
      editAccout: {
        text: "",
        url: "",
      }, //保存状态
      routerData: this.$router.history.current.name, //路由状态
      mindmapPlayButton: false, //播放状态(true播放/false关闭)
      isShowMindmapPlaySetUp: false, //显示播放导图模态框
      nowElectron: false, //当前是否在electron环境中

      MindElementType: MindElementType,
      showMenu: false, //展示菜单
      showSwitchMapMenu: false, //切换导图菜单
      menuList: [], //菜单要展示的内容
      pointObj: null, //节点dom数据
      menuPoint: null, //菜单的位置
      editMindmapContent: EditMindmapVM,

      printImageSrc: "", //打印的图片
      maxWidth: "none", //最大宽度
      maxHeight: "100%", //最大高度
      isShareGroup: false, //是否共享组
      isSafari: false,
      isEelctron: false,
      darkMode: false, //深色模式
      showColorMatchingIcon: true, //一键配色图标动画效果
      intervalStyle: null,
      rightMenuIconBg: "#e4e4e4",
      nodeContentMessage: null, // 当前是否选中节点
      KeyboardValueType,
      keyboard: {
        REVOKE: "",
        RECOVERY: "",
        ADD_CHILD_NODE: "",
        ADD_TOP_CHILD_NODE: "",
        ADD_BOTTOM_CHILD_NODE: "",
      },
      editMindmapContentVM: null,
      showMindAddGroup: false, //是否展示导入到共享组
      showStyleTitle: false, //是否显示风格提示框
      exportPdfType: false, //选择导出PDF类型
      rightMenuHeight: "65px",
      rightMenuTop: "0",
      rightMenuBg: "#f5f5f5",
      centerMenuWidth: document.documentElement.clientWidth - 722,
      spinning: false,
    };
  },
  components: {
    ComplieAudio,
    CommonBgColor,
    ComplieHeaderImg,
    ComplieHeaderMind,
    ComplieRightMenuIcon,
    ComplieChildMenuTheme,
    ComplieMindmapPlayModal,
    MyMindAddGroupModal,
    ComplieExportMenu,
    ComplieSwitchMapMenu,
    Template,
    SpinningAnimation,
  },
  created() {
    this.isMac = !this.$tools.isMac();
    this.nowElectron = this.$tools.isElectron();

    //是否是mac
    this.isSafari = this.$tools.isMacSafari();
    if (this.$route.name == "gmap") {
      this.isShareGroup = true;
    }
    this.editMindmapContentVM = EditMindmapVM;
  },
  mounted() {
    if (this.viewModel == null) {
      this.viewModel = this.$store.state.editMindmap;
    }
    this.bus.$on("isShowIconDetialsMenu", (msg) => {
      this.isShowIconDetialsMenu = msg;
    });

    this.editMindmapContentVM = EditMindmapVM;

    this.editMindmapContentVM.headerMenu = this;
    // this.blink();
    this.$nextTick(() => {
      let timer = setInterval(() => {
        const subtopics = this.$refs.subtopics;
        if (subtopics) {
          this.getSubtopicsSite(subtopics);
          clearTimeout(timer);
        }
        if (timer > 10000) {
          clearTimeout(timer);
        }
      }, 100);
    });
    window.addEventListener("resize", () => {
      this.oneClickColorCenter();
      this.setCenterMenuWidth();
    });
    this.bus.$on(eventBusMethod.PrintImg, (val) => {
      // 打印
      if (val) {
        this.startPrinting(val);
      }
    });
  },
  methods: {
    ...mapMutations([
      "getTextStlye",
      "setHeaderMenu",
      "showLoginModel",
      "isShowShoppingModel",
      "setAddIconPage",
      "alignNodeStyle",
      "showDaGangModel",
      "markColorAccout",
      "mindmapRightMenu",
      "isShowShoppingModel",
      "saveEditAccoutToHeader",
      "mindmapPlayPauseShowSetup",
      "setMapEditIsDardarkMode",
      "imgTypeModelAccout",
      "showCropperImg",
      "showCropperImgUrl",
      "sharePotatoChipsModel",
      "hideModelLink",
      "setFreeNodeMoveAdsorbent",
      "setBranchFreeLayout",
      "setShowBranchFreeLayout",
      "topToolDardarkMode",
      "setShowSkeleton",
      "setGlobalLineWidth",
      "setShowAddAi",
    ]),

    checkMenu(e, show) {
      this.pointObj = this.$refs.eachbuttonboxchild.getBoundingClientRect();
      this.showMenu = !this.showMenu;
      this.menuList = [
        {
          image: require("../../assets/img/gMap/save-img.svg"),
          imgWhite: require("../../assets/img/export/darkModeIcon/saveWhite.svg"),
          name: this.$getStringObj.getString(
            this.$Strings.Mind_Edit_Left_Menu_Save_Picture
          ),
          menuType: "save_Type",
        },
        {
          image: require("../../assets/img/gMap/derive-pdf.svg"),
          imgWhite: require("../../assets/img/export/darkModeIcon/derivePdfWhite.svg"),
          name: this.$getStringObj.getString(
            this.$Strings.Mind_Edit_Left_Menu_Export_Pdf
          ),
          menuType: "save_Pdf",
        },
        {
          image: require("../../assets/img/export/exportWord.svg"),
          imgWhite: require("../../assets/img/export/darkModeIcon/exportWordWhite.svg"),
          name: this.$getStringObj.getString(
            this.$Strings.Mind_Edit_Left_Menu_Export_Word
          ),
          menuType: menuListType.exportWord,
          needMember: true,
        },
        {
          image: require("../../assets/img/export/exportTXT.svg"),
          imgWhite: require("../../assets/img/export/darkModeIcon/exportTXTWhite.svg"),
          name: this.$getStringObj.getString(
            this.$Strings.Mind_Edit_Left_Menu_Export_TXT
          ),
          menuType: menuListType.exportTxt,
          needMember: true,
        },
        {
          image: require("../../assets/img/export/exportSave.svg"),
          imgWhite: require("../../assets/img/export/darkModeIcon/exportSaveWhite.svg"),
          name: this.$getStringObj.getString(
            this.$Strings.Public_Header_Save
          ),
          menuType: menuListType.exportSave,
          needMember: false,
        },
        {
          image: require("../../assets/img/export/exportXjmm.svg"),
          imgWhite: require("../../assets/img/export/darkModeIcon/exportXjmmWhite.svg"),
          name: this.$getStringObj.getString(
            this.$Strings.Mind_Edit_Left_Menu_Export_Save_As
          ),
          menuType: menuListType.exportSaveAs,
          needMember: false,
        },
        {
          image: require("../../assets/img/mindNewEditMenuIocn/shareLink.svg"),
          imgWhite: require("../../assets/img/export/darkModeIcon/shareLinkWhite.svg"),
          name: this.$getStringObj.getString(
            strings.Mind_Edit_Left_Menu_Share_Links
          ),
          menuType: menuListType.shareLink,
        },
        {
          image: require("../../assets/img/gMap/share-scrips.svg"),
          imgWhite: require("../../assets/img/export/darkModeIcon/shareScripsWhite.svg"),
          name: this.$getStringObj.getString(
            this.$Strings.Mind_Edit_Left_Menu_Share_The_Storm
          ),
          menuType: "share_PotatoChips",
        },
        {
          image: require("../../assets/img/homeHeaderIcon/icon/group-gray.svg"),
          imgWhite: require("../../assets/img/export/darkModeIcon/groupWhite.svg"),
          name: this.$getStringObj.getString(
            this.$Strings.Mind_Mind_Import_Into_A_Shared_Group
          ),
          menuType: menuListType.sharedGroup,
        },
        {
          image: require("../../assets/img/mindNewEditMenuIocn/print.svg"),
          imgWhite: require("../../assets/img/export/darkModeIcon/printWhite.svg"),
          name: this.$getStringObj.getString(
            strings.Mind_Edit_Left_Menu_Printing
          ),
          menuType: "save_Printf",
          needMember: true,
        },
        {
          image: require("../../assets/img/mindNewEditMenuIocn/passwordIcon.svg"),
          imgWhite: require("../../assets/img/export/darkModeIcon/passwordIconWhite.svg"),
          name: this.$getStringObj.getString(
            strings.Mind_Set_Password
          ),
          menuType: "setting_mindpassword",
        },
        {
          image: require("../../assets/img/mindNewEditMenuIocn/member.svg"),
          imgWhite: require("../../assets/img/export/darkModeIcon/memberWhite.svg"),
          name: this.$getStringObj.getString(
            strings.Mind_Edit_Left_Menu_Unlock_Full_Version
          ),
          menuType: "unlock_Full_Version",
        },
      ];
      //当前的页面是否是群组的编辑页面,是的话去除分享链接,导入到共享组按钮
      if (this.$router.history.current.name == "gmap") {
        this.menuList = this.menuList.filter(
          (item) => item.menuType !== menuListType.shareLink
        );
        this.menuList = this.menuList.filter(
          (item) => item.menuType !== menuListType.sharedGroup
        );
      }
      //当前的页面是否可以导出txt,world
      let mindType = this.$store.state.initData.mindType;
      if (mindType != MindType.LINE_MAP && mindType != MindType.BUBBLE_MAP) {
        this.menuList = this.menuList.filter(
          (item) => item.menuType !== menuListType.exportWord
        );
        this.menuList = this.menuList.filter(
          (item) => item.menuType !== menuListType.exportTxt
        );
      }
      if (MindMe.isUpgrade()) {
        this.menuList = this.menuList.filter(
          (item) => item.menuType !== "unlock_Full_Version"
        );
      }
      this.editMindmapContent.uncheckNode(); //取消选中节点
      this.editMindmapContent.delegate.exitEditStatusSaveMap(); // 退出编辑状态，保存导图
      this.menuPoint = {
        val1: "bottom",
        val2: "right",
        type: "eachMenu",
      };
    },
    switchMapMenu(e, show) {
      this.pointObj = this.$refs.eachbuttonboxchild.getBoundingClientRect();
      this.showSwitchMapMenu = !this.showSwitchMapMenu;
      this.menuPoint = {
        val1: "bottom",
        val2: "right",
        type: "eachMenu",
      };
    },
    // 允许保存图片/PDF/Word/TXT
    allowExport() {
      const allowSave = this.initMindGroupViewData.allowSaveImage;
      if (allowSave === undefined) {
        return true;
      }
      if (!allowSave) {
        this.$message.error(
          this.$getStringObj.getString(this.$Strings.No_Permission_Do_This)
        );
      }
      return allowSave;
    },
    showExportPdfType(val) {
      this.exportPdfType = val;
    },
    setMenuAccout(type) {
      if (!getJwt("jwt")) {
        //所有按钮都需要登录
        this.showLoginModel(true);
        return;
      }
      switch (type) {
        case "save_Type":
          if (!this.allowExport()) {
            return;
          }
          this.saveImgType();
          break;
        case "save_Pdf":
          if (!this.allowExport()) {
            return;
          }
          // if (!this.nowElectron) {
          //   this.showExportPdfType(true)
          // }else{
          //   this.savePdfType(1);
          // }
          this.savePdfType(1);
          break;
        case menuListType.exportWord:
          if (!this.allowExport()) {
            return;
          }
          this.exportWordFun();
          break;
        case menuListType.exportTxt:
          if (!this.allowExport()) {
            return;
          }
          this.exportTXTFun();
          break;
        case menuListType.exportSave:
          this.saveMindMap();
          break;
        case menuListType.exportSaveAs:
          this.saveAsMindMap();
          break;
        case menuListType.shareLink:
          this.saveLinkType();
          break;
        case "share_PotatoChips":
          this.sharePc();
          break;
        case menuListType.sharedGroup:
          if (LoadLocalXJMind.isLoaclMind() && !LoadLocalXJMind.isCloudStorage()) {
            new UiUtil().showNeedToUpload(this, EditMindmapVM)
          } else {
            this.showMindAddGroup = true;
          }
          break;
        case "save_Sourse_Type":
          this.saveSourseType();
          break;
        case "unlock_Full_Version":
          this.saveFullType();
          break;
        case "save_Printf":
          this.savePrintf();
          break;
        case "help":
          this.helpModal(true);
          break;
        case "setting_mindpassword":
          this.setMindPassword();
          break;
          
        default:
          break;
      }
    },
    // 解锁完整版本
    saveFullType() {
      this.isShowShoppingModel(true);
    },
    setPrintPage(oldPhotoWidth, oldPhotoHeight) {
      let orientation;
      // 在分辨率72的情况下a4纸宽595高842
      let photoHeight; //新宽度
      let photoWidth; //新高度
      let a4Width = 595;
      let a4Height = 842;
      let a4Scale = a4Width / a4Height; //a4宽高比
      let photoScale = oldPhotoWidth / oldPhotoHeight;
      let photoScaleHeightWidth = oldPhotoHeight / oldPhotoWidth;
      if (oldPhotoWidth < a4Width && oldPhotoHeight < a4Height) {
        orientation = "portrait";
        photoHeight = oldPhotoWidth;
        photoWidth = oldPhotoHeight;
        //竖屏 不缩放
      } else if (Math.abs(a4Scale - photoScale) < 0.2) {
        orientation = "portrait";
        //竖屏
        //缩放
        if (a4Scale > photoScale) {
          photoWidth = a4Height;
          //宽自由缩放
        } else {
          photoHeight = a4Width;
          //高自由缩放
        }
      } else if (Math.abs(a4Scale - photoScaleHeightWidth) < 0.2) {
        orientation = "landscape";
        //横屏
        //缩放
        if (a4Scale > photoScaleHeightWidth) {
          photoHeight = a4Width;
          //高自由缩放
        } else {
          photoWidth = a4Height;
          //宽自由缩放
        }
      } else if (a4Scale - photoScale > 0.2) {
        orientation = "portrait";
        //横屏
        //缩放
        photoWidth = a4Height;
      } else if (a4Scale - photoScale < -0.2) {
        orientation = "landscape";
        //横屏
        //缩放
        photoHeight = a4Height;
      } else {
        orientation = "portrait";
        //竖屏 缩放
        if (a4Scale > photoScale) {
          photoWidth = a4Height;
          //高自由缩放
        } else {
          photoHeight = a4Width;
          //宽自由缩放
        }
      }
      return {
        orientation: orientation, // landscape是横放，portrait是竖放。
        width: photoHeight, //图片的宽度
        height: photoWidth, //图片的高度
      };
    },
    setMindPassword() {
      EditMindmapVM.setMindPassword();
    },
    //  启动打印
    startPrinting(img) {
      if (EditMindmapVM.mindDisplayType == MindDisplayType.MindMap) {
        this.printImageSrc = img;
        let image = new Image();
        image.setAttribute("crossOrigin", "Anonymouse");
        image.src = img;
        image.onload = () => {
          let obj = this.setPrintPage(image.width, image.height);
          if (obj.width == undefined) {
            this.maxWidth = "none"; //最大宽度
            this.maxHeight = "100%"; //最大高度
          } else if (obj.height == undefined) {
            this.maxWidth = "100%"; //最大宽度
            this.maxHeight = "none"; //最大高度
          } else {
            this.maxWidth = "none"; //最大宽度
            this.maxHeight = "none"; //最大高度
          }

          setTimeout(() => {
            const style =
              "@page {size:" +
              obj.orientationorientation +
              ";margin:10mm 10mm };";
            printJS({
              printable: "printimg",
              type: "html",
              scanStyles: false,
              style,
            });
          }, 500);
        };
      } else if (EditMindmapVM.mindDisplayType == MindDisplayType.Outline) {
        this.printImageSrc = img;
        let image = new Image();
        image.setAttribute("crossOrigin", "Anonymouse");
        image.src = img;
        image.onload = () => {
          let obj = this.setPrintPage(image.width, image.height);
          if (obj.width == undefined) {
            this.maxWidth = "none"; //最大宽度
            this.maxHeight = "100%"; //最大高度
          } else if (obj.height == undefined) {
            this.maxWidth = "100%"; //最大宽度
            this.maxHeight = "none"; //最大高度
          } else {
            this.maxWidth = "none"; // //最大宽度
            this.maxHeight = "none"; // //最大高度
          }

          setTimeout(() => {
            const style =
              "@page {size:" + obj.orientation + ";margin:0 10mm };";
            printJS({
              printable: "printimg",
              type: "html",
              scanStyles: false,
              style,
            });
          }, 500);
        };
      }
    },
    //  打印
    savePrintf() {
      if (!getJwt("jwt")) {
        this.showLoginModel(true);
        return;
      }
      let userData = MindMe.getMeMessage();
      if (userData == null || userData == "") {
        this.showLoginModel(true);
        return;
      }
      if (!userData.isMember) {
        this.isShowShoppingModel(true);
        return;
      }

      this.imgTypeModelAccout({ accout: true, type: "" });
      sessionStorage.setItem(
        sessionStorageKey.ImgSaveMode,
        saveImgModes.PrintPictures
      );
      return;

      document.title = EditMindmapVM.getTitle();
      let rect = this.editMindmapContent.getMindContentRect();
      let name = this.editMindmapContent.getTitle();
      let bgColor = this.$store.state.saveImageBgcolor;
      let toImage = new ToImage();
      let type = "printf";
      let outType = "png"; // png
      if (EditMindmapVM.mindDisplayType == MindDisplayType.MindMap) {
        if (this.isSafari) {
          toImage.printFun(rect);
        } else {
          let image = toImage.toImage(rect, bgColor, type, outType, name);
          image.then(() => {
            this.printImageSrc = toImage.imgUrl;

            let obj = this.setPrintPage(rect.size.width, rect.size.height);
            if (obj.width == undefined) {
              this.maxWidth = "none"; //最大宽度
              this.maxHeight = "100%"; //最大高度
            } else if (obj.height == undefined) {
              this.maxWidth = "100%"; //最大宽度
              this.maxHeight = "none"; //最大高度
            } else {
              this.maxWidth = "none"; //最大宽度
              this.maxHeight = "none"; //最大高度
            }

            setTimeout(() => {
              const style =
                "@page {size:" +
                obj.orientationorientation +
                ";margin:10mm 10mm };";
              printJS({
                printable: "printimg",
                type: "html",
                scanStyles: false,
                style,
              });
            }, 500);
          });
        }
      } else if (EditMindmapVM.mindDisplayType == MindDisplayType.Outline) {
        let image = toImage.outlineToImage(0x000000, type, outType, name);
        image.then(() => {
          this.printImageSrc = toImage.imgUrl;
          let image = new Image();
          image.setAttribute("crossOrigin", "Anonymouse");
          image.src = toImage.imgUrl;
          image.onload = () => {
            let obj = this.setPrintPage(image.width, image.height);
            if (obj.width == undefined) {
              this.maxWidth = "none"; //最大宽度
              this.maxHeight = "100%"; //最大高度
            } else if (obj.height == undefined) {
              this.maxWidth = "100%"; //最大宽度
              this.maxHeight = "none"; //最大高度
            } else {
              this.maxWidth = "none"; // //最大宽度
              this.maxHeight = "none"; // //最大高度
            }

            setTimeout(() => {
              const style =
                "@page {size:" + obj.orientation + ";margin:0 10mm };";
              printJS({
                printable: "printimg",
                type: "html",
                scanStyles: false,
                style,
              });
            }, 500);
          };
        });
        /*const app = document.getElementById("app");
        if (app) {
            app.style.display = 'none'
        }
        const outline = document.getElementById("outline-content").cloneNode(!0);
        document.body.appendChild(outline)
        window.print()
         if (app) {
            app.style.display = 'block'
        }
        document.body.removeChild(outline)*/
      }
    },
    //分享链接
    saveLinkType() {
      if (!getJwt("jwt")) {
        this.showLoginModel(true);
        return;
      }
      if (LoadLocalXJMind.isLoaclMind() && !LoadLocalXJMind.isCloudStorage()) {
        new UiUtil().showNeedToUpload(this, EditMindmapVM)
        return;
      }

      if (this.$router.history.current.name == "gmap") {
        this.hideModelLink({
          accout: true,
          mindMapId: this.initMindGroupViewData.id,
        });
      } else {
        this.hideModelLink({
          accout: true,
          mindMapId: this.$router.history.current.query.id,
        });
      }
    },

    //保存图片
    getSaveImgType(getType) {
      if (getType && getType.imgSize) {
        let imgWidth = getType.imgSize.width;
        let imgHeight = getType.imgSize.height;
        if (imgWidth && imgHeight) {
          let rect = this.editMindmapContent.getMindContentRectForSaveImage(
            getType.pictureLayout,
            imgWidth,
            imgHeight
          );
          let name = this.editMindmapContent.getTitle();
          let bgColor = this.$store.state.saveImageBgcolor;
          let toImage = new ToImage();
          let type = "download";
          let outType = getType;
          if (EditMindmapVM.mindDisplayType == MindDisplayType.MindMap) {
            toImage.toImage(rect, bgColor, type, outType, name, EditMindmapVM.isSettingFontFamily());
            EditMindmapVM.refresh(true);
          } else if (EditMindmapVM.mindDisplayType == MindDisplayType.Outline) {
            toImage.outlineToImage(16777215, type, outType, name);
          }
        }
      }
    },
    // 选择节点导出图片
    selectNodeSaveImg(getType) {
      let rect = this.editMindmapContent.getNodeRectForSaveImage(); //获取节点信息
      let isSettingFontFamilyInSelectedNode = EditMindmapVM.isSettingFontFamilyInSelectedNode()
      this.editMindmapContent.removeNodeLineWithOut(); //移除线条
      this.editMindmapContent.uncheckNode(); //取消选中节点
      let name = this.editMindmapContent.getTitle();
      let bgColor = this.$store.state.saveImageBgcolor;
      let toImage = new ToImage();
      let type = "download";
      let outType = getType; // png webp jpeg

      toImage.toImage(rect, bgColor, type, outType, name, isSettingFontFamilyInSelectedNode);
      this.editMindmapContent.refresh(true);
    },
    // 多选节点导出图片
    multiSelectNodeSaveImg(getType) {
      let rect = this.editMindmapContent.getNodeRectForSaveImage();
      let isSettingFontFamilyInSelectedNode = EditMindmapVM.isSettingFontFamilyInSelectedNode()
      this.editMindmapContent.removeNodeLineWithOut();
      this.editMindmapContent.uncheckNode();
      let name = this.editMindmapContent.getTitle();
      let bgColor = this.$store.state.saveImageBgcolor;
      let toImage = new ToImage();
      let type = "download";
      let outType = getType; // png webp jpeg

      toImage.toImage(rect, bgColor, type, outType, name, isSettingFontFamilyInSelectedNode);
      this.editMindmapContent.refresh(true);
    },

    //打开选择img type面板
    saveImgType() {
      if (!getJwt("jwt")) {
        this.showLoginModel(true);
        return;
      }
      let res = EditMindmapVM.delegate.generalConfigurationParameters;
      let userData = MindMe.getMeMessage();
      if (userData == null || !userData.isMember) {
        if (res != false && res != null && res != "" && !res.exportImageFree) {
          let that = this;
          this.$confirm({
            title: getString(strings.Mind_Tips),
            content: "此功能是会员功能,是否去充值会员？",
            centered: true,
            okText: getString(strings.Mind_Edit_Confirm),
            cancelText: getString(strings.Mind_Group_Cancel),
            onOk() {
              that.isShowShoppingModel(true);
            },
            onCancel() {},
          });
          return;
        }
      }
      // let latexList = EditMindmapVM.getAllLatexList();
      // if(latexList.length>0){
      //   this.spinning = true;
      //   this.bus.$emit("latexTurnImg",true);
      //   let tim = setInterval(()=>{
      //     if(!this.$store.state.isLatexTurnImgSuccess){
      //       return;
      //     }
      //     clearInterval(tim);
      //     this.imgTypeModelAccout({ accout: true, type: "" });
      //     this.spinning = false;
      //   },100)
      // }else{
      this.imgTypeModelAccout({ accout: true, type: "" });
      // }
      sessionStorage.setItem(
        sessionStorageKey.ImgSaveMode,
        saveImgModes.AllNodeSaveImg
      );
    },

    //保存pdf
    savePdfType(flag) {
      this.showExportPdfType(false);
      if (!getJwt("jwt")) {
        this.showLoginModel(true);
        return;
      }

      let res = EditMindmapVM.delegate.generalConfigurationParameters;
      let userData = MindMe.getMeMessage();
      if (userData == null || !userData.isMember) {
        if (res != false && res != null && res != "" && !res.exportImageFree) {
          let that = this;
          this.$confirm({
            title: getString(strings.Mind_Tips),
            content: "此功能是会员功能,是否去充值会员？",
            centered: true,
            okText: getString(strings.Mind_Edit_Confirm),
            cancelText: getString(strings.Mind_Group_Cancel),
            onOk() {
              that.isShowShoppingModel(true);
            },
            onCancel() {},
          });
          return;
        }
      }
      if (!this.isMember() && flag == 2) {
        this.isShowShoppingModel(true);
        return;
      }

      let rect = this.editMindmapContent.getMindContentRect();
      let name = this.editMindmapContent.getTitle();
      let bgColor = this.$store.state.saveImageBgcolor;
      let toImage = new ToImage();
      let type = "savePdf";
      let outType = "png"; // png
      if (EditMindmapVM.mindDisplayType == MindDisplayType.MindMap) {
        if (flag == 1) {
          toImage.toImage(rect, bgColor, type, outType, name, EditMindmapVM.isSettingFontFamily());
        } else {
          toImage.printFun(rect);
        }
      } else if (EditMindmapVM.mindDisplayType == MindDisplayType.Outline) {
        toImage.outlineToImage(16777215, type, outType, name);
      }
    },

    // 导出Word
    exportWordFun() {
      if (this.isMember()) {
        let content = EditMindmapVM.outTextContent();
        new ExportFile(this).wordExport(content);
      } else {
        this.isShowShoppingModel(true);
      }
    },

    // 导出TXT
    exportTXTFun() {
      if (this.isMember()) {
        let content = EditMindmapVM.outTextContent();
        new ExportFile(this).txtExport(content);
      } else {
        this.isShowShoppingModel(true);
      }
    },

    saveMindMap() {
      isClickSaveMapButton.value = true;
      EditMindmapVM.delegate.exitEditStatus();
      EditMindmapVM.saveDataForClosePage(); 
    },

    saveAsMindMap() {
      isClickSaveMapButton.value = true;
      EditMindmapVM.saveMindMapToLocal(true)
    },

    //分享风暴截图
    sharePc() {
      if (!getJwt("jwt")) {
        this.showLoginModel(true);
        return;
      }
      if (LoadLocalXJMind.isLoaclMind() && !LoadLocalXJMind.isCloudStorage()) {
        new UiUtil().showNeedToUpload(this, EditMindmapVM)
        return;
      }
      let rect = this.editMindmapContent.getMindContentRect();

      let wh_s = 5 / 4;
      let orgWHS = rect.width() / rect.height();
      if (wh_s > orgWHS) {
        let width = rect.height() * wh_s;
        let changeWidth = width - rect.width();
        rect.width(width);
        rect.x = rect.x - changeWidth / 2;
      } else {
        let height = rect.width() / wh_s;
        let changeHeight = height - rect.height();
        rect.height(height);
        rect.y = rect.y - changeHeight / 2;
      }
      rect.y = Math.max(rect.y, 0);
      rect.x = Math.max(rect.x, 0);

      let name = this.editMindmapContent.getTitle();
      let bgColor = this.$store.state.saveImageBgcolor;
      let toImage = new ToImage();
      let type = "storm";
      let outType = "png"; // png
      let image = toImage.toImage(rect, bgColor, type, outType, name, EditMindmapVM.isSettingFontFamily());
      image.then((image) => {
        this.showCropperImg(true);
        this.showCropperImgUrl({ img: image, type: "storm" });
      });
    },
    //截取风暴封面图片
    setSsharePc(image) {
      const cover = image;
      const name = this.editMindmapContent.getTitle().replace(/[\r\n]/g, "");
      const subtitle = this.editMindmapContent.getSubtitle();
      this.sharePotatoChipsModel({
        accout: true,
        shareData: { cover, name, subtitle },
      });
    },

    checkMenuItem(val) {
      this.outsideCloseExport();
      this.setMenuAccout(val.item.menuType);
    },

    outsideCloseExport() {
      this.showMenu = false;
    },
    outsideCloseSwitchMap() {
      this.showSwitchMapMenu = false;
    },

    outsideCloseMindStyleComponent() {
      this.mindStyleComponentAccout = false;
    },

    outsideCloseIconCompoenntAccout() {
      this.iconCompoenntAccout = false;
    },

    outsideClose() {
      this.mindComponentAccout = false;
    },

    outsideCloseIconDetialsMenu() {
      this.isShowIconDetialsMenu = false;
    },

    //语言包
    getString(i) {
      return getString(i);
    },
    isElectron() {
      if (
        typeof navigator === "object" &&
        typeof navigator.userAgent === "string" &&
        navigator.userAgent.indexOf("Electron") >= 0
      ) {
        return true;
      }
      return false;
    },
    //保存导图数据
    saveMindData() {
      isClickSaveMapButton.value = true;
      EditMindmapVM.delegate.exitEditStatus();
      EditMindmapVM.cloudStorage(); 
      setTimeout(() => {
        this.editAccout.url = "";
      }, 2000);
    },
    //保存已更改状态
    saveEditAccout(accout) {
      var time = setTimeout(() => {
        this.saveEditAccoutToHeader(null);
      }, 100);
      if (accout == "saveSuccess") {
        this.saveEditAccoutToHeader("saveSuccess");
        time;
      } else if (accout == "alreadyEdit") {
        this.saveEditAccoutToHeader("alreadyEdit");
        time;
        //this.checkAddIconColor(this.DrawingBoardBackgroundColor);
      }
    },
    //根据导图类型渲染菜单
    getHeaderMenuAccout(borderAccoutData) {
      let rightMenuIconTem = [
        {
          Icon: "&#58643;",
          text: getString(strings.Mind_Edit_Right_Style),
          type: "Style",
        },
        {
          Icon: "&#58919;",
          text: getString(strings.Mind_Edit_Right_Icon),
          type: "Icon",
        },
      ];
      if (borderAccoutData.mindType == MindType.BUBBLE_MAP) {
        this.headerMenuAccout.linelayout = false;
        this.headerMenuAccout.timelayout = false;
        this.headerMenuAccout.bubbelLayout = true;
        this.headerMenuAccout.doubelBubbleLayout = false;
        this.rightMenuIcon = rightMenuIconTem;
        this.headerMenuAccout.outlineLayout = true;
      } else if (borderAccoutData.mindType == MindType.LINE_MAP || borderAccoutData.mindType == MindType.FLOW_CHART) {
        this.headerMenuAccout.linelayout = true;
        this.headerMenuAccout.addNode = borderAccoutData.mindType == MindType.LINE_MAP;
        this.headerMenuAccout.timelayout = false;
        this.headerMenuAccout.bubbelLayout = false;
        this.headerMenuAccout.doubelBubbleLayout = false;
        this.headerMenuAccout.outlineLayout = true;
      } else if (borderAccoutData.mindType == MindType.TIME_MAP) {
        this.headerMenuAccout.linelayout = false;
        this.headerMenuAccout.timelayout = true;
        this.headerMenuAccout.bubbelLayout = true;
        this.headerMenuAccout.doubelBubbleLayout = false;
        this.headerMenuAccout.outlineLayout = false;
        this.rightMenuIcon = rightMenuIconTem;
        this.showDaGangModel(false);
      } else if (borderAccoutData.mindType == MindType.DOUBLE_BUBBLE_MAP) {
        this.headerMenuAccout.linelayout = false;
        this.headerMenuAccout.timelayout = false;
        this.headerMenuAccout.bubbelLayout = true;
        this.headerMenuAccout.doubelBubbleLayout = true;
        this.headerMenuAccout.outlineLayout = false;
        this.rightMenuIcon = rightMenuIconTem;
      }
      this.$nextTick((_) => {
        let obj = {
          exportMenu: this.$refs.eachbuttonboxchild,
          centerMenu: this.$refs.complieheadermenubox,
          rightMenu: this.$refs.complieHeaderRightMenu,
        };
        //code
        this.setHeaderMenu(obj);
      });
    },
    //导图播放
    mindmapPlay() {
      this.mindmapPlayPauseShowSetup({
        showSetup: false,
        playAccout: "SHOW_PLAY_SETUP",
      });
    },
    getHeaderMenuType(type, index) {
      if (type == "Delete_Node") {
        this.$emit("clickDeleteNode");
        return;
      }
      if (type == "Icon_Details") {
        this.isShowIconDetialsMenu = !this.isShowIconDetialsMenu;
        this.isShowFontColorModal = false;
        return;
      }
      if (type == "Font_Color") {
        this.markColorAccout(2);
        this.isShowFontColorModal = !this.isShowFontColorModal;
        this.isShowIconDetialsMenu = false;
        return;
      }
      if (type == "Font_Style") {
        this.headerOutline.fontStyleMenu[index].fontStyleAccout =
          !this.headerOutline.fontStyleMenu[index].fontStyleAccout;
        this.getTextStlye(index);
        return;
      }

      if (
        type == MindElementType.ADD ||
        type == MindElementType.ADD_BROTHER_TOP ||
        type == MindElementType.ADD_BROTHER_BOTTOM
      ) {
        let node = this.editMindmapContentVM.getSelectedNode();
        if (node.value.type == MindElementType.EXPLAIN) {
          this.$message.error(
            getString(strings.Mind_Adding_Child_Nodes_Not_Allowed)
          ); //"注释不允许添加子节点"
          return;
        }
      }

      this.MindOperateUIControllerView = new MindOperateUIControllerView(
        this.viewModel,
        this.viewModel
      );
      this.MindOperateUIControllerView.onSelected(type);
    },
    setIconComponent() {
      this.iconCompoenntAccout = !this.iconCompoenntAccout;
      this.mindComponentAccout = false;
      this.mindStyleComponentAccout = false;
      this.mindAudioComponentAccout = false;
    },

    showConnectLineMenu() {
      if (!this.mindComponentAccout && !EditMindmapVM.checkSelectedNodeAndTip()) {
        return;
      }
      this.mindComponentAccout = !this.mindComponentAccout;
      this.iconCompoenntAccout = false;
      this.mindStyleComponentAccout = false;
      this.mindAudioComponentAccout = false;
    },

    setMindComponent() {
      if (!this.mindComponentAccout && !EditMindmapVM.checkSelectedNodeAndTip()) {
        return;
      }
      this.mindComponentAccout = false//EditMindmapVM.isFlowChart() ? false : !this.mindComponentAccout;
      this.iconCompoenntAccout = false;
      this.mindStyleComponentAccout = false;
      this.mindAudioComponentAccout = false;
      let connectingLineSelectedView = document.getElementById("connecting-line");
      // if (EditMindmapVM.isFlowChart() || this.mindComponentAccout) {
        if (EditMindmapVM.isFlowChart()) {
          EditMindmapVM.selectedNodeConnectLineStatus(ConnectLineType.RIGHT_ANGLE_LINE)
        } else {
          EditMindmapVM.selectedNodeConnectLineStatus(ConnectLineType.CURVE_LINE)
        }
        
        let moveoverSelectedView = false
        setTimeout(() => {
          if (this.mindComponentAccout) {
            if (!moveoverSelectedView) {
              this.setMindComponent()
            }
          }
        }, 3000);
        if (connectingLineSelectedView != null) {
          connectingLineSelectedView.onmouseover = function (dargeEvent) {
            moveoverSelectedView = true
          }
          connectingLineSelectedView.onmouseout = function (dargeEvent) {
            moveoverSelectedView = false
          }
        }
      // } else {
      //   if (connectingLineSelectedView != null) {
      //     connectingLineSelectedView.onmouseover = null
      //     connectingLineSelectedView.onmouseout = null
      //   }
      // }
    },
    setMindStyleComponent() {
      this.mindStyleComponentAccout = !this.mindStyleComponentAccout;
      clearInterval(this.intervalStyle);
      this.iconCompoenntAccout = false;
      this.mindComponentAccout = false;
      this.mindAudioComponentAccout = false;
      this.showStyleTitle = false;
      let nowTime = Util.getCurrentTime();
      localStorage.setItem("showStyleTitleTime", nowTime);
    },
    setMindAudioComponent() {
      if (!getJwt("jwt")) {
        this.showLoginModel(true);
        return;
      }
      this.mindAudioComponentAccout = !this.mindAudioComponentAccout;
      this.iconCompoenntAccout = false;
      this.mindComponentAccout = false;
      this.mindStyleComponentAccout = false;
    },
    setAiComponent() {    
      if (!EditMindmapVM.checkIsSelectedNodeAndShowTip()) {
        return;
      }
      this.setShowAddAi(true);  
      this.mindAudioComponentAccout = false;
      this.iconCompoenntAccout = false;
      this.mindComponentAccout = false;
      this.mindStyleComponentAccout = false;
    },
    audioComponentAccout(accout) {
      this.mindAudioComponentAccout = accout;
    },
    setMenuShowDetial(index, type) {
      //调用vuex方法
      switch (type) {
        case "OutlineOrMindmap":
          this.MindOperateUIControllerView = new MindOperateUIControllerView(
            this.viewModel,
            this.viewModel
          );
          this.MindOperateUIControllerView.onSelected(MindElementType.OUTLINE);
          break;
        case "Icon":
          this.setAddIconPage("ADD_ICON");
          break;
        case "Style":
          // if (this.$store.state.setAddIconPage  == "ADD_ICONS" || this.$store.state.setAddIconPage  == "ADD_ICON") {
          //   this.setAddIconPage("ADD_STYLE");
          // }
          if (this.nodeContentMessage == null) {
            this.setAddIconPage("ADD_Canvas");
          } else {
            this.setAddIconPage("ADD_STYLE");
          }
          let isShowBranchFreeLayout =
            EditMindmapVM.isBranchNodeFreelayoutMode();
          let isFreeNodeMoveAdsorbent = EditMindmapVM.isFreeNodeMoveAdsorbent();
          let isBranchFreeLayout = EditMindmapVM.isBranchNodeFreelayout();
          this.setFreeNodeMoveAdsorbent(isFreeNodeMoveAdsorbent);
          this.setBranchFreeLayout(isBranchFreeLayout);
          this.setShowBranchFreeLayout(isShowBranchFreeLayout);
          this.setGlobalLineWidth(EditMindmapVM.getGolbalLineWidth())
          break;
        default:
          break;
      }

      //大纲模式下
      if (this.hideOutlineAccout) {
        //非大纲模式下
      } else {
        if (index == this.menuIconClickIndex) {
          //如果当前菜单是显示的。
          if (this.menuIconClickAccout == true) {
            //这个$emit是用于控制Complie或者Gmap页面的右侧菜单是否展示
            this.$emit("menuIsShow", false);
            //当前右边菜单是否展示。
            this.menuIconClickAccout = false;
            //这个mindmapRightMenu是用于更改vuex中的右部菜单是否展示，用于再leftContent页面的画布计算。
            this.mindmapRightMenu(false);
            //将选中下标设置未null
            this.menuIconClickIndex = null;
          } else {
            this.$emit("menuIsShow", true);
            this.menuIconClickAccout = true;
            this.mindmapRightMenu(true);
            this.menuIconClickIndex = index;
          }
        } else {
          //如果点击的是切换大纲或导图按钮
          if (type == "OutlineOrMindmap") {
          } else {
            this.$emit("menuIsShow", true);
            this.menuIconClickAccout = true;
            this.mindmapRightMenu(true);
            this.menuIconClickIndex = index;
          }
        }
      }

      //大纲 导图
      if (type == "OutlineOrMindmap") {
        if (this.isShowOutline) {
          this.isShowOutline = false;
        } else {
          this.isShowOutline = true;
        }
      }
    },
    setAlignIcon(type) {
      this.alignNodeStyle(type);
      setTimeout(() => {
        this.alignNodeStyle(null);
      }, 100);
    },
    // getTopBarShowColor(color) {//color mindmap 背景色
    //   var barColor = color
    //   if (new Colors().getUiColor(color) == new new Colors().getUiColor(new Colors().white)) {
    //     barColor = 0xf5f5f5
    //   } else {
    //     barColor = new Colors().getDarkColor(color, 0.2)
    //   }
    //   return barColor
    // },

    // isTopBarShowDarkMode(color) {//color mindmap getTopBarShowColor(背景色)
    //   return !new Colors().isDarkColor(color)
    // },
    setTopBarShowDarkMode() {
      //设置工具栏深色模式
      let newval =
        this.$store.state.topToolDardarkMode ||
        sessionStorage.getItem("temDardarkMode");
      if (!newval || this.hideOutlineAccout) {
        //大纲不设置深色模式
        return;
      }
      let barColor = Colors.getNumberToRgbDark(newval, 0.2); //工具栏背景颜色设置为导图背景颜色透明度20%
      // console.log(Colors.getLightColor('#'+(newval.toString(16)), 0.6));
      this.darkMode = Colors.isDarkColor(Util.parseIntForString(barColor)); //判断深色模式
      let multiSelectionMenuDom = this.$refs.multiSelectionMenuBox; //多选菜单
      if (Colors.getUiColor(newval) == Colors.getUiColor(Colors.white) || !this.darkMode) {
        //亮色模式
        barColor = "#f5f5f5";
        this.rightMenuIconBg = "#e4e4e4";
        this.$refs.complieHeader.style.color = "#212121";
        this.$refs.complieHeader.style.borderBottom =
          "1px solid rgba(208,208,208,.5)";
        if (multiSelectionMenuDom) {
          multiSelectionMenuDom.style.background = "rgba(0, 0, 0, 0.39)";
        }
      } else {
        //深色模式
        this.$refs.complieHeader.style.borderBottom =
          "1px solid rgba(255,255,255,.2)";
        if (multiSelectionMenuDom) {
          multiSelectionMenuDom.style.background = "rgba(255,255,255, 0.39)";
        }
        let blackDecimal = 131586; //黑色十进制
        if (newval == blackDecimal) {
          //判断导图背景是否是黑色
          barColor = "#000000";
          this.rightMenuIconBg = "#666666";
          this.$refs.complieHeader.style.background = barColor;
          this.$refs.complieHeader.style.color = "#FFFFFF";
          this.rightMenuBg = barColor;
          this.setMapEditIsDardarkModeFun(barColor, blackDecimal);
          return;
        } else if (Colors.isClear(newval)) {
          //判断导图背景是否是无色
          barColor = "#000000";
          this.rightMenuIconBg = "#666666";
          this.$refs.complieHeader.style.background = barColor;
          this.$refs.complieHeader.style.color = "#FFFFFF";
          this.rightMenuBg = barColor;
          this.setMapEditIsDardarkModeFun(barColor, blackDecimal);
          return;
        }
        this.$refs.complieHeader.style.color = "#FFFFFF";
        this.rightMenuIconBg = Colors.getNumberToRgbDark(newval, 0.3);
        const blackColors = ["#282828", "#1b1b1b"]; //判断是否是黑色
        if (blackColors.includes(barColor)) {
          this.rightMenuIconBg = "#666666";
        }
      }
      this.$refs.complieHeader.style.background = barColor;
      this.rightMenuBg = barColor;
      this.setMapEditIsDardarkModeFun(barColor, newval);
    },
    setMapEditIsDardarkModeFun(barColor, mindBGColor) {
      //导图编辑页面设为深色模式
      this.setMapEditIsDardarkMode({
        darkMode: this.darkMode,
        bgColor: barColor,
        fontColor: "#ffffff",
        mindBGColor: parseInt(mindBGColor),
      });
      this.topToolDardarkMode(null); //重置导图背景颜色，重新加载深色模式
      this.oneClickColorCenter();
    },
    getKeyboard(type) {
      MindSettingManager.loadData(); //加载自定义的快捷键
      const mindKeyboards = MindSettingManager.mindSettingData.mindKeyboards;
      const keyboardsItem = mindKeyboards.find(
        (item) => item.type === type
      ).shortcutKey;
      switch (type) {
        case KeyboardValueType.ADD_CHILD_NODE: //添加子节点
          this.keyboard.ADD_CHILD_NODE = keyboardsItem;
          break;
        case KeyboardValueType.ADD_TOP_CHILD_NODE: //添加上子节点
          this.keyboard.ADD_TOP_CHILD_NODE = keyboardsItem;
          break;
        case KeyboardValueType.ADD_BOTTOM_CHILD_NODE: //添加下子节点
          this.keyboard.ADD_BOTTOM_CHILD_NODE = keyboardsItem;
          break;
        case KeyboardValueType.REVOKE: //撤销
          this.keyboard.REVOKE = keyboardsItem;
          break;
        case KeyboardValueType.RECOVERY: //恢复
          this.keyboard.RECOVERY = keyboardsItem;
          break;
      }
    },

    blink() {
      // let begin = this.getRGB('#ffffff');
      // let end = this.getRGB('#FF0000');
      // let curColor = this.getRGB('#33FFAA');
      // let bo = true;
      // let rate = this.getRate(begin, end);

      let that = this;
      this.intervalStyle = setInterval(function () {
        // let color = that.randomHexColor();
        // curColor.r = that.getCur(begin.r, end.r, curColor.r, bo, rate.r);
        // curColor.g = that.getCur(begin.g, end.g, curColor.g, bo, rate.g);
        // curColor.b = that.getCur(begin.b, end.b, curColor.b, bo, rate.b);
        // document.getElementById('one_key_discoloration').style.color = that.getColor(curColor);//color
        that.showColorMatchingIcon = !that.showColorMatchingIcon;
        // document.querySelector("img-one_key_discoloration").style
        // document.getElementById('span1').innerHTML = getColor(curColor);
        // if(curColor.r == begin.r && curColor.g == begin.g && curColor.b == begin.b)
        // {
        // bo = true;
        // }
        // if(curColor.r == end.r && curColor.g == end.g && curColor.b == end.b)
        // {
        // bo = false;
        // }
      }, 400);
      setTimeout(function () {
        clearInterval(this.intervalStyle);
        document.getElementById("one_key_discoloration").style.color = "";
      }, 10000);
    },
    // randomHexColor() { //随机生成十六进制颜色
    //   var hex = Math.floor(Math.random() * 16777216).toString(16); //生成ffffff以内16进制数
    //   while (hex.length < 6) { //while循环判断hex位数，少于6位前面加0凑够6位
    //     hex = '0' + hex;
    //   }
    //   return '#' + hex; //返回‘#'开头16进制颜色
    // },
    // getCur(beginValue, endValue, curValue, bo, rateValue){
    //   if(beginValue == endValue)
    //   {
    //     return beginValue;
    //   }
    //   rateValue = beginValue < endValue ? rateValue : -rateValue;
    //   curValue += bo ? rateValue : -rateValue;
    //   if(curValue < Math.min(beginValue, endValue))
    //   {
    //     curValue = Math.min(beginValue, endValue);
    //   }
    //   if(curValue > Math.max(beginValue, endValue))
    //   {
    //     curValue = Math.max(beginValue, endValue);
    //   }
    //   return curValue;
    // },
    // getRate(b, e){
    //   var obj = new Object();
    //   obj.r = Math.abs(b.r - e.r) / 5;
    //   obj.g = Math.abs(b.g - e.g) / 5;
    //   obj.b = Math.abs(b.b - e.b) / 5;
    //   return obj;
    // },
    // getRGB(color){
    //   var obj = new Object();
    //   obj.r = parseInt(color.substr(1,2), 16);
    //   obj.g = parseInt(color.substr(3,2), 16);
    //   obj.b = parseInt(color.substr(5,2), 16);
    //   return obj;
    // },
    // getColor(obj){
    //   obj.r = Math.round(obj.r);
    //   obj.g = Math.round(obj.g);
    //   obj.b = Math.round(obj.b);
    //   var color = '#';
    //   color += (obj.r < 16 ? '0':'') + obj.r.toString(16);
    //   color += (obj.g < 16 ? '0':'') + obj.g.toString(16);
    //   color += (obj.b < 16 ? '0':'') + obj.b.toString(16);
    //   return color;
    // },
    //隐藏导入到共享组模态框
    hiddenMindAddGroup() {
      this.showMindAddGroup = false;
    },
    //新手引导子主题位置
    getSubtopicsSite(subtopics) {
      if (subtopics) {
        let site = subtopics.getBoundingClientRect();
        newGuideNodeLocation.subtopics = {
          left: site.x,
          top: site.y,
        };
      }
    },
    oneClickColorCenter() {
      //  一键配色居中
      this.$nextTick(() => {
        let mindmapPlayAudioBoxDom = this.$refs.mindmapPlayAudioBox;
        let centerMenuDom = this.$refs.complieheadermenubox;
        let rightMenuDom = this.$refs.complieHeaderRightMenu;
        let rightIconMenuDom = this.$refs.complieHeaderRightMenuBox;
        let oneClickColorDom = document.getElementById("one_key_discoloration");

        if (
          centerMenuDom &&
          rightMenuDom &&
          rightIconMenuDom &&
          oneClickColorDom &&
          mindmapPlayAudioBoxDom
        ) {
          let centerMenuDomMarginRight = parseFloat(
            window.getComputedStyle(centerMenuDom)["margin-right"]
          );
          let rightMenuDomRight = parseFloat(
            window.getComputedStyle(rightMenuDom)["right"]
          );
          let rightIconMenuDomWidth = rightIconMenuDom.offsetWidth;
          let oneClickColorDomWidth = oneClickColorDom.offsetWidth;
          let mindmapPlayAudioBoxDomWidth = mindmapPlayAudioBoxDom.offsetWidth;
          let marginRight =
            (centerMenuDomMarginRight -
              rightMenuDomRight -
              rightIconMenuDomWidth -
              oneClickColorDomWidth -
              mindmapPlayAudioBoxDomWidth) /
            2;
          oneClickColorDom.style.marginRight =
            (marginRight < 60 ? 60 : marginRight) + "px";
        }
      });
    },
    setCenterMenuWidth() {
      if (document.documentElement.clientWidth - 722 > 0) {
        this.centerMenuWidth = document.documentElement.clientWidth - 722;
      } else {
        this.centerMenuWidth = 1380;
      }
    },
  },
  watch: {
    $route: {
      handler() {},
      deep: true,
    },
    "$store.state.isShowMindmapRightMenu": {
      handler(newIsShowMindmapRightMenu) {
        this.menuIconClickAccout = newIsShowMindmapRightMenu;
      },
      immediate: true,
    },
    "$store.state.initData"(newInitData, olInitData) {
      this.getHeaderMenuAccout(newInitData);
    },
    "$store.state.editMindmap"(newval, olval) {
      this.viewModel = newval;
    },
    // "$store.state.showShopping"(newval, olval) {
    //   this.mindStyleComponentAccout = !newval;
    // },
    //撤销
    "$store.state.isRevocationMap"(newval, olval) {
      this.getHeaderMenuType(MindElementType.BACKWARD_RETREAT);
    },
    //恢复
    "$store.state.isRecoverMap"(newval, olval) {
      this.getHeaderMenuType(MindElementType.FORWARD);
    },
    //大纲
    "$store.state.showDaGangModels": {
      handler(newshowDaGangModels) {
        this.hideOutlineAccout = newshowDaGangModels;
      },
      immediate: true, //在一开是绑定的时候执行
      // deep: true, //深度监听
    },

    //对齐按钮
    "$store.state.isShowAlignButton"(isShowAlignButton) {
      this.isShowAlignButton = isShowAlignButton;
    },

    //节点等宽按钮
    "$store.state.isShowSameWidth"(isShowSameWidth) {
      this.isShowSameWidth = isShowSameWidth;
    },

    //当前节点数据
    "$store.state.nodeContentMessage"(newNodeMessage, olval) {
      this.nodeContentMessage = newNodeMessage;
      if (newNodeMessage != null) {
        if (newNodeMessage.generalizationContent != null) {
          this.nodeAccout = newNodeMessage;
        }
        //节点文本内容
        if (newNodeMessage.textContent != null) {
          this.nodeAccout = newNodeMessage;
          this.fontTextColor = colorChangeClass.SystemToColor(
            newNodeMessage.textContent.textColor
          );
          if (
            newNodeMessage.iconElementContents != null &&
            newNodeMessage.iconElementContents.length > 0
          ) {
            this.iconFontSize = newNodeMessage.iconElementContents[0].width;
            this.iconPlaceAccout =
              newNodeMessage.iconElementContents[0].layoutType - 1;
          }
          if (newNodeMessage.textContent !== null) {
            this.headerOutline.fontStyleMenu[0].fontStyleAccout =
              newNodeMessage.textContent.textBold;
            this.headerOutline.fontStyleMenu[1].fontStyleAccout =
              newNodeMessage.textContent.textItalics;
            this.headerOutline.fontStyleMenu[2].fontStyleAccout =
              newNodeMessage.textContent.textStrikethrough;
          }
        }
      }
    },

    //保存编辑
    "$store.state.saveAndEditAccout"(newSaveAndEdit, olSaveAndEdit) {
      if (newSaveAndEdit == null) {
        return;
      }
      if (newSaveAndEdit == "saveSuccess") {
        this.editAccout.url = "check-circle";
        this.editAccout.text = getString(strings.Header_Mind_Tips_Update_Saved);
        setTimeout(() => {
          this.editAccout.url = "";
        }, 2000);
      } else if (newSaveAndEdit == "alreadyEdit") {
        this.editAccout.url = "exclamation-circle";
        this.editAccout.text = getString(strings.Header_Mind_Tips_Edit);
      }
      // this.checkAddIconColor(this.DrawingBoardBackgroundColor);
    },

    "$store.state.textColor"(newval, olval) {
      if (newval == null) {
        return;
      }
      this.fontTextColor = colorChangeClass.nodeUpdataBeforeColor(newval);
    },

    "$store.state.mindmapPlayPauseShowSetup.playAccout"(newPlayAccout) {
      switch (newPlayAccout) {
        case "PAUSE":
          break;
        case "OVER":
          this.mindmapPlayButton = false;
          break;
        case "PLAY":
          this.mindmapPlayButton = true;
          break;
        default:
          break;
      }
    },
    "$store.state.imgTypeModelAccout.type"(newImgType, olImgType) {
      if (newImgType == "") {
        return;
      }
      let imgSaveMode = sessionStorage.getItem(sessionStorageKey.ImgSaveMode);
      if (imgSaveMode) {
        switch (imgSaveMode) {
          case saveImgModes.AllNodeSaveImg:
            this.getSaveImgType(newImgType);
            break;
          case saveImgModes.SelectNodeSaveImg:
            this.selectNodeSaveImg(newImgType);
            break;
          case saveImgModes.MultiSelectNodeSaveImg:
            this.multiSelectNodeSaveImg(newImgType);
            break;
        }
      }
    },
    "$store.state.urlDomImg.imageBase64"(newval, olval) {
      if (newval == null || this.$store.state.urlDomImg.type == "uploadImg") {
        return;
      }
      this.setSsharePc(newval);
    },
    hideOutlineAccout(newval) {
      if (newval) {
        let barColor = "#f5f5f5";
        this.darkMode = false;
        this.rightMenuHeight = "auto";
        this.rightMenuTop = "11px";
        let complieHeaderDom = this.$refs.complieHeader;
        if (complieHeaderDom) {
          complieHeaderDom.style.background = barColor;
          complieHeaderDom.style.color = "#000000";
        }
        return;
      } else {
        this.rightMenuHeight = "65px";
        this.rightMenuTop = "0";
        this.setTopBarShowDarkMode();
      }
    },
    "$store.state.topToolDardarkMode"(newVal) {
      if (newVal) {
        sessionStorage.setItem("temDardarkMode", newVal);
        this.setTopBarShowDarkMode();
      }
      // console.log(newVal,'topToolDardarkMode');
    },
    "$store.state.showStyleTitleModel": {
      handler(newshow) {
        this.showStyleTitle = newshow;
      },
      immediate: true, //在一开是绑定的时候执行
      deep: true, //深度监听
    },
    "$store.state.isShowMindmapExportMenu"(newVal) {
      //显示隐藏导出菜单
      if (newVal) {
        this.checkMenu();
      } else {
        this.showMenu = false;
      }
    },
    "$store.state.showSkeleton"(newVal) {
      //显示骨架
      if (newVal) {
        this.setMindStyleComponent();
      }
    },
    mindStyleComponentAccout(newVal) {
      if (!newVal) {
        this.setShowSkeleton(false);
      }
    },
    //跳转到画布、样式、图标菜单
    "$store.state.setAddIconPage.type": {
      handler(newVal) {
        if (this.$store.state.isShowMindmapRightMenu) {
          if (newVal == "ADD_STYLE") {
            this.menuIconClickIndex = 1;
          } else if (newVal == "ADD_ICON") {
            this.menuIconClickIndex = 2;
          }
        }
      },
      immediate: true, //在一开是绑定的时候执行
      deep: true, //深度监听
    },
  },
};
</script>

<style lang="less" scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}

// 导图播放Icon
@font-face {
  font-family: "iconfontMindmapPlay";
  src: url("../../assets/font-icon/mindmap_play/iconfont.eot");
  src: url("../../assets/font-icon/mindmap_play/iconfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/font-icon/mindmap_play/iconfont.woff2") format("woff2"),
    url("../../assets/font-icon/mindmap_play/iconfont.woff") format("woff"),
    url("../../assets/font-icon/mindmap_play/iconfont.ttf") format("truetype"),
    url("../../assets/font-icon/mindmap_play/iconfont.svg#iconfont")
      format("svg");
}
.iconfont-mindmap-play {
  font-family: "iconfontMindmapPlay" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Outline";
  src: url("../../assets/font-icon/font-style-detials/iconfont.eot");
  src: url("../../assets/font-icon/font-style-detials/iconfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/font-icon/font-style-detials/iconfont.woff2")
      format("woff2"),
    url("../../assets/font-icon/font-style-detials/iconfont.woff")
      format("woff"),
    url("../../assets/font-icon/font-style-detials/iconfont.ttf")
      format("truetype"),
    url("../../assets/font-icon/font-style-detials/iconfont.svg#iconfont")
      format("svg");
}
.Outline {
  font-family: "Outline" !important;
  font-size: 21px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: "mindicon";
  src: url("../../assets/font-icon/mind_icon/iconfont.eot");
  src: url("../../assets/font-icon/mind_icon/iconfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/font-icon/mind_icon/iconfont.woff2") format("woff2"),
    url("../../assets/font-icon/mind_icon/iconfont.woff") format("woff"),
    url("../../assets/font-icon/mind_icon/iconfont.ttf") format("truetype"),
    url("../../assets/font-icon/mind_icon/iconfont.svg#iconfont") format("svg");
}
.mindicon {
  font-family: "mindicon" !important;
  font-size: 21px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: "iconfont";
  src: url("../../assets/font-icon/outline_Icon/iconfont.eot");
  src: url("../../assets/font-icon/outline_Icon/iconfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/font-icon/outline_Icon/iconfont.woff2") format("woff2"),
    url("../../assets/font-icon/outline_Icon/iconfont.woff") format("woff"),
    url("../../assets/font-icon/outline_Icon/iconfont.ttf") format("truetype"),
    url("../../assets/font-icon/outline_Icon/iconfont.svg#iconfont")
      format("svg");
}

@font-face {
  font-family: "iconfont";
  src: url("../../assets/font-icon/rightStyleTitleIcon/iconfont.eot");
  src: url("../../assets/font-icon/rightStyleTitleIcon/iconfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/font-icon/rightStyleTitleIcon/iconfont.woff2")
      format("woff2"),
    url("../../assets/font-icon/rightStyleTitleIcon/iconfont.woff")
      format("woff"),
    url("../../assets/font-icon/rightStyleTitleIcon/iconfont.ttf")
      format("truetype"),
    url("../../assets/font-icon/rightStyleTitleIcon/iconfont.svg#iconfont")
      format("svg");
}
.iconfont {
  font-family: "iconfont" !important;
  font-size: 21px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: "iconfont";
  src: url("../../assets/font-icon/font_SetStyleIcon/iconfont.eot");
  src: url("../../assets/font-icon/font_SetStyleIcon/iconfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/font-icon/font_SetStyleIcon/iconfont.woff2")
      format("woff2"),
    url("../../assets/font-icon/font_SetStyleIcon/iconfont.woff") format("woff"),
    url("../../assets/font-icon/font_SetStyleIcon/iconfont.ttf")
      format("truetype"),
    url("../../assets/font-icon/font_SetStyleIcon/iconfont.svg#iconfont")
      format("svg");
}
.iconfont {
  font-family: "iconfont" !important;
  font-size: 21px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and(min-width:0px) and(max-width:1235px) {
  .box-hidden-digest {
    display: none;
  }
  // .complie-header-menu-content{
  //   left: 26% !important;
  // }
}
@media screen and(min-width:0px) and(max-width:1170px) {
  .box-hidden-explain {
    display: none;
  }
  // .complie-header-menu-content{
  //   left: 26% !important;
  // }
}
@media screen and(min-width:0px) and(max-width:1075px) {
  .box-hidden-relevance {
    display: none;
  }
  // .complie-header-menu-content{
  //   left: 26% !important;
  // }
}

@media screen and(min-width:0px) and(max-width:1000px) {
  .box-hidden-blocking {
    display: none;
  }
  // .complie-header-menu-content{
  //   left: 26% !important;
  // }
}

@media screen and(min-width:0px) and(max-width:935px) {
  .box-hidden-bottom {
    display: none;
  }
  // .complie-header-menu-content{
  //   left: 26% !important;
  // }
}

@media screen and(min-width:0px) and(max-width:870px) {
  .box-hidden-son {
    display: none;
  }
  // .complie-header-menu-content{
  //   left: 26% !important;
  // }
}

@media screen and(min-width:1380px) and(max-width:1500px) {
  // .complie-header-menu-content{
  //   left: 22% !important;
  // }
}

.complie-header-menu {
  // height: 66px;
  min-width: 1375px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background: #f5f5f5;
  //不知到代码中出了什么bug，在距离顶部67像素的位置就出现了一个白色盒子，怎么也去不掉。用的div嵌套太多，也找不出来。
  // position: fixed;
  // width: 100%;
  .complie-header-menu-style {
    min-width: 100%;
    margin: 0 auto;
    .complie-header-menu-style-left-box {
      position: absolute;
      left: 20px;
      top: 0px;
      width: 300px;
      height: 65px;
      // background-color: hotpink;
      display: flex;
      align-items: center;
      .complie-operation-left-box {
        display: flex;
        align-items: center;
      }
      .complie-operation-vertical-line {
        height: 24px;
        width: 2px;
        // background-color: #c9c9c9;
        margin-left: 15px;
        margin-right: 15px;
      }
      .complie-operation-right-box {
        display: flex;
        align-items: center;
      }
    }

    .complie-style {
      min-width: 1375px;
      height: 65px;
      display: flex;
      margin: 0 auto;

      //顶部菜单栏
      .complie-header-menu-content {
        display: flex;
        align-items: center;
        height: 65px;
        margin: 0 auto;
        // position: absolute;
        // left: 30%;
        // left: 50%;
        // transform: translateX(-50%);

        //子节写的中间菜单开始
        justify-content: center;
        .complie-style-left-box {
          display: flex;
          align-items: center;
        }
        .complie-style-vertical-line {
          height: 24px;
          width: 2px;
          // background-color: #c9c9c9;
          margin-left: 30px;
          margin-right: 30px;
        }

        .outline-style-vertical-line {
          height: 24px;
          width: 2px;
          background-color: #c9c9c9;
          margin-left: 15px;
          margin-right: 15px;
        }
      }
      //右侧菜单
      .complie-header-right-menu {
        height: 65px;
        background: #f5f5f5;
        position: absolute;
        top: 0;
        right: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        .complie-style-right-box {
          display: flex;
          align-items: center;
        }
        //保存状态
        .save-status {
          min-width: 105px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 40px;
          .save-btn {
            margin-left: 10px;
          }
          .save-hover-btn:hover {
            text-decoration: underline;
          }
        }
        .edit-accout {
          min-width: 150px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          // right: 140px;
          top: 55px;
          right: 0;
          z-index: 100;
          min-height: 36px;
          // background: rgba(0, 0, 0, 0.27);
          border-radius: 20px;
          color: #666;
          .save-button {
            padding: 4px;
            border-radius: 10px;
            cursor: pointer;
            margin-left: 10px;
            button {
              height: 28px;
              border-radius: 10px;
              padding: 2px 6px;
              background: transparent;
              color: #666;
              border: none;
            }
          }
        }
        .active-border {
          border: 1px solid rgba(255, 255, 255, 0.5);
        }
        .menu-box {
          background-color: #e4e4e4;
          cursor: pointer;
          .menu-box-content {
            width: 44px;
            height: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: 0.5s;

            .mindicon-text {
              font-size: 17px;
            }
          }

          .active {
            background-color: #ffffff;
            color: #333;
          }
          .menu-box-text {
            text-align: center;
            font-size: 12px;
            // font-weight: 600;
            // transform: scale(0.95);
          }
        }
        .menu-box-dark {
          background-color: #666666;
        }
      }
      //对齐菜单
      .complie-header-align-menu {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: rgba(0, 0, 0, 0.39);
        // min-width: 458px;
        height: 50px;
        border-radius: 0px 0px 8px 8px;
        position: absolute;
        left: 50%;
        // right: 50%;
        transform: translate(-50%, 0%);
        z-index: 1400;
        top: 66px;
        .menu-box-content {
          height: 100%;
          display: flex;
          align-items: center;
          cursor: pointer;
          .align-icon {
            border-right: 2px solid #959595;
            padding: 0 9px 0 9px;
          }
        }

        .right-button-box {
          .right-button {
            height: 100%;
            padding: 0 9px 0 9px;
            // display: inline-block;
            white-space: nowrap;
            color: #fff;
            cursor: pointer;
          }
        }
      }
    }
    .complie-style-center {
      height: 65px;
      display: flex;

      //顶部菜单栏
      .complie-header-menu-content {
        display: flex;
        align-items: center;
        height: 65px;
        margin: 0 auto;
        // position: absolute;
        // left: 30%;
        // left: 50%;
        // transform: translateX(-50%);

        //子节写的中间菜单开始
        justify-content: center;
        .complie-style-left-box {
          display: flex;
          align-items: center;
        }
        .complie-style-vertical-line {
          height: 24px;
          width: 2px;
          // background-color: #c9c9c9;
          margin-left: 30px;
          margin-right: 30px;
        }

        .outline-style-vertical-line {
          height: 24px;
          width: 2px;
          background-color: #c9c9c9;
          margin-left: 15px;
          margin-right: 15px;
        }
      }
      //右侧菜单
      .complie-header-right-menu {
        height: 65px;
        background: #f5f5f5;
        position: absolute;
        top: 0;
        right: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        .complie-style-right-box {
          display: flex;
          align-items: center;
        }
        //保存状态
        .save-status {
          min-width: 105px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 40px;
          .save-btn {
            margin-left: 10px;
          }
          .save-hover-btn:hover {
            text-decoration: underline;
          }
        }
        .edit-accout {
          min-width: 150px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          right: 140px;
          z-index: 100;
          min-height: 36px;
          // background: rgba(0, 0, 0, 0.27);
          border-radius: 20px;
          color: #666;
          .save-button {
            padding: 4px;
            border-radius: 10px;
            cursor: pointer;
            margin-left: 10px;
            button {
              height: 28px;
              border-radius: 10px;
              padding: 2px 6px;
              background: transparent;
              color: #666;
              border: none;
            }
          }
        }
        .active-border {
          border: 1px solid rgba(255, 255, 255, 0.5);
        }
        .menu-box {
          background-color: #e4e4e4;
          cursor: pointer;
          .menu-box-content {
            width: 44px;
            height: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: 0.5s;

            .mindicon-text {
              font-size: 17px;
            }
          }

          .active {
            background-color: #ffffff;
            color: #333;
          }
          .menu-box-text {
            text-align: center;
            font-size: 12px;
            // font-weight: 600;
            // transform: scale(0.95);
          }
        }
        .menu-box-dark {
          background-color: #666666;
        }
      }
      //对齐菜单
      .complie-header-align-menu {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: rgba(0, 0, 0, 0.39);
        // min-width: 458px;
        height: 50px;
        border-radius: 0px 0px 8px 8px;
        position: absolute;
        left: 50%;
        // right: 50%;
        transform: translate(-50%, 0%);
        z-index: 1400;
        top: 66px;
        .menu-box-content {
          height: 100%;
          display: flex;
          align-items: center;
          cursor: pointer;
          .align-icon {
            border-right: 2px solid #959595;
            padding: 0 9px 0 9px;
          }
        }

        .right-button-box {
          .right-button {
            height: 100%;
            padding: 0 9px 0 9px;
            // display: inline-block;
            white-space: nowrap;
            color: #fff;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.showMindStyleComponent {
  display: block;
}
.hideMindStyleComponent {
  display: none;
}

.complie-header-mind-comp {
  position: relative;
  top: 0px;
  left: 85px;
}
.showMindComponent {
  display: block;
}
.hideMindComponent {
  display: none;
}

.complie-header-img-comp {
  position: relative;
  top: 13px;
}
.showComponent {
  display: block;
}
.hideComponent {
  display: none;
}

//自己写的。
.each-button-box {
  // margin: 0 15px;
  padding: 0 10px;
  cursor: pointer;
  position: relative;
  // &:hover {
  //   background-color: var(--rightClickMenuBg);
  // }
  //用于展示提示文字开始
  .show-title {
    display: none;
  }
  &:hover .show-title {
    display: block;
    position: absolute;
    left: 50%;
    top: calc(100% + 10px);
    transform: translate(-50%, 0);
    z-index: 99999;
    white-space: nowrap;
    color: #000;
    background-color: #fff;
    border-radius: 4px;
    padding: 5px;
    border: 1px solid #e6e6e6;
    box-shadow: 0 2px 5px #eee;
    .top-triangle {
      position: absolute;
      left: 50%;
      top: -10px;
      width: 0;
      height: 0;
      border: 5px solid;
      transform: translate(-50%, 0);
      border-color: transparent transparent #fff;
    }
  }

  .complie-color {
    margin: 0 auto;
    width: 46px;
    height: 20px;
    background: #bcbcbc;
    border: 1px solid #7d7d7d;
    opacity: 1;
    border-radius: 0px;
  }

  //大纲笑脸图标样式开始
  .icon-detials-box {
    position: absolute;
    top: 56px;
    z-index: 1000;
    height: 374px;
    left: 50%;
    transform: translate(-50%, 0);
    overflow: auto;
    border-radius: 8px;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.39);
  }
  //图标滚动条美化
  .icon-detials-box::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  .icon-detials-box::-webkit-scrollbar-track {
    background: rgb(239, 239, 239);
    border-radius: 2px;
  }
  .icon-detials-box::-webkit-scrollbar-thumb {
    background: #bfbfbf;
    border-radius: 5px;
  }
  .icon-detials-box::-webkit-scrollbar-thumb:hover {
    background: #bfbfbf;
  }
  //大纲笑脸图标样式结束

  //大纲字体样式开始
  .font-detials-box {
    position: absolute;
    top: 57px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1000;
    width: 264px;
    height: 210px;
  }
  //大纲字体样式结束

  .comple-menu-icon {
    margin-bottom: 24px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    justify-content: center;
  }

  .each-button-box-icon {
    margin: 0 auto;
    width: 24px;
    height: 24px;
    text-align: center;

    .each-button-outline-text {
      font-size: 16px;
      line-height: 24px;
    }
  }

  //字体样式
  .font-stlye-before {
    border-radius: 6px;
    background: #ffffff;
    color: #fd492b;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  //图标于文字开始
  .each-button-box-img {
    display: block;
    width: 22px;
    height: 22px;
    margin: 0 auto;
    position: relative;
    .each-button-box-center-img {
      width: 100%;
      height: 100%;
      display: block;
    }
    .menu-triangle {
      position: absolute;
      right: -10px;
      top: 50%;
      width: 8px;
      height: 5px;
      background-image: url("../../assets/img/mindNewEditMenuIocn/menuTriange.svg");
    }
    .menu-triangle-dark {
      position: absolute;
      right: -10px;
      top: 50%;
      width: 8px;
      height: 5px;
      background-image: url("../../assets/img/mindNewEditMenuIocn/darkMode/menuTriange.svg");
    }
  }
  .each-button-box-text {
    font-size: 12px;
    // transform: scale(0.95);
    // font-weight: 600;
    margin-top: 2px;
    text-align: center;
    white-space: nowrap;
  }
  //图标于文字结束
}
.each-button-right-box {
  padding: 0 10px;
  cursor: pointer;
  position: relative;
}
.each-button-box-padding0 {
  padding: 0 !important;
  .eachbuttonboxchild {
    padding: 0 10px;
  }
}
.style-content-box {
  margin-right: 60px;
}

//插入按钮右边距离
.menu-button-right-margin {
  margin-right: 20px;
}

@keyframes heartAnimate {
  0%,
  100% {
    transform: scale(1) rotate(0deg);
    margin-left: -370px;
  } /*0%,100%时保持图形的原大小且不旋转*/
  10%,
  30% {
    transform: scale(1) rotate(0deg);
    margin-left: -390px;
  } /*10%,30%时图形缩小成0.9倍，并且顺时针旋转3度*/
  20%,
  40% {
    transform: scale(1) rotate(0deg);
    margin-left: -370px;
  } /*20%,40%,60%,80%时图形扩大成1.1倍，并且逆时针旋转3度*/
  50%,
  70%,
  90% {
    transform: scale(1) rotate(0deg);
    margin-left: -390px;
  }
  60%,
  80% {
    transform: scale(1) rotate(0deg);
    margin-left: -370px;
  } /*50%,70%时图形扩大成1.1倍，并且顺时针旋转3度*/
}
@-webkit-keyframes heartAnimate {
  0%,
  100% {
    transform: scale(1) rotate(0deg);
    margin-left: -370px;
  } /*0%,100%时保持图形的原大小且不旋转*/
  10%,
  30% {
    transform: scale(1) rotate(0deg);
    margin-left: -390px;
  } /*10%,30%时图形缩小成0.9倍，并且顺时针旋转3度*/
  20%,
  40% {
    transform: scale(1) rotate(0deg);
    margin-left: -370px;
  } /*20%,40%,60%,80%时图形扩大成1.1倍，并且逆时针旋转3度*/
  50%,
  70%,
  90% {
    transform: scale(1) rotate(0deg);
    margin-left: -390px;
  }
  60%,
  80% {
    transform: scale(1) rotate(0deg);
    margin-left: -370px;
  }
}
@-moz-keyframes heartAnimate {
  0%,
  100% {
    transform: scale(1) rotate(0deg);
    margin-left: -370px;
  }
  10%,
  30% {
    transform: scale(1) rotate(0deg);
    margin-left: -390px;
  }
  20%,
  40% {
    transform: scale(1) rotate(0deg);
    margin-left: -370px;
  }
  50%,
  70%,
  90% {
    transform: scale(1) rotate(0deg);
    margin-left: -390px;
  }
  60%,
  80% {
    transform: scale(1) rotate(0deg);
    margin-left: -370px;
  }
}
@-o-keyframes heartAnimate {
  0%,
  100% {
    transform: scale(1) rotate(0deg);
    margin-left: -370px;
  }
  10%,
  30% {
    transform: scale(1) rotate(0deg);
    margin-left: -390px;
  }
  20%,
  40% {
    transform: scale(1) rotate(0deg);
    margin-left: -370px;
  }
  50%,
  70%,
  90% {
    transform: scale(1) rotate(0deg);
    margin-left: -390px;
  }
  60%,
  80% {
    transform: scale(1) rotate(0deg);
    margin-left: -370px;
  }
}
@-ms-keyframes heartAnimate {
  0%,
  100% {
    transform: scale(1) rotate(0deg);
    margin-left: -370px;
  }
  10%,
  30% {
    transform: scale(1) rotate(0deg);
    margin-left: -390px;
  }
  20%,
  40% {
    transform: scale(1) rotate(0deg);
    margin-left: -370px;
  }
  50%,
  70%,
  90% {
    transform: scale(1) rotate(0deg);
    margin-left: -390px;
  }
  60%,
  80% {
    transform: scale(1) rotate(0deg);
    margin-left: -370px;
  }
}
.style_title_frame {
  position: fixed;
  right: 280px;
  width: 169px;
  height: 32px;
  -webkit-animation: heartAnimate 4s ease infinite; /*调用名称为heartAnimate的动画，全程1s，速度曲线是ease，无限循环播放*/
  -moz-animation: heartAnimate 4s ease infinite;
  -o-animation: heartAnimate 4s ease infinite;
  -ms-animation: heartAnimate 4s ease infinite;
  animation: heartAnimate 4s ease infinite;
}
@keyframes heartAnimateSafari {
  0%,
  100% {
    transform: scale(1) rotate(0deg);
    margin-left: -190px;
  }
  10%,
  30% {
    transform: scale(1) rotate(0deg);
    margin-left: -210px;
  }
  20%,
  40% {
    transform: scale(1) rotate(0deg);
    margin-left: -190px;
  }
  50%,
  70%,
  90% {
    transform: scale(1) rotate(0deg);
    margin-left: -210px;
  }
  60%,
  80% {
    transform: scale(1) rotate(0deg);
    margin-left: -190px;
  }
}
@-webkit-keyframes heartAnimateSafari {
  0%,
  100% {
    transform: scale(1) rotate(0deg);
    margin-left: -190px;
  }
  10%,
  30% {
    transform: scale(1) rotate(0deg);
    margin-left: -210px;
  }
  20%,
  40% {
    transform: scale(1) rotate(0deg);
    margin-left: -190px;
  }
  50%,
  70%,
  90% {
    transform: scale(1) rotate(0deg);
    margin-left: -210px;
  }
  60%,
  80% {
    transform: scale(1) rotate(0deg);
    margin-left: -190px;
  }
}
@-moz-keyframes heartAnimateSafari {
  0%,
  100% {
    transform: scale(1) rotate(0deg);
    margin-left: -190px;
  }
  10%,
  30% {
    transform: scale(1) rotate(0deg);
    margin-left: -210px;
  }
  20%,
  40% {
    transform: scale(1) rotate(0deg);
    margin-left: -190px;
  }
  50%,
  70%,
  90% {
    transform: scale(1) rotate(0deg);
    margin-left: -210px;
  }
  60%,
  80% {
    transform: scale(1) rotate(0deg);
    margin-left: -190px;
  }
}
@-o-keyframes heartAnimateSafari {
  0%,
  100% {
    transform: scale(1) rotate(0deg);
    margin-left: -190px;
  }
  10%,
  30% {
    transform: scale(1) rotate(0deg);
    margin-left: -210px;
  }
  20%,
  40% {
    transform: scale(1) rotate(0deg);
    margin-left: -190px;
  }
  50%,
  70%,
  90% {
    transform: scale(1) rotate(0deg);
    margin-left: -210px;
  }
  60%,
  80% {
    transform: scale(1) rotate(0deg);
    margin-left: -190px;
  }
}
@-ms-keyframes heartAnimateSafari {
  0%,
  100% {
    transform: scale(1) rotate(0deg);
    margin-left: -190px;
  }
  10%,
  30% {
    transform: scale(1) rotate(0deg);
    margin-left: -210px;
  }
  20%,
  40% {
    transform: scale(1) rotate(0deg);
    margin-left: -190px;
  }
  50%,
  70%,
  90% {
    transform: scale(1) rotate(0deg);
    margin-left: -210px;
  }
  60%,
  80% {
    transform: scale(1) rotate(0deg);
    margin-left: -190px;
  }
}
.style_title_frame_Safari {
  position: fixed;
  width: 169px;
  height: 32px;
  -webkit-animation: heartAnimateSafari 4s ease infinite; /*调用名称为heartAnimate的动画，全程1s，速度曲线是ease，无限循环播放*/
  -moz-animation: heartAnimateSafari 4s ease infinite;
  -o-animation: heartAnimateSafari 4s ease infinite;
  -ms-animation: heartAnimateSafari 4s ease infinite;
  animation: heartAnimateSafari 4s ease infinite;
}

// .style_title_frame_img{
//   position:absolute;
//   -webkit-animation: heartAnimateImg 4s ease infinite;    /*调用名称为heartAnimate的动画，全程1s，速度曲线是ease，无限循环播放*/
//     -moz-animation: heartAnimateImg 4s ease infinite;
//     -o-animation: heartAnimateImg 4s ease infinite;
//     -ms-animation: heartAnimateImg 4s ease infinite;
//     animation: heartAnimateImg 4s ease infinite;
// }

.export-pdf-bg {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 9999;
  .export-pdf-box {
    position: absolute;
    width: 230px;
    min-height: 105px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 4px;
    .export-pdf-name {
      text-align: left;
      font-size: 16px;
      margin-bottom: 20px;
      height: 36px;
      line-height: 36px;
      color: #333;
      position: relative;
      padding: 0px 16px;
      background: #efefef;
      border-radius: 4px 4px 0 0;
      .export-pdf-title {
        position: absolute;
      }
      .export-pdf-close {
        float: right;
        position: relative;
        top: -2px;
        .close-button-img {
          width: 16px;
          cursor: pointer;
          opacity: 0.5;
        }
        .close-button-img:hover {
          opacity: 0.8;
        }
      }
    }
    .export-pdf-button {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 24px 0 20px;
      button {
        margin: 0 15px;
      }
      .need-member {
        position: absolute;
        right: 8px;
      }
    }
  }
}
</style>
