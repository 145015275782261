<template>
  <div class="menu-canvas">
    <!-- 节点面板 -->
    <div class="menu-node" >
      <div class="menu-linecolor">
        <div class="menu-linecolor-box" @click="setTextColorAccout">
          <div class="menu-left-accout">{{getString(strings.Mind_Edit_Right_Menu_text_Color)}}</div>
          <div class="menu-right-accout">
            <div
              class="menu-right-linecolor"
              style="background: #ffc5c5"
              :style="{ background: textColor }"
            ></div>
          </div>
        </div>
        <div
          class="menu-right-bg"
          :class="
            textColorAccout ? 'menu-right-text-show' : 'menu-right-text-hide'
          "
        >
          <CommonBgColor />
        </div>

       
        <div class="menu-linecolor-box">
          <div class="menu-left-accout">
            <span>{{getString(strings.Mind_Edit_Right_Menu_Font_Style)}}</span>
          </div>
          <div class="menu-right-accout">
            <div
              v-for="(item, index) in fontStlyeList"
              :key="index"
              class="menu-font-stlye"
              :class="
                item.fontStyleAccout ? 'font-stlye-before' : 'menu-font-stlye'
              "
              @click="setFontStlyeAccout(index)"
            >
              <img :src="item.fontStlye" alt="" />
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import CommonBgColor from "../../common/CommonBgColor/CommonBgColor";
import colorChangeClass from "../../../utils/ColorToSystem";

import complieRightMenu from "@/assets/css/complieRightMenu/complieRightMenu.less";
import strings from '../../../common/lang/strings';
import getString from '../../../common/lang/language';
export default {
  data() {
    return {
      fontSizeSlider: 100,
      fontStyleAccout: 0,
      textColorAccout: false,
      textColor: null,
      borderMinorStyle: null,
      nodeAccout: this.$store.state.nodeContentMessage,
      fontStlyeList: [
        {
          fontStlye: require("../../../assets/img/mapIcon/res/mipmap-right-menu/font-weight.png"),
          fontStyleAccout: false,
        },
        {
          fontStlye: require("../../../assets/img/mapIcon/res/mipmap-right-menu/font-italic.png"),
          fontStyleAccout: false,
        },
        {
          fontStlye: require("../../../assets/img/mapIcon/res/mipmap-right-menu/font-underline.png"),
          fontStyleAccout: false,
        },
      ],
      strings:strings
    };
  },
  components: {
    CommonBgColor,
  },
  created() {
    if (this.nodeAccout == null) {
      this.nodeAccout = this.$store.state.nodeContentMessage;
    }
  },
  mounted() {
    document.addEventListener("click", (e) => {
      var hideTag = e.target.tagName.toUpperCase();
      if (
        (e.target.className != "menu-right-border-box" &&
          e.target.className != "menu-right-linecolor" &&
          e.target.className != "menu-right-accout" &&
          e.target.className != "menu-use-box" &&
          e.target.className != "menu-right-accout-ly" &&
          e.target.className != "menu-right-bgcolor" &&
          e.target.className != "menu-linecolor-box" &&
          e.target.className != "menu-left-accout" &&
          e.target.className != "color-content-max-titel" &&
          e.target.className != "vc-sketch-presets" &&
          e.target.className != "vc-saturation" &&
          e.target.className != "vc-hue-container" &&
          e.target.className != "vc-alpha-picker" &&
          e.target.className != "vc-input__input" &&
          e.target.className != "vc-alpha-container" &&
          e.target.className != "vc-saturation--black" &&
          e.target.className != "vc-sketch" &&
          e.target.className != "vc-sketch-sliders" &&
          e.target.className != "vc-hue-picker") ||
        (e.target.className == "menu-content" &&
          hideTag == "SVG" &&
          hideTag != "PATH" &&
          hideTag != "IMG")
      ) {
        this.textColorAccout = false;
        this.lineColorAccout = false;
        this.fromBorderAccout = false;
        this.borderAccout = false;
        this.fillColorAccout = false;
        this.baoWeiFillColorAccout = false;
        this.baoWeiLineColorAccout = false;
      }
    });
  },
  methods: {
    ...mapMutations([
      "showChunkColor",
      "markColorAccout",
      "getTextSize",
      "getTextStlye",
      "showTipsMessageAccouts",
    ]),
    getString(i){
      return getString(i)
    },
    //字体样式
    setFontStlyeAccout(index) {
      if (this.nodeAccout != null) {
        this.fontStyleAccout = index;
        this.fontStlyeList[index].fontStyleAccout = !this.fontStlyeList[index]
          .fontStyleAccout;
        this.getTextStlye(index);
      } else {
        this.showTipsMessageAccouts(true);
        setTimeout(() => {
          this.showTipsMessageAccouts(false);
        }, 1000);
      }
    },
    //字体颜色
    setTextColorAccout() {
      if (this.nodeAccout != null) {
        this.textColorAccout = !this.textColorAccout;
        this.lineColorAccout = false;
        this.fromBorderAccout = false;
        this.fillColorAccout = false;
        this.borderAccout = false;
        this.showChunkColor(true);
        this.markColorAccout(2);
      } else {
        this.showTipsMessageAccouts(true);
        setTimeout(() => {
          this.showTipsMessageAccouts(false);
        }, 1000);
      }
    },
    //字体大小
    setTextSize(size) {
      if (this.nodeAccout != null) {
        this.getTextSize(size);
      } else {
        this.showTipsMessageAccouts(true);
        setTimeout(() => {
          this.showTipsMessageAccouts(false);
        }, 1000);
      }
    },
  },
  watch: {
    fontSizeSlider: {
      handler(newval, olval) {
        this.setTextSize(newval);
      },
    },
    "$store.state.nodeContentMessage"(newNodeMessage, olval) {
      if (newNodeMessage != null) {
        if (newNodeMessage.generalizationContent != null) {
          this.nodeAccout = newNodeMessage;
        }
        //节点文本内容
        if (newNodeMessage.textContent != null) {
          this.nodeAccout = newNodeMessage;
          this.fontSizeSlider = newNodeMessage.textContent.textFontSize;
          this.textColor = colorChangeClass.SystemToColor(
            newNodeMessage.textContent.textColor
          );
          this.borderColor = colorChangeClass.SystemToColor(
            newNodeMessage.borderColor
          );
          this.fillColor = colorChangeClass.SystemToColor(
            newNodeMessage.backgroundColor
          );
          if (typeof newNodeMessage.borderWidth == "number") {
            this.borderSizeSlider = newNodeMessage.borderWidth;
          }
          if (newNodeMessage.lineContent != null) {
            this.lineSizeSlider = newNodeMessage.lineContent.lineWidth;
          }
          if (
            newNodeMessage.iconElementContents != null &&
            newNodeMessage.iconElementContents.length > 0
          ) {
            this.iconFontSize = newNodeMessage.iconElementContents[0].width;
            this.iconPlaceAccout =
              newNodeMessage.iconElementContents[0].layoutType - 1;
          }
          if (newNodeMessage.textContent !== null) {
            this.fontStlyeList[0].fontStyleAccout =
              newNodeMessage.textContent.textBold;
            this.fontStlyeList[1].fontStyleAccout =
              newNodeMessage.textContent.textItalics;
            this.fontStlyeList[2].fontStyleAccout =
              newNodeMessage.textContent.textStrikethrough;
          }
        }
      }
    },

    "$store.state.textColor"(newval, olval) {
      if (newval == null) {
        return;
      }
      this.textColor = colorChangeClass.nodeUpdataBeforeColor(newval);
    },
  },
};
</script>
<style lang="less" scoped>
</style>
<style scoped>
.menu-linecolor-box .menu-right-bg {
  position: absolute;
  top: 60px;
}
.menu-right-accout {
  cursor: pointer;
}
.menu-right-text-show {
  display: block;
}
.menu-right-text-hide {
  display: none;
}
.right-menu-content-box
  .menu-content
  .menu-canvas
  .menu-linecolor-box
  .menu-right-accout
  .font-stlye-before {
  width: 22px;
  height: 22px;
  border: 1px solid #cccccc;
  background: #cccccc;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 2px 2px;
  cursor: pointer;
}
.menu-font-stlye {
  width: 22px;
  height: 22px;
  border: 1px solid #cccccc;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 2px 2px;
  cursor: pointer;
}

.right-menu-content-box .menu-content .menu-canvas .menu-linecolor {
  width: 285px;
  height: 100%;
  margin-bottom: 20px;
  border-top: 1px solid #e4e4e4;
}
.right-menu-content-box
  .menu-content
  .menu-canvas
  .menu-linecolor-box
  .menu-right-accout {
  position: absolute;
  right: 20px;
  display: flex;
}
.right-menu-content-box
  .menu-content
  .menu-canvas
  .menu-linecolor-box
  .menu-left-accout {
  position: absolute;
  left: 20px;
}
.right-menu-content-box .menu-content .menu-canvas .menu-linecolor-box {
  width: 285px;
  height: 40px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.right-menu-content-box .menu-content .menu-canvas .menu-right-linecolor {
  width: 50px;
  height: 22px;
  background: #ffffff;
  border: 1px solid #cccccc;
  opacity: 1;
  border-radius: 2px;
}
.right-menu-content-box
  .menu-content
  .menu-canvas
  .menu-linecolor-box
  .menu-right-accout
  .menu-right-accout-slider {
  height: 6px;
}

.ant-slider {
  width: 100px;
  text-align: center;
  margin: 0;
  position: absolute;
  right: 75px;
}
.menu-bgcolor .menu-left-accout {
  position: absolute;
  left: 20px;
  cursor: pointer;
}
.menu-bgcolor .menu-right-accout {
  position: absolute;
  right: 20px;
}
.menu-bgcolor .menu-right-accout .menu-right-bgcolor {
  width: 50px;
  height: 22px;
  background: #ffffff;
  border: 1px solid #cccccc;
  opacity: 1;
  border-radius: 2px;
}
</style>