<template>
  <!-- 导出图片面板 -->
  <div id="getimg-type">
    <div class="img-type-box">
      <div class="img-title-box">
        <div class="img-title-text">
          <span v-if="imgSaveMode == saveImgModes.PrintPictures">
            {{ getString(strings.Mind_Edit_Left_Menu_Printing) }}
          </span>
          <span v-else>
            {{ getString(strings.Mind_Edit_Left_Menu_Save_Picture) }}
          </span>
        </div>
        <section class="img-close-box">
          <div class="img-close-model" @click="colseImgTypeModel">
            <img
              class="close-button-img"
              src="../../../assets/img/popupsImg/colse_new.svg"
              alt=""
            />
          </div>
        </section>
      </div>
      <section class="export-picture-box">
        <article class="setting-picture-left">
          <!-- 保存格式  -->
          <section class="setting-picture-item" v-if="showSaveFormat">
            <label class="setting-item-label">
              {{ getString(strings.Mind_Edit_Left_Menu_Save_Picture_Format) }}
            </label>
            <div class="img-type-obj">
              <div
                class="img-obj-content"
                v-for="(item, index) in radioStyle"
                :key="index"
                @click="onChange(index, item.type)"
              >
                <div
                  class="img-obj-text"
                  :class="
                    downloadImageData.imgTypeIndex == index ? 'active' : ''
                  "
                >
                  <div
                    v-if="downloadImageData.imgTypeIndex == index"
                    class="img-active"
                  >
                    <img
                      src="../../../../static/img/saveImg/selectedBg.svg"
                      alt=""
                    />
                  </div>
                  <p>{{ item.title }}</p>
                </div>
              </div>
            </div>
          </section>
          <!-- 插入背景 -->
          <section class="setting-picture-item" v-if="showBgImg">
            <label class="setting-item-label">
              {{ getString(strings.Mind_Insert_Background) }}
            </label>
            <article class="setting-item-content chop-logo">
              <img
                v-if="downloadImageData.insertBgImg == ''"
                src="../../../../static/img/saveImg/add_logo.svg"
                @click="setBgImg"
                alt=""
              />
              <template v-else>
                <img
                  :src="downloadImageData.insertBgImg"
                  @click="setBgImg"
                  alt=""
                />
                <input
                  type="file"
                  accept="image/*"
                  @change="handleFile"
                  class="hiddenInput"
                />
                <img
                  v-if="downloadImageData.insertBgImg"
                  src="../../../../static/img/saveImg/closeBtn.svg"
                  @click="deletedBgImg"
                  class="deleted-button"
                  alt=""
                />
                <!-- <span
                  v-if="downloadImageData.insertBgImg"
                  class="font-style deleted-button"
                  @click="deletedBgImg"
                >
                  {{ getString(strings.Mind_Mindmap_Deleted_Forever) }}
                </span> -->
                <div class="img-type-obj" style="margin-left: 28px">
                  <div
                    class="img-obj-content"
                    v-for="(item, index) in insertBgStyle"
                    :key="index"
                    @click="onChangeInsertBgType(index, item.type)"
                    style="min-width: 90px"
                  >
                    <div
                      class="img-obj-text"
                      :class="
                        downloadImageData.insertBgType == item.type
                          ? 'active'
                          : ''
                      "
                    >
                      <div
                        v-if="downloadImageData.insertBgType == item.type"
                        class="img-active"
                      >
                        <img
                          src="../../../../static/img/saveImg/selectedBg.svg"
                          alt=""
                        />
                      </div>
                      <p>{{ getString(item.title) }}</p>
                    </div>
                  </div>
                </div>
              </template>
            </article>
          </section>
          <!-- 尺寸适配 -->
          <section class="setting-picture-item">
            <label class="setting-item-label">
              {{ getString(strings.Mind_Size_Fit) }}
            </label>
            <article class="setting-item-content">
              <a-select
                v-model="sizeFit"
                defaultValue="sizeFit"
                style="width: 342px"
                @change="sizeFitChange"
              >
                <a-icon
                  slot="suffixIcon"
                  style="color: #333"
                  type="caret-down"
                />
                <a-select-option
                  v-for="item of sizeFitList"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </article>
          </section>
          <!-- 布局 -->
          <section class="setting-picture-item">
            <label class="setting-item-label">
              {{ getString(strings.Mind_Edit_Right_Menu_layout) }}
            </label>
            <article class="setting-item-content">
              <div
                @click="setImgLayout"
                class="layout-picture-item"
                :class="{
                  'layout-picture-active': !downloadImageData.pictureLayout,
                }"
              >
                <img
                  v-if="!downloadImageData.pictureLayout"
                  src="../../../../static/img/saveImg/imgLayout/transverse_active.svg"
                  alt=""
                />
                <img
                  v-else
                  src="../../../../static/img/saveImg/imgLayout/transverse.svg"
                  alt=""
                />
              </div>
              <div
                @click="setImgLayout"
                style="margin-left: 10px"
                class="layout-picture-item"
                :class="{
                  'layout-picture-active': downloadImageData.pictureLayout,
                }"
              >
                <img
                  v-if="downloadImageData.pictureLayout"
                  src="../../../../static/img/saveImg/imgLayout/vertical_active.svg"
                  alt=""
                  height="24"
                />
                <img
                  v-else
                  src="../../../../static/img/saveImg/imgLayout/vertical.svg"
                  alt=""
                  height="24"
                />
              </div>
            </article>
          </section>
          <!-- 水印设置  -->
          <section class="setting-picture-item">
            <label class="setting-item-label">
              {{ getString(strings.Mind_Watermark_Settings) }}
            </label>
            <article class="setting-item-content">
              <div class="watermark-settings-box">
                <div class="watermark-settings-item">
                  <a-checkbox
                    :checked="downloadImageData.chopSwitch"
                    @change="onChangeChopSwitch"
                  >
                    <span
                      class="font-style text-overflow"
                      :title="getString(strings.Mind_Simplification_Watermark)"
                    >
                      {{ getString(strings.Mind_Simplification_Watermark) }}
                      <!-- 寻简水印 -->
                    </span>
                  </a-checkbox>
                </div>
                <div class="watermark-settings-item">
                  <a-checkbox
                    :checked="customWatermarkShow"
                    @change="onChangeWatermarkSwitch"
                  >
                    <span
                      class="font-style text-overflow"
                      :title="getString(strings.Mind_Custom_Watermark)"
                    >
                      {{ getString(strings.Mind_Custom_Watermark) }}
                      <!-- 自定义水印 -->
                    </span>
                  </a-checkbox>
                </div>
              </div>
            </article>
          </section>
          <template v-if="customWatermarkShow">
            <!-- 水印文字 -->
            <section class="setting-picture-item">
              <label class="setting-item-label">
                {{ getString(strings.Save_Img_Watermark_Text) }}
              </label>
              <article class="setting-item-content">
                <a-input
                  class="setting-width"
                  :placeholder="getString(strings.Save_Img_Please_Input)"
                  :value="downloadImageData.chopText"
                  @blur="onChnageChopText"
                />
              </article>
            </section>
            <!-- 水印Logo -->
            <section class="setting-picture-item">
              <label class="setting-item-label">
                <!-- {{getString(strings.Save_Img_Watermark_Logo)}} -->
                Logo
              </label>
              <article class="setting-item-content chop-logo">
                <img
                  v-if="downloadImageData.chopLogo == ''"
                  src="../../../../static/img/saveImg/add_logo.svg"
                  @click="setLogo"
                  alt=""
                />
                <img
                  v-else
                  :src="downloadImageData.chopLogo"
                  @click="setLogo"
                  alt=""
                />
                <input
                  type="file"
                  accept="image/*"
                  @change="handleFile"
                  class="hiddenInput"
                />
                <img
                  v-if="downloadImageData.chopLogo"
                  src="../../../../static/img/saveImg/closeBtn.svg"
                  @click="deletedChopLogo"
                  class="deleted-button"
                  alt=""
                />
                <!-- <span
                  v-if="downloadImageData.chopLogo"
                  class="font-style deleted-button"
                  @click="deletedChopLogo"
                >
                  {{ getString(strings.Mind_Mindmap_Deleted_Forever) }}
                </span> -->
              </article>
            </section>
            <!-- 水印样式 -->
            <section
              class="setting-picture-item"
              style="align-items: flex-start"
            >
              <label class="setting-item-label">
                {{ getString(strings.Save_Img_Watermark_Style) }}
              </label>
              <article class="setting-item-content">
                <div
                  class="chop-type-content"
                  v-for="(item, index) in chopTypes"
                  :key="index"
                  @click="getChopType(index, item.type)"
                >
                  <img
                    class="clickType"
                    v-if="index == clickChopTypeIndex"
                    src="../../../../static/img/saveImg/selectedBg.svg"
                    alt=""
                  />
                  <img
                    class="clickImg"
                    :class="
                      clickChopTypeIndex == index ? 'chop-type-active' : ''
                    "
                    :src="item.img"
                    alt=""
                  />
                  <span class="font-style">{{ getString(item.text) }}</span>
                </div>
              </article>
            </section>
          </template>
        </article>
        <aside class="preview-right">
          <section
            class="preview-content"
            :style="{ height: `${customWatermarkShow ? 450 : 282}px` }"
          >
            <img
              v-if="previewPictures"
              :src="previewPictures"
              class="preview-img"
              alt="加载失败"
            />
            <span v-else class="font-style">{{
              getString(strings.Loading)
            }}</span>
          </section>
        </aside>
      </section>
      <div class="img-to-dowlod">
        <a-button @click="colseImgTypeModel">{{
          getString(strings.Global_Cancel)
        }}</a-button>
        <a-button
          v-if="imgSaveMode == saveImgModes.PrintPictures"
          type="primary"
          html-type="submit"
          @click="printImgFun"
        >
          {{ getString(strings.Mind_Edit_Left_Menu_Printing) }}
        </a-button>
        <a-button
          v-else
          type="primary"
          html-type="submit"
          @click="imgTypeToDowload"
        >
          {{ getString(strings.Global_Ok) }}
        </a-button>
      </div>
    </div>

    <!-- 会员权益modal -->
    <a-modal
      class="report-member-model"
      :centered="true"
      v-model="chopIsMeberModelAccout"
      :title="getString(strings.Mind_Edit_Join_Membership)"
      :cancelText="getString(strings.Global_Cancel)"
      :okText="getString(strings.Mind_Edit_Upgrade)"
      @cancel="chopIsMeberCancelHandleOk"
      @ok="chopIsMeberDeleteHandleOk"
    >
      <div class="report-crisps-style-box">
        <div
          class="report-crisps-style"
          style="
            font-size: 18px;
            font-family: Noto Sans SC;
            text-align: center;
            display: block;
          "
        >
          {{ getString(strings.Save_Img_Chop_Tips) }}
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import getString from "../../../common/lang/language";
import strings from "../../../common/lang/strings";
import publicModalStyle from "../../../assets/css/publicModalStyle/publicModalStyle.less";
import ToImage from "../../../utils/ToImage";
import EditMindmapVM from "../../../viewmodel/mindelementdata/vm/EditMindmapVM";
import Config from "../../../core/core/calcule/Config";
import MindDisplayType from "../../../viewmodel/datatype/MindDisplayType";
import {
  materialLibraryType,
  saveImgInsertBgType,
  eventBusMethod,
  sessionStorageKey,
  saveImgModes,
} from "../../../assets/js/GlobalVariable.js";
import Template from "../../../views/template/Template.vue";
import MindMe from '../../../viewmodel/facade/MindMe';

export default {
  components: { Template },
  data() {
    return {
      radioStyle: [
        {
          url: require("../../../../static/img/saveImg/PNG.png"),
          name: strings.Mind_Edit_Left_Menu_Save_Picture_Png,
          type: "png",
          title: "png",
        },
        {
          url: require("../../../../static/img/saveImg/JPG.png"),
          name: strings.Mind_Edit_Left_Menu_Save_Picture_Jpg,
          type: "jpg",
          title: "jpg",
        },
        {
          url: require("../../../../static/img/saveImg/WEBP.png"),
          name: strings.Mind_Edit_Left_Menu_Save_Picture_Webp,
          type: "webp",
          title: "webp",
        },
        //  {
        //   type: "svg",
        //   title: "svg",
        // },
      ], //保存格式
      insertBgStyle: [
        {
          type: saveImgInsertBgType.Stretching,
          title: strings.Mind_Stretching, //"拉伸"
        },
        {
          type: saveImgInsertBgType.EqualRatio,
          title: strings.Mind_Equal_Ratio, //等比
        },
      ], //插入背景类型
      chopTypes: [
        {
          img: require("../../../../static/img/saveImg/add_logo_full.png"),
          type: "File_Full",
          text: strings.Save_Img_Fill_Full,
        },
        {
          img: require("../../../../static/img/saveImg/add_logo_center.png"),
          type: "Center_Tilt",
          text: strings.Save_Img_Center_Tilt,
        },
        {
          img: require("../../../../static/img/saveImg/add_logo_drack_full.png"),
          type: "File_Full_Dark_Color",
          text: strings.Save_Img_File_Full_Dark_Color,
        },
      ],
      strings, //语言包
      clickChopTypeIndex: 0, // 当前选中的水印类型
      chopIsMeberModelAccout: false, //会员权益模态框
      disabled: true, //会员switch
      customWatermarkShow: false, //自定义水印
      downloadImageData: {
        imgTypeIndex: 0, //导出格式索引
        imgType: "png", //导出格式
        chopSwitch: true, //寻简水印是否添加
        chopText: "", //水印文字
        chopLogo: "", // 水印图片
        chopType: "File_Full", //水印类型
        pictureLayout: false, //布局
        imgSize: null, //图片尺寸
        insertBgImg: "", //插入背景图片
        insertBgType: saveImgInsertBgType.Stretching, //插入背景类型
      },
      showBgImg: true, //是否显示插入背景
      showSaveFormat: true, //是否显示保存格式
      previewPictures: "", // 预览图片
      previewPicturesTimer: null,
      sizeFit: 0, //尺寸适配
      sizeFitList: [
        {
          label: "auto",
          value: 0,
        },
      ], //尺寸适配下拉列表
      imgSaveMode:
        sessionStorage.getItem(sessionStorageKey.ImgSaveMode) ||
        saveImgModes.AllNodeSaveImg,
      saveImgModes,
    };
  },
  created() {
    this.downloadImageData.chopSwitch = !MindMe.isUpgrade();
    this.sizeFitList = [];
    if (this.imgSaveMode) {
      switch (this.imgSaveMode) {
        case saveImgModes.AllNodeSaveImg:
          Config.PrintSizes.forEach((item, index) => {
            this.sizeFitList.push({
              label: item.name,
              value: index,
            });
          });
          if (EditMindmapVM.delegate.mindMapingType && EditMindmapVM.delegate.mindMapingType.value) {
            this.showBgImg = true;
          }else{
            this.showBgImg = false;
          }
          break;
        case saveImgModes.SelectNodeSaveImg:
        case saveImgModes.MultiSelectNodeSaveImg:
          this.sizeFitList = [
            {
              label: "auto",
              value: 0,
            },
          ];
          break;
        case saveImgModes.PrintPictures:
          Config.PrintSizes.forEach((item, index) => {
            this.sizeFitList.push({
              label: item.name,
              value: index,
            });
          });
          this.showSaveFormat = false;
          this.downloadImageData.pictureLayout = true;
          this.sizeFit = 1;
          break;
      }
    }
    this.downloadImageData.imgSize = {
      width: Config.PrintSizes[this.sizeFit].width,
      height: Config.PrintSizes[this.sizeFit].height,
    };
    let daGangModels = this.$store.state.showDaGangModels; //大纲
    if (daGangModels) {
      this.showBgImg = !daGangModels;
      this.sizeFitList = [
        {
          label: "auto",
          value: 0,
        },
      ];
      this.sizeFit = 0;
    }
  },
  mounted() {
    if (!MindMe.isUpgrade()) {
      this.disabled = true;
    } else {
      this.disabled = false;
    }
    this.bus.$on(eventBusMethod.InsertBgImgEvent, (val) => {
      //保存图片插入背景图
      if (val) {
        this.downloadImageData.insertBgImg = val.imageBase64;
      }
    });
  },
  methods: {
    ...mapMutations([
      "imgTypeModelAccout",
      "isShowShoppingModel",
      "setmaterialLibraryModulesModel",
    ]),
    getString(i) {
      return getString(i);
    },
    //付费模态框
    chopIsMeberCancelHandleOk() {
      this.chopIsMeberModelAccout = false;
    },
    chopIsMeberDeleteHandleOk() {
      this.chopIsMeberModelAccout = false;
      this.isShowShoppingModel(true);
    },
    //关闭保存图片面板
    colseImgTypeModel() {
      this.imgTypeModelAccout({ accout: false, type: "" });
    },
    // 打印
    printImgFun() {
      if (this.imgSaveMode == saveImgModes.PrintPictures) {
        this.bus.$emit(eventBusMethod.PrintImg, this.previewPictures);
        this.colseImgTypeModel();
      } else {
        this.imgTypeModelAccout({
          accout: false,
          type: this.downloadImageData,
        });
      }
    },
    //确定保存
    imgTypeToDowload() {
      var meMessage = JSON.parse(localStorage.getItem("meMessage"));
      if (meMessage != null && meMessage != undefined && meMessage != "") {
        if (!meMessage.isMember) {
          //是否为会员
          if (
            !this.downloadImageData.chopSwitch ||
            this.downloadImageData.chopText != "" ||
            this.downloadImageData.chopLogo != ""
          ) {
            this.chopIsMeberModelAccout = true;
          } else {
            this.imgTypeModelAccout({
              accout: false,
              type: this.downloadImageData,
            });
          }
        } else {
          this.imgTypeModelAccout({
            accout: false,
            type: this.downloadImageData,
          });
        }
      } else {
        //未登录
        if (
          !this.downloadImageData.chopSwitch ||
          this.downloadImageData.chopText != "" ||
          this.downloadImageData.chopLogo != ""
        ) {
          this.chopIsMeberModelAccout = true;
        } else {
          this.imgTypeModelAccout({
            accout: false,
            type: this.downloadImageData,
          });
        }
      }
    },
    //获取水印类型
    getChopType(index, type) {
      var meMessage = localStorage.getItem("meMessage");
      if (meMessage != null && meMessage != undefined && meMessage != "") {
        meMessage = JSON.parse(meMessage);
        if (!meMessage.isMember) {
          this.chopIsMeberModelAccout = true;
          return;
        }
      } else {
        this.chopIsMeberModelAccout = true;
        return;
      }
      this.clickChopTypeIndex = index;
      this.downloadImageData.chopType = type;
    },
    //获取保存图片的type
    onChange(index, type) {
      this.downloadImageData.imgTypeIndex = index;
      this.downloadImageData.imgType = type;
    },
    //获取水印文字
    onChnageChopText(e) {
      var meMessage = localStorage.getItem("meMessage");
      if (meMessage != null && meMessage != undefined && meMessage != "") {
        meMessage = JSON.parse(meMessage);
        if (!meMessage.isMember) {
          this.chopIsMeberModelAccout = true;
          this.downloadImageData.chopText = "";
        } else {
          this.downloadImageData.chopText = e.target.value;
        }
      } else {
        this.chopIsMeberModelAccout = true;
        this.downloadImageData.chopText = "";
      }
    },
    //获取switch状态
    onChangeChopSwitch() {
      this.downloadImageData.chopSwitch = !this.downloadImageData.chopSwitch;
      // this.customWatermarkShow = false;
      if (!this.downloadImageData.chopSwitch) {
        let meMessage = JSON.parse(localStorage.getItem("meMessage"));
        if (meMessage != null && meMessage != undefined && meMessage != "") {
          if (!meMessage.isMember) {
            this.chopIsMeberModelAccout = true;
            this.downloadImageData.chopSwitch = true;
          }
        }
      }
    },
    //自定义水印状态
    onChangeWatermarkSwitch() {
      this.customWatermarkShow = !this.customWatermarkShow;
      // this.downloadImageData.chopSwitch = false;
      if (this.customWatermarkShow) {
        let meMessage = JSON.parse(localStorage.getItem("meMessage"));
        if (meMessage != null && meMessage != undefined && meMessage != "") {
          if (!meMessage.isMember) {
            this.chopIsMeberModelAccout = true;
            this.customWatermarkShow = false;
            this.downloadImageData.chopSwitch = true;
          }
        }
      }
    },
    //获取本地图片
    handleFile(e) {
      let $target = e.$target || e.srcElement;
      let file = $target.files[0];
      var reader = new FileReader();
      reader.onload = (data) => {
        let res = data.target || data.srcElement;
        let logoImage = res.result;
        this.downloadImageData.chopLogo = logoImage;
        // this.showCropperImgUrl({ img: userImage, type: "uploadImg" });
      };
      reader.readAsDataURL(file);
      if (file < 1024 * 1024 * 3) {
        this.$message.error(
          getString(strings.Message_Tips_Share_Link_Does_Not_Exist)
        );
        return false;
      } else {
        // this.showCropperImg(true);
        this.$el.querySelector(".hiddenInput").value = null;
      }
    },
    //本地添加Logo
    setLogo() {
      var meMessage = localStorage.getItem("meMessage");
      if (meMessage != null && meMessage != undefined && meMessage != "") {
        meMessage = JSON.parse(meMessage);
        if (!meMessage.isMember) {
          this.chopIsMeberModelAccout = true;
          return;
        }
      } else {
        this.chopIsMeberModelAccout = true;
        return;
      }
      this.$el.querySelector(".hiddenInput").click();
    },
    // 删除自定义水印Logo
    deletedChopLogo() {
      this.downloadImageData.chopLogo = "";
    },
    // 尺寸适配
    sizeFitChange(value) {
      this.sizeFit = value;
      this.downloadImageData.imgSize = {
        width: Config.PrintSizes[value].width,
        height: Config.PrintSizes[value].height,
      };
    },
    //设置布局
    setImgLayout() {
      this.downloadImageData.pictureLayout =
        !this.downloadImageData.pictureLayout;
    },
    //插入背景图弹窗
    setBgImg() {
      /*if (this.isMember()) {
        this.setmaterialLibraryModulesModel({
          accout: true,
          type: materialLibraryType.InsertBgImage,
        });
      } else {
        this.chopIsMeberModelAccout = true;
      }*/
      this.setmaterialLibraryModulesModel({
        accout: true,
        type: materialLibraryType.InsertBgImage,
      });
    },
    // 删除插入背景图
    deletedBgImg() {
      this.downloadImageData.insertBgImg = "";
    },
    //设置插入背景图的type
    onChangeInsertBgType(index, type) {
      // this.downloadImageData.imgTypeIndex = index;
      this.downloadImageData.insertBgType = type;
    },
  },
  watch: {
    downloadImageData: {
      //预览
      handler(newVal) {
        if (this.previewPicturesTimer) {
          clearTimeout(this.previewPicturesTimer);
        }
        this.previewPicturesTimer = setTimeout(() => {
          if (newVal && newVal.imgSize) {
            let imgWidth = newVal.imgSize.width;
            let imgHeight = newVal.imgSize.height;
            if (imgWidth && imgHeight) {
              let rect = null;
              if (this.imgSaveMode) {
                switch (this.imgSaveMode) {
                  case saveImgModes.AllNodeSaveImg:
                  case saveImgModes.PrintPictures:
                    rect = EditMindmapVM.getMindContentRectForSaveImage(
                      newVal.pictureLayout,
                      imgWidth,
                      imgHeight
                    );
                    break;
                  case saveImgModes.SelectNodeSaveImg:
                  case saveImgModes.MultiSelectNodeSaveImg:
                    rect = EditMindmapVM.getNodeRectForSaveImage(); //获取节点信息
                    EditMindmapVM.removeNodeLineWithOut(); //移除线条
                    // EditMindmapVM.uncheckNode(); //取消选中节点
                    break;
                }
              }
              let name = EditMindmapVM.getTitle();
              let bgColor = this.$store.state.saveImageBgcolor;
              let toImage = new ToImage();
              let type = "previewPicture";
              let outType = newVal;
              this.previewPictures = "";
              let image = null;
              if (EditMindmapVM.mindDisplayType == MindDisplayType.MindMap) {
                image = toImage.toImage(rect, bgColor, type, outType, name, false);
                EditMindmapVM.refresh(true);
              } else {
                image = toImage.outlineToImage(16777215, type, outType, name);
              }
              image.then((res) => {
                this.previewPictures = res;
                // console.log(newVal, res);
              });
            }
          }
        }, 1000);
      },
      deep: true,
    },
    "$store.state.showDaGangModels"(newVal) {
      //大纲
      this.showBgImg = !newVal;
    },
  },
};
</script>

<style lang="less" scoped>
#getimg-type {
  max-width: 820px;
  min-height: 380px;
  // max-height: 100vh;
  // overflow-y: auto;
  width: 100%;
  border-radius: 9px;
  background: #ffffff;
  position: absolute;
  z-index: 1000;
  margin: 0 auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .img-type-box {
    // margin: 20px 30px 20px 30px;
    position: relative;
    .img-title-box {
      height: 40px;
      padding: 0 22px;
      background: #efefef;
      border-radius: 9px 9px 0 0;
      .img-title-text {
        // margin: 29px 0 40px 0;
        span {
          display: flex;
          // justify-content: center;
          align-items: center;
          font-size: 18px;
          font-family: Noto Sans SC;
          /*font-weight: bold;*/
          line-height: 40px;
          color: #333333;
          opacity: 1;
        }
      }
      .img-close-box {
        width: 40px;
        height: 40px;
        position: absolute;
        right: 0px;
        top: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        .img-close-model {
          position: relative;
          top: -2px;
          .close-button-img {
            width: 16px;
            cursor: pointer;
            opacity: 0.8;
          }
          .close-button-img:hover {
            opacity: 1;
          }
        }
      }
    }
    .export-picture-box {
      width: 100%;
      padding: 30px 0;
      display: flex;
      .setting-picture-left {
        padding: 0 30px 0 22px;
        width: 58%;
        .setting-picture-item {
          width: 100%;
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          .font-style {
            color: #333333;
            font-size: 14px;
          }
          .text-overflow {
            max-width: 118px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            position: absolute;
          }
          .setting-width {
            width: 342px;
          }
          .hiddenInput {
            display: none;
          }
          .setting-item-label {
            width: 50px;
            font-size: 12px;
            color: #999999;
            margin-right: 20px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            text-align: right;
          }
          .setting-item-content {
            display: flex;
            position: relative;
            .watermark-settings-box {
              display: flex;
              .watermark-settings-item {
                width: 150px;
              }
            }
            .chop-type-content {
              text-align: center;
              cursor: pointer;
              position: relative;
              padding-right: 10px;
              width: 118px;
              span {
                margin-top: 8px;
              }
              .clickImg {
                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                width: 108px;
                height: 74px;
              }
              .clickType {
                position: absolute;
                bottom: 28px;
                right: 10px;
              }
            }
            .chop-type-active {
              border: 1px solid #fd492b;
            }
            .layout-picture-item {
              width: 65px;
              height: 30px;
              background: #ffffff;
              box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .layout-picture-active {
              background: #fd492b;
              border: 1px solid #eeeeee;
              box-shadow: none;
            }
          }
          .chop-logo {
            img {
              width: 44px;
              height: 44px;
              cursor: pointer;
            }
            .deleted-button {
              width: 14px;
              height: 14px;
              margin-left: 6px;
              align-self: center;
              cursor: pointer;
              position: absolute;
              top: -10px;
              left: 30px;
            }
          }
        }
      }
      .preview-right {
        width: 42%;
        .preview-content {
          width: 312px;
          padding: 30px;
          background: #ffffff;
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
          display: flex;
          justify-content: center;
          align-items: center;
          overflow-y: auto;
          &::-webkit-scrollbar {
            width: 0;
          }
          .preview-img {
            width: auto;
            max-width: 100%;
          }
        }
      }
    }
    .img-type-obj {
      max-width: 378px;
      display: flex;
      justify-content: space-between;
      // header
      .img-obj-content {
        min-width: 88px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .img-obj-text {
          background: #ffffff;
          // box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
          border-radius: 2px;
          cursor: pointer;
          position: relative;
          border: 1px solid #cccccc;
          img {
            width: 110px;
            height: 110px;
          }
          .img-active {
            position: absolute;
            right: -1px;
            bottom: -1px;
            img {
              width: auto;
              height: auto;
            }
          }
          p {
            width: 74px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            font-size: 14px;
            color: #333333;
            margin: 0;
          }
        }
        .active {
          border: 1px solid #fd492b;
          position: absolute;
        }
      }
    }
    .img-to-dowlod {
      display: flex;
      justify-content: center;
      height: 34px;
      flex-wrap: nowrap;
      margin: 0 0 20px 0;
      button {
        width: 55px;
        opacity: 1;
        margin: 0 10px;
        padding: 0 12px;
        width: 68px;
        height: 32px;
        border-radius: 2px;
        span {
          text-align: center;
        }
      }
    }
  }
}
</style>