<template>
  <div id="complie-audio-box">
    <div class="complie-content" v-if="httpAudio == ''">
      <div class="audio-start-and-end">
        <!-- 开始录音暂停录音 -->
        <div
          class="iconfont icon-audio"
          v-if="audioStartAndEndIcon == 'START_AUDIO'"
        >
          <input
            type="file"
            accept=".mp3,.aac"
            @change="handleFile"
            class="hiddenInput"
          />
          <div
            style="font-size: 14px; width: 40px; margin-right: 18px"
            @click="audioUploadLocal"
          >
            {{ getString(strings.Mind_Edit_Upload) }}
          </div>
          <!-- &#58908; -->
          <img
            @click="audioStratRecording"
            src="../../../../static/img/audio/start_audio.png"
            style=""
            alt=""
          />
        </div>
        <div
          class="iconfont icon-audio"
          @click="audioStopRecording"
          v-else-if="audioStartAndEndIcon == 'STOP_AUDIO'"
        >
          &#58912;
        </div>
        <div
          class="iconfont icon-audio"
          @click="audioPlayBackRecording"
          v-else-if="audioStartAndEndIcon == 'PLAY_BACK_AUDIO'"
        >
          <img
            src="../../../../static/img/audio/play_back.png"
            style=""
            alt=""
          />
        </div>
        <!-- 解码中 -->
        <div
          style="width: 100%"
          v-else-if="audioStartAndEndIcon == 'DE_CODE_AUDIO'"
        >
          {{ getString(strings.Mind_Edit_Audio_Decoding) }}
        </div>
        <!-- 绘制波浪 -->
        <div
          class="audio-dynamic"
          v-if="audioStartAndEndIcon != 'DE_CODE_AUDIO'"
        >
          <div style="width: 100%; height: 20px">
            <canvas id="canvas" v-show="audioPlay"></canvas>
            <canvas id="playChart" v-show="!audioPlay"></canvas>
          </div>
        </div>
        <div class="audio-time" v-if="audioStartAndEndIcon != 'DE_CODE_AUDIO'">
          {{ audioTimeAccout }}
        </div>
        <!-- 音频删除和保存 -->
        <div class="audio-delete-and-save" v-if="audioSaveAndDeleteRecording">
          <div class="iconfont icon-delete" @click="audioDeleteRecordingShow">
            &#58911;
          </div>
          <div class="iconfont-division"></div>
          <div class="iconfont icon-audio" @click="audioUploadRecording">
            &#58901;
          </div>
        </div>
        <!-- 保存后的状态 -->
        <div class="audio-delete-and-save" v-if="audioDeleteRecordingIcon">
          <div class="audio-total-time">
            {{ getString(strings.Mind_Edit_Audio_Duration) }}{{ audioTime }}
          </div>
          <div class="iconfont icon-delete" @click="audioDeleteRecordingShow">
            &#58911;
          </div>
        </div>
      </div>
    </div>
    <!-- 播放 -->
    <div class="audio-http-data" v-else>
      <!-- <audio :src="httpAudio" controls="controls"></audio> -->
      <div class="play-auido-box">
        <div class="play-content" v-if="playAccout == 'START_PLAY_AUDIO'">
          <div class="play-button">
            <img
              src="../../../../static/img/audio/start_online_audio.png"
              alt=""
              @click="playAudio(1)"
            />
          </div>
          <a-slider
            :default-value="0"
            :value="suspedPlayTime"
            :tooltipVisible="false"
            :max="playTimeWidth"
            @click="audioPlayBuffered"
            @change="audioPlayBuffered"
            style="width: 86px; margin-left: 12px"
          />
          <div class="play-time">
            {{ audioPlayTime }}
          </div>
        </div>

        <div class="play-content" v-if="playAccout == 'SUSPEND_AUDIO'">
          <div class="play-button">
            <img
              src="../../../../static/img/audio/stop_audio.png"
              alt=""
              @click="playAudio(2)"
            />
          </div>
          <div class="play-time">
            {{ audioPlayTime }}
          </div>
          <div class="play-time-width">
            <a-slider
              :default-value="0"
              :value="suspedPlayTime"
              :tooltipVisible="false"
              :max="playTimeWidth"
              @click="audioPlayBuffered"
              @change="audioPlayBuffered"
            />
          </div>
        </div>

        <div class="play-content" v-if="playAccout == 'STOP_AUDIO'">
          <div class="play-button">
            <img
              src="../../../../static/img/audio/start_online_audio.png"
              alt=""
              @click="playAudio(3)"
            />
          </div>
          <div class="play-time">
            {{ audioPlayTime }}
          </div>
          <div class="play-time-width">
            <a-slider
              :default-value="0"
              :value="suspedPlayTime"
              :tooltipVisible="false"
              :max="playTimeWidth"
              @click="audioPlayBuffered"
              @change="audioPlayBuffered"
            />
          </div>
        </div>
        <audio id="playAudio" ref="playAudio" :src="getUrl(httpAudio)" preload>
          <!-- <source  type="audio/mpeg"> -->
        </audio>
        <!-- 保存后的状态 -->
        <div class="audio-delete-and-save">
          <div class="iconfont icon-delete" @click="audioDeleteRecordingShow">
            &#58911;
          </div>
        </div>
      </div>
      <div class="audio-dynamic" v-show="false">
        <div style="width: 100%; height: 20px">
          <canvas id="canvas" v-show="audioPlay"></canvas>
          <canvas id="playChart" v-show="!audioPlay"></canvas>
        </div>
      </div>
    </div>
    <!-- 删除模态框 -->
    <a-modal
      class="report-crisps-model"
      :centered="true"
      v-model="reportAudioDelete"
      :title="getString(strings.Mind_Mindmap_Deleted_Forever)"
      :cancelText="getString(strings.Global_Cancel)"
      :okText="getString(strings.Global_Ok)"
      @cancel="commentAudioCancelHandleOk"
      @ok="commentAudioDeleteHandleOk"
    >
      <div class="report-crisps-style-box">
        <div
          class="report-crisps-style"
          style="
            font-size: 18px;
            font-family: Noto Sans SC;
            text-align: center;
            display: block;
          "
        >
          {{ getString(strings.Mind_Edit_Delete_Audio_Tips) }}
        </div>
      </div>
    </a-modal>
    <!-- 付费模态框 -->
    <a-modal
      class="report-crisps-model"
      :centered="true"
      v-model="reportIsMeberModelAccout"
      :title="getString(strings.Mind_Edit_Join_Membership)"
      :cancelText="getString(strings.Global_Cancel)"
      :okText="getString(strings.Mind_Edit_Upgrade)"
      @cancel="commentIsMeberCancelHandleOk"
      @ok="commentIsMeberDeleteHandleOk"
    >
      <div class="report-crisps-style-box">
        <div
          class="report-crisps-style"
          style="
            font-size: 18px;
            font-family: Noto Sans SC;
            text-align: center;
            display: block;
          "
        >
          {{ getString(strings.Mind_Edit_Join_Membership_Tips) }}
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import Recorder from "js-audio-recorder";

// import Player from '../../../../node_modules/_js-audio-recorder@1.0.6@js-audio-recorder/src/player/player';

// import Player from './player/player';
import {
  postMindmapAudioLimit,
  postMindmapDeleteAudio,
  postMindmapUploadAudio,
} from "../../../common/netWork/mind_map_api";
import EditMindmapVM from "../../../viewmodel/mindelementdata/vm/EditMindmapVM";
import { mapMutations } from "vuex";
import HttpServerConfig from "../../../common/HttpServerConfig";
import dataTimeToFormats from "../../../utils/timestampToTime";
import httpImageToPrefix from "../../../utils/httpImageToPrefix";
import recorderElectron from "../../../utils/recorder";
import crisps from "../../../assets/css/crisps/crisps.less";
import strings from "../../../common/lang/strings";
import getString from "../../../common/lang/language";
import MindMe from '../../../viewmodel/facade/MindMe';
import LoadLocalXJMind from '../../../viewmodel/core/tools/mapfile/LoadLocalXJMind';
import SaveMindMapToLocal from '../../../viewmodel/core/tools/mapfile/SaveMindMapToLocal';
import { md5 } from '../../../common/netWork/base';
import Util from '../../../utils/Util';
import MindMapZipConstant from '../../../viewmodel/core/tools/mapfile/MindMapZipConstant';

const lamejs = require("lamejs");
let recorder = new Recorder({
  sampleBits: 16, // 采样位数，支持 8 或 16，默认是16
  sampleRate: 48000, // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，我的chrome是48000
  numChannels: 1, // 声道，支持 1 或 2， 默认是1
  // compiling: false,(0.x版本中生效,1.x增加中)  // 是否边录边转换，默认是false
});

export default {
  data() {
    return {
      audioStartAndEndIcon: "START_AUDIO", //录制 暂停 //DE_CODE_AUDIO
      audioSaveAndDeleteRecording: false, //录制删除保存
      audioDeleteRecordingIcon: false, //保存后状态
      audioPlay: true, //播放录音 继续播放

      //波浪图-录音
      drawRecordId: null,
      oCanvas: null,
      ctx: null,
      //波浪图-播放
      drawPlayId: null,
      pCanvas: null,
      pCtx: null,
      audioTime: "00:00", //录制总时长
      audioTimeAccout: "00:00", //录音动态时间
      audioTimeToHttp: 0, //提供给服务器的录音时长
      audioMaxLimitMS: null, // 最大录音时长

      httpAudio: "", //线上audio数据
      audioPlayTime: "00:00", //播放时间
      playAccout: "START_PLAY_AUDIO", //播放状态
      playTimeWidth: 0, //播放进度条长度
      playCountTime: null, // 播放总时长
      suspedPlayTime: 0, //提供给进度条的数字
      config: {
        resourceType: "device", //媒体类型(可传'system'|'device'指定获取系统/麦克风声音)
        bufferHanduler: "", //buffer处理回调函数
        getMediaSuccessCallback: "", //获取媒体设备(系统声道或这麦克风)成功后的回调函数
        errorHanduler: "", //异常处理函数
      },
      reportAudioDelete: false, //删除录音确认框
      reportIsMeberModelAccout: false, //会员权益模态框
      strings: strings, //语言包
      microphonePermissions:false,//麦克风权限
    };
  },
  mounted() {
    this.startCanvas();
    this.postMindmapAudioLimit();
    /**
     * 本地进行操作的音频
     * this.$store.state.mp3Blob
     * @type {'del'} 将httpAudio音频置空
     * @type {'null','undefined','',} 将音频赋值给httpAudio
     */
    if (this.$store.state.mp3Blob == "del") {
      this.httpAudio = "";
    } else if (
      this.$store.state.mp3Blob != null &&
      this.$store.state.mp3Blob != undefined &&
      this.$store.state.mp3Blob != ""
    ) {
      this.httpAudio = this.$store.state.mp3Blob;
      this.setHttpAudioTime(); //获取音频时长
    } else {
      /**
       * 拉取线上导图音频
       * this.$store.state.canvanDatas
       * @param {audio} 'null','undefined','' 该导图没有音频
       * @return 
       */
      if (
        this.$store.state.canvanDatas == null ||
        this.$store.state.canvanDatas.audio == undefined
      ) {
        return;
      }
      //拼接线上地址
      this.httpAudio = httpImageToPrefix(this.$store.state.canvanDatas.audio);
      this.setHttpAudioTime();
    }
    //如果音频为空获取录音权限
    if (this.httpAudio == "") {
      this.getPermission();
    } else {
      //弹出是否删除音频模态框
      // this.reportAudioDelete = true;
    }
  },
  methods: {
    ...mapMutations([
      "mp3BlobData",
      "audioToHeaderShow",
      "isShowShoppingModel",
    ]),
    getString(i) {
      return getString(i);
    },
    getUrl(url){
      return LoadLocalXJMind.getLocalFilePath(httpImageToPrefix(url))
    },
    //确定删除音频模态框
    commentAudioDeleteHandleOk() {
      this.audioDeleteRecording();
      this.audioDeleteHttpRecording();
      this.reportAudioDelete = false;
    },
    //取消删除音频模态框
    commentAudioCancelHandleOk() {
      this.$emit("audioToHeaderShow", false);
    },
    //取消开通会员模态框
    commentIsMeberCancelHandleOk() {},
    //确定开通会员模态框
    commentIsMeberDeleteHandleOk() {
      this.reportIsMeberModelAccout = false;
      this.isShowShoppingModel(true);
    },
    /**
     * 获取线上录音总时长
     */
    setHttpAudioTime() {
      let audio = new Audio();
      audio.src = this.httpAudio;
      audio.load();
      audio.addEventListener("loadedmetadata", function () {
        //
      });
      audio.oncanplay = () => {
        this.audioPlayTime = dataTimeToFormats.sToHs(
          parseInt(audio.duration.toFixed(0))
        );
      };
    },
    /**
     * 获取最大录音时长
     */
    postMindmapAudioLimit() {
      if (MindMe.isLogin()) {
        postMindmapAudioLimit(null, (res) => {
          this.audioMaxLimitMS = parseFloat(res.maxLimitMS) / 1000;
        });
      } else {
        this.audioMaxLimitMS = 10000
      }
    },
    /**
     * 波浪图配置
     * */
    startCanvas() {
      //录音波浪
      this.oCanvas = document.getElementById("canvas");
      this.ctx = this.oCanvas.getContext("2d");
      //播放波浪
      this.pCanvas = document.getElementById("playChart");
      this.pCtx = this.pCanvas.getContext("2d");
    },
    //开始录制
    audioStratRecording() {
      if (
        typeof navigator === "object" &&
        typeof navigator.userAgent === "string" &&
        navigator.userAgent.indexOf("Electron") >= 0
      ) {
        new recorderElectron(this.config).start();
      }

      this.audioStartAndEndIcon = "STOP_AUDIO";
      if (recorder == null) {
        recorder = new Recorder({
          sampleBits: 16, // 采样位数，支持 8 或 16，默认是16
          sampleRate: 48000, // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，我的chrome是48000
          numChannels: 1, // 声道，支持 1 或 2， 默认是1
          // compiling: false,(0.x版本中生效,1.x增加中)  // 是否边录边转换，默认是false
        });
      }
      this.startCanvas();
      var that = this;
      recorder.start().then(
        () => {
          that.drawRecord(); // 开始绘制图片
          // 绑定事件-打印的是当前录音数据
          recorder.onprogress = function (params) {
            that.audioTimeAccout = dataTimeToFormats.sToHs(
              params.duration.toFixed(0)
            );
            that.audioTime = dataTimeToFormats.sToHs(
              params.duration.toFixed(0)
            );
            that.audioTimeToHttp = params.duration.toFixed(0);
          };
          this.drawRecord(); //开始绘制图片

        },
        (error) => {
          if (!this.microphonePermissions) {
            this.getPermissionFail();
          }
          // 出错了
          // console.log(error,1);
        }
      );
    },
    //暂停播放 回放
    audioStopRecording() {
      if (this.audioSaveAndDeleteRecording) {
        recorder.pausePlay();
        this.audioPlay = false;
        this.audioStartAndEndIcon = "PLAY_BACK_AUDIO";
      } else {
        this.audioStopOverRecording();
      }
    },
    //回放录音
    audioPlayBackRecording() {
      this.audioStartAndEndIcon = "STOP_AUDIO";
      if (this.audioPlay) {
        // 播放录音;
        let saveTime = dataTimeToFormats.sToHs(this.audioTimeAccout);
        this.audioPlay = false;
        recorder.play();
        this.drawPlay(); //绘制波浪图
        //倒计时
        this.audioTimes = setInterval(() => {
          //播放时长等于总时长停止播放
          this.audioTimeAccout = dataTimeToFormats.sToHs(
            recorder.getPlayTime().toFixed(0)
          );
          if (this.audioTimeAccout == this.audioTime) {
            this.audioStartAndEndIcon = "PLAY_BACK_AUDIO";
            this.audioPlay = true;
            this.audioTimeAccout = "00:00";
            recorder.stopPlay(); //停止录音播放
            clearInterval(this.audioTimes);
          }
        }, 1000);
      } else {
        // 恢复播放录音
        recorder.resumePlay();
        this.drawPlay(); //绘制波浪图
      }
    },
    //结束录音
    audioStopOverRecording() {
      if (
        typeof navigator === "object" &&
        typeof navigator.userAgent === "string" &&
        navigator.userAgent.indexOf("Electron") >= 0
      ) {
        new recorderElectron(this.config).restart();
      }
      this.audioStartAndEndIcon = "PLAY_BACK_AUDIO";
      this.audioSaveAndDeleteRecording = true;
      recorder.stop();
      this.drawRecordId && cancelAnimationFrame(this.drawRecordId);
      this.drawRecordId = null;
      this.getRecorder();
    },
    //上传录音
    audioUploadRecording() {
      if (dataTimeToFormats.sToHs(this.audioMaxLimitMS) < this.audioTime) {
        /**
         * 非会员 10秒
         * 会员   45分钟
         * 到时停止录音
         */
        this.reportIsMeberModelAccout = true;
        return;
      }
      this.audioStartAndEndIcon = "DE_CODE_AUDIO";
      this.audioSaveAndDeleteRecording = false;
      setTimeout(() => {
        // this.audioSaveAndDeleteRecording = true;
        recorder.stop();
        this.drawRecordId && cancelAnimationFrame(this.drawRecordId);
        this.drawRecordId = null;
        this.getRecorder();
        this.getMp3Data();
      }, 20);
    },
    handleFile(e) {
      let $target = e.$target || e.srcElement;
      let mp3Blob = $target.files[0];
      
      //获取录音时长
      let url = URL.createObjectURL(mp3Blob);
      let audioElement = new Audio(url);
      let duration = new Promise((resolve, reject) => {
        return audioElement.addEventListener("loadedmetadata", function (time) {
          resolve(audioElement.duration.toFixed(0));
        });
      });
      duration.then((time) => {
        this.$el.querySelector(".hiddenInput").value = null;
        if (this.audioMaxLimitMS < time) {
          /**
           * 非会员 10秒
           * 会员   45分钟
           * 到时停止录音
           */
          this.reportIsMeberModelAccout = true;
          // this.isShowShoppingModel(true);
          return;
        }
        if (LoadLocalXJMind.isLoaclMind() && !LoadLocalXJMind.isCloudStorage()) {
          let nodeAudioLocalPath = LoadLocalXJMind.getLocalFileRootPath() + MindMapZipConstant.FileSeparator + md5(Util.getCurrentTime())
          if (mp3Blob.type != null && mp3Blob.type.indexOf("ogg") >= -1) {
            nodeAudioLocalPath = nodeAudioLocalPath + ".mp3"
          } else {
            nodeAudioLocalPath = nodeAudioLocalPath + ".mp3"
          }
          let localAudioPath = LoadLocalXJMind.getLocalFileRootPath() + MindMapZipConstant.FileSeparator + md5(nodeAudioLocalPath) + "." + Util.getUrlType(nodeAudioLocalPath)

          let that = this;
          let audioFileReader = new FileReader()
          audioFileReader.readAsArrayBuffer(mp3Blob)
          audioFileReader.onload = function (e){ 
              let buf = e.target.result
              let data = new Int8Array(buf)
              SaveMindMapToLocal.saveLocalContent(localAudioPath, "", data).then((success) => {
                if (success) {
                  setTimeout(() => {
                    that.mp3BlobData(nodeAudioLocalPath);
                    that.httpAudio = nodeAudioLocalPath;
                    that.$emit("audioToHeaderShow", false);
                    that.audioStartAndEndIcon = "PLAY_BACK_AUDIO";
                  }, 500);
                  
                } else {
                  that.$message.error("存储录音文件错误");
                  that.audioStartAndEndIcon = "START_AUDIO";
                  that.$emit("audioToHeaderShow", false);
                }
              })              
          };
          return
        }

        if (mp3Blob != null && mp3Blob != "") {
          let handler;
          //判断是否处在群组编辑页面。
          if(this.$router.history.current.name == 'gmap'  ) {
            handler = {
              "X-Type": "gmap-global-audio",
              "X-ID": this.$route.query.id
            }
          } else {
            handler = {
              "X-Type": "mindmap-global-audio",
              "X-ID": this.$route.query.id,
            }
          }
          postMindmapUploadAudio(
            mp3Blob,
            (res) => {
              this.mp3BlobData(res.audioUrl);
              this.httpAudio = httpImageToPrefix(res.audioUrl);
              this.$emit("audioToHeaderShow", false);
              this.audioStartAndEndIcon = "PLAY_BACK_AUDIO";
            },
            (e) => {
              this.$message.error(e.desc);
            },
            null,
            handler
          );
        }
      });
    },
    //上传本地录音
    audioUploadLocal() {
      this.$el.querySelector(".hiddenInput").click();
      // if (mp3Blob != null) {
    },
    audioDeleteRecordingShow(){
      //弹出是否删除音频模态框
      this.reportAudioDelete = true;
    },
    //删除录音
    audioDeleteRecording() {
      this.audioStartAndEndIcon = "START_AUDIO";
      this.audioDeleteRecordingIcon = false;
      this.audioSaveAndDeleteRecording = false;
      var that = this;
      recorder.destroy().then(function () {
        recorder = null;
        recorder = new Recorder({
          sampleBits: 16, // 采样位数，支持 8 或 16，默认是16
          sampleRate: 48000, // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，我的chrome是48000
          numChannels: 1, // 声道，支持 1 或 2， 默认是1
          // compiling: false,(0.x版本中生效,1.x增加中)  // 是否边录边转换，默认是false
        });
        that.httpAudio = "";
        that.mp3BlobData("");
        that.audioPlay = true;
        recorder.stopPlay(); //停止录音播放
        clearInterval(that.audioTimes);
        that.audioTime = "00:00";
        that.audioTimeAccout = 0;
        that.drawRecordId && cancelAnimationFrame(that.drawRecordId);
        that.drawRecordId = null;
      });
    },
    /**
     *  获取录音文件
     * */
    getRecorder() {
      let toltime = recorder.duration; //录音总时长
      let fileSize = recorder.fileSize; //录音总大小

      //录音结束，获取取录音数据
      let PCMBlob = recorder.getPCMBlob(); //获取 PCM 数据
      let wav = recorder.getWAVBlob(); //获取 WAV 数据

      let channel = recorder.getChannelData(); //获取左声道和右声道音频数据
    },
    /**
     *  获取麦克风权限
     * */
    getPermission() {
      Recorder.getPermission().then(
        () => {
          this.microphonePermissions=true;
          // this.$message.success(
          //   getString(strings.Message_Tips_Get_Permission_Successfully)
          // );
        },
        (error) => {
          // console.log(error,'2');
          this.microphonePermissions=false;
          // this.getPermissionFail();
        }
      );
    },
    //获取录音设备失败
    getPermissionFail(){
      this.$message.destroy();
      this.$message.error(getString(strings.Failed_To_Get_Recording_Device));
    },
    /**
     * 文件格式转换 wav-map3
     * */
    //下载wav
    downWAV() {
      //这里传参进去的时文件名
      recorder.downloadWAV("新文件");
    },
    // mp3数据
    getMp3Data() {
      const mp3Blob = this.convertToMp3(recorder.getWAV());
      if (mp3Blob != null) {
        if (LoadLocalXJMind.isLoaclMind() && !LoadLocalXJMind.isCloudStorage()) {
          let nodeAudioLocalPath = LoadLocalXJMind.getLocalFileRootPath() + MindMapZipConstant.FileSeparator + md5(Util.getCurrentTime())
          if (mp3Blob.type != null && mp3Blob.type.indexOf("ogg") >= -1) {
            nodeAudioLocalPath = nodeAudioLocalPath + ".mp3"
          } else {
            nodeAudioLocalPath = nodeAudioLocalPath + ".mp3"
          }
          let localAudioPath = LoadLocalXJMind.getLocalFileRootPath() + MindMapZipConstant.FileSeparator + md5(nodeAudioLocalPath) + "." + Util.getUrlType(nodeAudioLocalPath)

          let that = this;
          let audioFileReader = new FileReader()
          audioFileReader.readAsArrayBuffer(mp3Blob)
          audioFileReader.onload = function (e){ 
              let buf = e.target.result
              let data = new Int8Array(buf)
              SaveMindMapToLocal.saveLocalContent(localAudioPath, "", data).then((success) => {
                if (success) {
                  setTimeout(() => {
                    that.mp3BlobData(nodeAudioLocalPath);
                    that.httpAudio = nodeAudioLocalPath;
                    that.$emit("audioToHeaderShow", false);
                    that.audioStartAndEndIcon = "PLAY_BACK_AUDIO";
                  }, 500);
                  
                } else {
                  that.$message.error("存储录音文件错误");
                  that.audioStartAndEndIcon = "START_AUDIO";
                  that.$emit("audioToHeaderShow", false);
                }
              })              
          };
          return
        }
        let handler;
        //判断是否处在群组编辑页面。
        if(this.$router.history.current.name == 'gmap'  ) {
          handler = {
            "X-Type": "gmap-global-audio",
            Duration: this.audioTimeToHttp,
            "X-ID": this.$route.query.id
          }
        } else {
          handler = {
            "X-Type": "mindmap-global-audio",
            Duration: this.audioTimeToHttp,
            "X-ID": this.$route.query.id,
          }
        }
        postMindmapUploadAudio(
          mp3Blob,
          (res) => {
            this.mp3BlobData(
              // HttpServerConfig.getResourceDownloadRoot() + "/" +
              res.audioUrl
            );
            this.httpAudio = httpImageToPrefix(res.audioUrl);
            this.$emit("audioToHeaderShow", false);
            this.audioStartAndEndIcon = "PLAY_BACK_AUDIO";
          },
          (e) => {
            this.$message.error(e.desc);
            this.audioStartAndEndIcon = "START_AUDIO";
            this.$emit("audioToHeaderShow", false);
          },
          null,
          handler
        );
      }
      //recorder.download(mp3Blob, "recorder", "mp3");
    },
    convertToMp3(wavDataView) {
      // 获取wav头信息
      const wav = lamejs.WavHeader.readHeader(wavDataView); // 此处其实可以不用去读wav头信息，毕竟有对应的config配置
      const { channels, sampleRate } = wav;
      const mp3enc = new lamejs.Mp3Encoder(channels, sampleRate, 128);
      // 获取左右通道数据
      const result = recorder.getChannelData();
      const buffer = [];

      const leftData =
        result.left &&
        new Int16Array(result.left.buffer, 0, result.left.byteLength / 2);
      const rightData =
        result.right &&
        new Int16Array(result.right.buffer, 0, result.right.byteLength / 2);
      const remaining = leftData.length + (rightData ? rightData.length : 0);

      const maxSamples = 1152;
      for (let i = 0; i < remaining; i += maxSamples) {
        const left = leftData.subarray(i, i + maxSamples);
        let right = null;
        let mp3buf = null;

        if (channels === 2) {
          right = rightData.subarray(i, i + maxSamples);
          mp3buf = mp3enc.encodeBuffer(left, right);
        } else {
          mp3buf = mp3enc.encodeBuffer(left);
        }

        if (mp3buf.length > 0) {
          buffer.push(mp3buf);
        }
      }

      const enc = mp3enc.flush();

      if (enc.length > 0) {
        buffer.push(enc);
      }

      return new Blob(buffer, { type: "audio/ogg;codecs=opus" });
    },
    /**
     * 绘制波浪图-录音
     * */
    drawRecord() {
      if (recorder == null) {
        recorder = new Recorder({
          sampleBits: 16, // 采样位数，支持 8 或 16，默认是16
          sampleRate: 48000, // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，我的chrome是48000
          numChannels: 1, // 声道，支持 1 或 2， 默认是1
          // compiling: false,(0.x版本中生效,1.x增加中)  // 是否边录边转换，默认是false
        });
      }
      // 用requestAnimationFrame稳定60fps绘制
      this.drawRecordId = requestAnimationFrame(this.drawRecord);

      // 实时获取音频大小数据
      let dataArray = recorder.getRecordAnalyseData(),
        bufferLength = dataArray.length;

      // 填充背景色
      this.ctx.fillStyle = "rgb(255, 255, 255,1)";
      this.ctx.fillRect(0, 0, this.oCanvas.width, this.oCanvas.height);

      // 设定波形绘制颜色
      this.ctx.lineWidth = 2;
      this.ctx.strokeStyle = "rgb(239, 104, 80, 1)";

      this.ctx.beginPath();

      var sliceWidth = (this.oCanvas.width * 1.0) / bufferLength, // 一个点占多少位置，共有bufferLength个点要绘制
        x = 0; // 绘制点的x轴位置

      for (var i = 0; i < bufferLength; i++) {
        var v = dataArray[i] / 128.0;
        var y = (v * this.oCanvas.height) / 2;

        if (i === 0) {
          // 第一个点
          this.ctx.moveTo(x, y);
        } else {
          // 剩余的点
          this.ctx.lineTo(x, y);
        }
        // 依次平移，绘制所有点
        x += sliceWidth;
      }

      this.ctx.lineTo(this.oCanvas.width, this.oCanvas.height / 2);
      this.ctx.stroke();
    },
    /**
     * 绘制波浪图-播放
     * */
    drawPlay() {
      if (recorder == null) {
        recorder = new Recorder({
          sampleBits: 16, // 采样位数，支持 8 或 16，默认是16
          sampleRate: 48000, // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，我的chrome是48000
          numChannels: 1, // 声道，支持 1 或 2， 默认是1
          // compiling: false,(0.x版本中生效,1.x增加中)  // 是否边录边转换，默认是false
        });
      }
      // 用requestAnimationFrame稳定60fps绘制
      this.drawPlayId = requestAnimationFrame(this.drawPlay);

      // 实时获取音频大小数据
      let dataArray = recorder.getPlayAnalyseData(),
        bufferLength = dataArray.length;

      // 填充背景色
      this.pCtx.fillStyle = "rgb(255, 255, 255,1)";
      this.pCtx.fillRect(0, 0, this.pCanvas.width, this.pCanvas.height);

      // 设定波形绘制颜色
      this.pCtx.lineWidth = 2;
      this.pCtx.strokeStyle = "rgb(239, 104, 80, 1)";

      this.pCtx.beginPath();

      var sliceWidth = (this.pCanvas.width * 1.0) / bufferLength, // 一个点占多少位置，共有bufferLength个点要绘制
        x = 0; // 绘制点的x轴位置

      for (var i = 0; i < bufferLength; i++) {
        var v = dataArray[i] / 128.0;
        var y = (v * this.pCanvas.height) / 2;

        if (i === 0) {
          // 第一个点
          this.pCtx.moveTo(x, y);
        } else {
          // 剩余的点
          this.pCtx.lineTo(x, y);
        }
        // 依次平移，绘制所有点
        x += sliceWidth;
      }

      this.pCtx.lineTo(this.pCanvas.width, this.pCanvas.height / 2);
      this.pCtx.stroke();
    },
    //删除线上导图audio
    audioDeleteHttpRecording() {
      postMindmapDeleteAudio(
        { audioUrl: this.httpAudio },
        (res) => {
          this.$message.success(
            getString(strings.Message_Tips_Get_Permission_Successfully)
          );
          this.audioSaveAndDeleteRecording = false;
          this.audioStartAndEndIcon = "START_AUDIO";
          this.httpAudio = "";
          this.mp3BlobData("");
        },
        (error) => {
          this.$message.error(error.desc);
        }
      );
    },
    //线上录音播放状态
    playAudio(key) {
      switch (key) {
        case 1:
          this.playAccout = "SUSPEND_AUDIO";
          this.playOrSuspedAudio();
          break;
        case 2:
          this.playAccout = "STOP_AUDIO";
          this.playOrSuspedAudio();
          break;
        case 3:
          this.playAccout = "SUSPEND_AUDIO";
          this.playOrSuspedAudio();
          break;
        default:
          break;
      }
    },
    playOrSuspedAudio() {
      let audio = document.getElementById("playAudio");
      if (this.playAccout == "SUSPEND_AUDIO") {
        audio.play();
        //播放时长
        this.playTimeWidth = parseInt(audio.duration.toFixed(0));
        this.playCountTime = setInterval(() => {
          this.audioPlayTime = dataTimeToFormats.sToHs(
            parseInt(audio.currentTime.toFixed(0))
          );
          this.suspedPlayTime = parseInt(audio.currentTime.toFixed(0));
          if (
            parseInt(audio.currentTime.toFixed(0)) ==
            parseInt(audio.duration.toFixed(0))
          ) {
            this.playAccout = "STOP_AUDIO";
            clearTimeout(this.playCountTime);
          }
        }, 1000);
      } else {
        audio.pause();
        clearTimeout(this.playCountTime);
      }
    },
    //更改音频播放进度
    audioPlayBuffered(value) {
      this.playAccout = "STOP_AUDIO";
      clearTimeout(this.playCountTime);

      let audio = document.getElementById("playAudio");
      this.audioPlayTime = dataTimeToFormats.sToHs(value);
      this.suspedPlayTime = value;
      audio.currentTime = value;
      this.playAccout = "SUSPEND_AUDIO";
      this.playOrSuspedAudio();
    },
  },
  watch: {
    // audioTime() {
    //   if (dataTimeToFormats.sToHs(this.audioMaxLimitMS) == this.audioTime) {
    //     /**
    //      * 非会员 10秒
    //      * 会员   45分钟
    //      * 到时停止录音
    //      */
    //     this.audioStopOverRecording();
    //   }
    // },
    // "$store.state.mp3Blob"(newMp3Blob){
    //   this.httpAudio = newMp3Blob
    // },
    "$store.state.canvanDatas.audio"(newHttpAudioData) {
      if (newHttpAudioData != "") {
        this.httpAudio = newHttpAudioData;
      }
    },
  },
};
</script>

<style lang="less" scoped>
@font-face {
  font-family: "iconfont";
  src: url("../../../assets/font-icon/mind-audio/iconfont.eot"); /* IE9 */
  src: url("../../../assets/font-icon/mind-audio/iconfont.eot")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../../../assets/font-icon/mind-audio/iconfont.woff2")
      format("woff2"),
    url("../../../assets/font-icon/mind-audio/iconfont.woff2") format("woff"),
    url("../../../assets/font-icon/mind-audio/iconfont.ttf") format("truetype"),
    /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
      url("../../../assets/font-icon/mind-audio/iconfont.ttf#iconfont")
      format("svg"); /* iOS 4.1- */
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 24px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}
.icon-audio {
  color: #ec705a;
  display: flex;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
  }
}
#complie-audio-box {
  background: #ffffff;
  border-radius: 4px;
  min-width: 168px;
  height: 40px;
  position: absolute;
  top: 56px;
  border: 1px solid #ccc;
  box-shadow: rgb(153 153 153) 0px 0px 5px;
  z-index: 800;
  display: flex;
  align-items: center;
  .complie-content {
    margin: 10px;
    .audio-start-and-end {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .hiddenInput {
        display: none;
      }
      .audio-dynamic {
        width: 83px;
        height: 20px;
      }
      .audio-time {
        font-size: 12px;
        font-family: Noto Sans SC;
        font-weight: 400;
        line-height: 17px;
        color: #ec705a;
        opacity: 1;
        margin-left: 10px;
      }
      .audio-delete-and-save {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: 22px;
        .iconfont-division {
          border-right: 1px solid #bbbbbb;
          height: 12px;
          margin: 0 10px;
        }
        .audio-total-time {
          width: 60px;
          font-size: 12px;
          font-family: Noto Sans SC;
          font-weight: 400;
          line-height: 17px;
          color: #ec705a;
          opacity: 1;
          margin-left: 10px;
        }
      }
    }
  }
  .audio-http-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    .play-auido-box {
      display: flex;
      align-items: center;
      position: relative;
      .play-content {
        display: flex;
        align-items: center;
        .play-button {
          img {
            cursor: pointer;
          }
        }
        .play-time {
          font-size: 12px;
          height: 14px;
          font-family: Noto Sans SC;
          font-weight: 400;
          line-height: 17px;
          color: #ec705a;
          opacity: 1;
          margin-left: 10px;
        }
        .play-time-width {
          margin-left: 6px;
          width: 98px;
        }
      }
    }
  }
}
canvas {
  width: 83px;
  height: 20px;
}
audio {
  margin: 0.25rem 0;
  height: 2.52rem;
  border-radius: 25px;
  background: rgb(187, 221, 213);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.82) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  position: relative;
  border: none;
}
</style>