<template>
<!-- 节点类型 -->
  <div id="node-type-box" class="menu-content-border-box">
    <div class="menu-use-content-inside" ref="nodeTypeBox">
      <!-- <ul v-if="borderAccout.common" class="menu-use-box">
        <li
          v-for="(item, index) in layoutStyleList"
          :key="index"
          @click="getLayoutType(item.nodeLayoutType,item.layoutIcon)"
        >
          <img :src="item.layoutIcon" />
        </li>
      </ul> -->
      <!-- <ul v-if="borderAccout.Straight" class="menu-use-box">
        <li
          v-for="(item, index) in layoutStyleStraight"
          :key="index"
          @click="getLayoutType(item.nodeLayoutType,item.layoutIcon)"
        >
          <img :src="item.layoutIcon" />
        </li>
      </ul> -->
      <ul v-if="borderAccout.Curve" class="menu-use-box">
        <li
          v-for="(item, index) in layoutStyleLine"
          :key="index"
          @click="getLayoutType(item.nodeLayoutType,mapEditDarkMode?item.imgWhite:item.layoutIcon)"
        >
          <img v-if="mapEditDarkMode" :src="item.imgWhite" />
          <img v-else :src="item.layoutIcon" />
        </li>
      </ul>
      <!-- <ul v-if="borderAccout.DoubelStraight" class="menu-use-box">
        <li
          v-for="(item, index) in layoutStyleDoubelStraight"
          :key="index"
          @click="getLayoutType(item.nodeLayoutType,item.layoutIcon)"
        >
          <img :src="item.layoutIcon" />
        </li>
      </ul> -->
    </div>
  </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
import MindElementType from "../../../viewmodel/datatype/MindElementType";
import MindOperateUIControllerView from "../tools/MindOperateUIControllerView";
import MindType from "../../../viewmodel/datatype/MindType";
import TimeNodeLayoutType from '../../../viewmodel/datatype/TimeNodeLayoutType';
// globalLayout
export default {
  name: "ComplieChildMenuLayout",
  data() {
    return {
      // layoutStyleList: [
      //   {
      //     layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/left_right_layout_icon.png"),
      //     nodeLayoutType: MindElementType.LAYOUT_LEFT_RIGHT,
      //   },
      //   {
      //     layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/left_layout_icon.svg"),
      //     nodeLayoutType: MindElementType.LAYOUT_LEFT,
      //   },
      //   {
      //     layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/layout_right_icon.svg"),
      //     nodeLayoutType: MindElementType.LAYOUT_RIGHT,
      //   },
      //   {
      //     layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/layout_bottom_icon.svg"),
      //     nodeLayoutType: MindElementType.LAYOUT_BOTTOM,
      //   },
      //   {
      //     layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/layout_tree_left_right_icon.svg"),
      //     nodeLayoutType: MindElementType.LAYOUT_TREE_LEFT_RIGHT,
      //   },
      //   {
      //     layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/tree_left_layout_icon.svg"),
      //     nodeLayoutType: MindElementType.LAYOUT_TREE_LEFT,
      //   },
      //   {
      //     layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/tree_right_layout_icon.svg"),
      //     nodeLayoutType: MindElementType.LAYOUT_TREE_RIGHT,
      //   },
      //   {
      //     layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/brackets_right_layout_icon.svg"),
      //     nodeLayoutType: MindElementType.LAYOUT_BRACKETS_RIGHT,
      //   },
      //   {
      //     layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/layout_form_icon.svg"),
      //     nodeLayoutType: MindElementType.LAYOUT_FORM,
      //   },
      //   {
      //     layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/layout_triangle_icon.svg"),
      //     nodeLayoutType: MindElementType.LAYOUT_TRIANGLE,
      //   },

      //   {
      //     layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/clockwise.svg"),
      //     nodeLayoutType: MindElementType.LAYOUT_TREE_RIGHT,
      //   },
      //   {
      //     layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/left_arrow.svg"),
      //     nodeLayoutType: MindElementType.LAYOUT_BRACKETS_RIGHT,
      //   },
      //   {
      //     layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/right_arrow.svg"),
      //     nodeLayoutType: MindElementType.LAYOUT_FORM,
      //   },
      //   {
      //     layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/left_braces.svg"),
      //     nodeLayoutType: MindElementType.LAYOUT_TRIANGLE,
      //   },

      // ],
      // layoutStyleStraight: [
      //   {
      //     layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/layout_boubel.png"),
      //     nodeLayoutType: MindElementType.LAYOUT_BUBBLE,
      //   },
      //   {
      //     layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/layout_form_icon.svg"),
      //     nodeLayoutType: MindElementType.LAYOUT_FORM,
      //   },
      // ],
      layoutStyleLine: [
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_element_layout/lightModeIcon/nodeType1.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_element_layout/darkModeIcon/nodeType1.svg"),
          nodeLayoutType: TimeNodeLayoutType.NORMAL,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_element_layout/lightModeIcon/nodeType2.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_element_layout/darkModeIcon/nodeType2.svg"),
          nodeLayoutType: TimeNodeLayoutType.TITLE_DES_BOTTOM,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_element_layout/lightModeIcon/nodeType3.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_element_layout/darkModeIcon/nodeType3.svg"),
          nodeLayoutType: TimeNodeLayoutType.TITLE_BOTTOM,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_element_layout/lightModeIcon/nodeType4.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_element_layout/darkModeIcon/nodeType4.svg"),
          nodeLayoutType: TimeNodeLayoutType.HEAD_TITLE_TOP_BOTTOM,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_element_layout/lightModeIcon/nodeType5.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_element_layout/darkModeIcon/nodeType5.svg"),
          nodeLayoutType: TimeNodeLayoutType.HEAD_TITLE_DES_MIDDLE_BOTTOM,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_element_layout/lightModeIcon/nodeType6.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_element_layout/darkModeIcon/nodeType6.svg"),
          nodeLayoutType: TimeNodeLayoutType.HEAD_TITLE_MIDDLE_BOTTOM,
        },
      ],
      // layoutStyleDoubelStraight: [
      //   {
      //     layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/layout_boubel.png"),
      //     nodeLayoutType: MindElementType.LAYOUT_BUBBLE,
      //   },
      // ],
      layoutType: "",
      viewModel: null,
      mindOperateUIControllerView: null,
      borderAccout: {
        common: false,
        Straight: false,
        Curve: false,
        DoubelStraight:false
      },
    };
  },
  computed: {
    ...mapState({
      mapEditDarkMode: (state) => state.mapEditIsDardarkMode.darkMode, //深色模式
    }),
  },
  created() {},
  mounted() {
    if (this.viewModel == null) {
      this.viewModel = this.$store.state.editMindmap;
      this.getLayoutAccout(this.$store.state.initData)
    }
    this.setDardarkMode();
  },
  methods: {
    ...mapMutations(["mindElementTypeLayout"]),
    getLayoutType(type,url) {
      if (this.mindOperateUIControllerView == null) {
        this.mindOperateUIControllerView = new MindOperateUIControllerView(
          this.viewModel,
          this.viewModel
        );
      }
      // this.mindOperateUIControllerView.onSelected(type);
      this.viewModel.changeTimeNodeLayoutType(type);
      this.mindElementTypeLayout(url)
    },
    getLayoutAccout(newInitData) {
      let borderAccoutData = newInitData;
      if (borderAccoutData.mindType == MindType.BUBBLE_MAP) {
        this.borderAccout.Straight = true;
        this.borderAccout.common = false;
        this.borderAccout.Curve = false;
        this.borderAccout.DoubelStraight = false
      } else if (borderAccoutData.mindType == MindType.LINE_MAP) {
        this.borderAccout.Straight = false;
        this.borderAccout.common = true;
        this.borderAccout.Curve = false;
        this.borderAccout.DoubelStraight = false
      }else if (borderAccoutData.mindType == MindType.TIME_MAP) {
        this.borderAccout.Straight = false;
        this.borderAccout.common = false;
        this.borderAccout.Curve = true;
        this.borderAccout.DoubelStraight = false;
      }else if(borderAccoutData.mindType == MindType.DOUBLE_BUBBLE_MAP){
        this.borderAccout.Straight = false;
        this.borderAccout.common = false;
        this.borderAccout.Curve = false;
        this.borderAccout.DoubelStraight = true;
      }
    },
    setDardarkMode() {
      // 设置深色模式
      this.$nextTick(() => {
        let boxDom = this.$refs.nodeTypeBox;
        let dardarkMode = this.$store.state.mapEditIsDardarkMode;
        if (boxDom) {
          if (dardarkMode.darkMode) {
            // boxDom.style.color = dardarkMode.fontColor;
            boxDom.style.background = dardarkMode.bgColor;
            boxDom.style.border = '1px solid rgba(255,255,255,.3)';
          } else {
            // boxDom.style.color = "#666666";
            boxDom.style.background = "#fff";
            boxDom.style.border = 0;
          }
        }
      });
    },
  },
  watch: {
    "$store.state.initData"(newInitData, olInitData) {
      this.getLayoutAccout(newInitData);
    },
    "$store.state.editMindmap"(newval, olval) {
      this.viewModel = newval;
    },
     "$store.state.mapEditIsDardarkMode": {
      //导图编辑页面是否为深色模式
      handler(newVal, oldVal) {
        this.setDardarkMode();
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.menu-use-content-inside {
  width: 268px;
  /* height: 190px; */
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 2px 10px 6px rgba(0, 0, 0, 0.1);
}
.menu-use-content-inside ul {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.menu-use-content-inside ul li {
  width: 45px;
  height: 45px;
  float: left;
  list-style: none;
  margin: 8px 8px;
  padding: 3px 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.menu-use-content-inside ul li:hover {
  background:  var(--rightClickMenuBg);
}
.menu-use-content-inside ul li img {
  /* width: 80%;
  height: 80%; */
}
.menu-theme-border {
  background: #e0e0e0;
}
</style>