<template>
  <div class="croppers">
    <div class="cropper-content">
      <div class="cropper" style="text-align: center">
        <vue-Cropper
          ref="cropper"
          :img="option.img"
          :outputSize="option.size"
          :outputType="option.outputType"
          :info="true"
          :full="option.full"
          :canMove="option.canMove"
          :canMoveBox="option.canMoveBox"
          :original="option.original"
          :autoCrop="option.autoCrop"
          :fixed="option.fixed"
          :fixedNumber="option.fixedNumber"
          :centerBox="option.centerBox"
          :infoTrue="option.infoTrue"
          :fixedBox="option.fixedBox"
          :mode="option.mode"
        ></vue-Cropper>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <a-button @click="dialogVisibleHide">{{
        getString(strings.Global_Cancel)
      }}</a-button>
      <a-button type="primary" @click="finish">{{
        getString(strings.Global_Ok)
      }}</a-button>
      <!-- <a-button type="danger" @click="">放大</a-button> -->
      <div
          class="each-scale-box add-scale-box"
          @click="changeScale(1)"
        >
          <div class="active-show-background"></div>
          <span class="iconfont-scale center-icon-box">&#xe606;</span>

          <div class="bottom-gray-text-content">放大</div>
      </div>

      <div
          class="each-scale-box lost-scale-box"
          @click="changeScale(-1)"
        >
          <div class="active-show-background"></div>
          <span class="iconfont-scale center-icon-box">&#xe605;</span>
          <div class="bottom-gray-text-content">缩小</div>
      </div>

      <!-- <a-button type="danger"  @click="changeScale(-1)">缩小</a-button> -->
    </div>
    <SpinningAnimation :spinning="spinning" />
  </div>
</template>
<script>
import { mapMutations } from "vuex";
import HttpServerConfig from '../../../common/HttpServerConfig';
import getString from "../../../common/lang/language";
import strings from "../../../common/lang/strings";
import { postMindmapUploadImage } from "../../../common/netWork/mind_map_api";
import imageToStyle from "../../../utils/ImageToolkit";
import SpinningAnimation from"./../../spinningAnimation/SpinningAnimation";
export default {
  components: {
    SpinningAnimation,
  },
  data() {
    return {
      // 裁剪组件的基础配置option
      option: {
        img: "", // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 1, // 裁剪生成图片的质量
        outputType: "jpeg", // 裁剪生成图片的格式
        canScale: false, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 2000, // 默认生成截图框宽度
        autoCropHeight: 1920, // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: false, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例
        full: true, // 是否输出原图比例的截图
        canMoveBox: true, // 截图框能否拖动
        canMove: false, // 图片能否拖动
        original: true, // 上传图片按照原始比例渲染
        centerBox: true, // 截图框是否被限制在图片里面
        infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
        mode: "cover",
      },
      picsList: [], //页面显示的数组
      // 防止重复提交
      loading: false,
      imgUrlData: "",
      strings: strings,
      spinning:false,
      seekBarNumber:0,
    };
  },
  mounted() {
    if (this.option.img == "") {
      let that = this;
      if (this.$store.state.cropperImgUrl == null) {
        return;
      }

      //获取图片的宽高
      let img = new Image();img.setAttribute('crossOrigin','Anonymouse');
      img.src = this.$store.state.cropperImgUrl.img;
      img.onload = function () {
        that.option.autoCropWidth = img.width;
        that.option.autoCropHeight = img.height;
      };
      this.option.img = this.$store.state.cropperImgUrl.img;
      //设置截图框的比例
      if (this.$store.state.cropperImgUrl.type == "storm") {
        this.option.fixedNumber = [5, 4];
        this.option.fixedBox = true;
      } else if (this.$store.state.cropperImgUrl.type == "uploadImg") {
        // this.option.fixedNumber = [1, 1];
        this.option.fixedBox = false;
        this.option.fixed = false;
      }
    }
  },
  methods: {
    ...mapMutations(["showCropperImg", "showCropperImgUrl", "setDomImg"]),
    getString(i) {
      return getString(i);
    },
    dialogVisibleHide() {
      this.showCropperImg(false);
    },
    finish() {
      /**
       * this.$store.state.cropperImgUrl.type
       * storm 风暴截图
       * uploadImg 导图内部上传的图片例如 素材库 本地上传
       */
      this.spinning = true;
      this.$refs.cropper.getCropData((data) => {
        //图片转化成blob类型
        function blobData() {
          var arr = data.split(","),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
          while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
          }
          return new Blob([u8arr], { type: mime });
        }
        var blob = blobData();
        //风暴截图
        if (this.$store.state.cropperImgUrl.type == "storm") {
          if (blob.type.indexOf("image/") > -1) {
            postMindmapUploadImage(
              blob,
              (res) => {
                this.showCropperImgUrl("");
                let imgUrlData = res.imageUrl;
                let width;
                let height;
                let img = new Image();img.setAttribute('crossOrigin','Anonymouse');
                img.src = imgUrlData;
                width = img.width;
                height = img.height;
                //将数据传入vuex
                this.setDomImg({
                  imageBase64: imgUrlData,
                  type: "storm",
                  width: width,
                  height: height,
                });
                this.spinning = false;
                this.showCropperImg(false);
              },
              (error) => {
                this.spinning = false;
                console.log(error);
              },
              null,
              {
                "X-Type": "crisps-cover",
                "X-ID": this.$route.query.id,
              }
            );
          } else {
            this.spinning = false;
            console.log("file is not a image");
          }
        } else if (this.$store.state.cropperImgUrl.type == "uploadImg") {
          if (blob.type.indexOf("image/") > -1) {
            let handler;
            //判断是否处在群组编辑页面。
            if(this.$router.history.current.name == 'gmap'  ) {
              handler = {
                "X-Type": "gmap-inner-image",
                "X-ID": this.$route.query.id
              }
            } else {
              handler = {
                "X-Type": "mindmap-inner-image",
                "X-ID": this.$route.query.id,
              }
            }
            postMindmapUploadImage(
              blob,
              (res) => {
                this.spinning = false;
                this.showCropperImgUrl("");
                let imgUrlData = HttpServerConfig.getMediaSrc(res.imageUrl);
                new imageToStyle()
                  .webToBase64(imgUrlData)
                  .then((imageBase64) => {
                    let width;
                    let height;
                    let img = new Image();img.setAttribute('crossOrigin','Anonymouse');
                    img.src = imgUrlData;
                    img.onload = () => {
                      width = img.width;
                      height = img.height;
                      // this.setDomImg({ imageBase64: imgUrlData, type: "storm", width: width, height: height});
                      // this.showCropperImg(false)

                      this.setDomImg({
                        imageBase64: imgUrlData,
                        type: "uploadImg",
                        width: width,
                        height: height,
                      });
                      setTimeout(() => {
                        this.setDomImg({
                          imageBase64: null,
                          type: "uploadImg",
                          width: 0,
                          height: 0,
                        });
                      }, 100);
                    };
                  });
                this.showCropperImg(false);
              },
              (error) => {
                this.spinning = false;
                console.log(error);
              },
              null,
              handler
            );


          } else {
            this.spinning = false;
            console.log("file is not a image");
          }
        }
      });
    },
    //图片缩放
    changeScale (num) {
      num = num || 1
      this.$refs.cropper.changeScale(num)
    },
  },

  watch: {
    "$store.state.cropperImgUrl.img"(
      newCropperImgUrlAccout,
      olCropperImgUrlAccout
    ) {
      let that = this;
      let img = new Image();img.setAttribute('crossOrigin','Anonymouse');
      img.src = newCropperImgUrlAccout;
      img.onload = function () {
        that.option.autoCropWidth = img.width;
        that.option.autoCropHeight = img.height;
      };
      this.option.img = newCropperImgUrlAccout;

      if (this.$store.state.cropperImgUrl.type == "storm") {
        this.option.fixedNumber = [5, 4];
        this.option.fixedBox = true;
      } else if (this.$store.state.cropperImgUrl.type == "uploadImg") {
        // this.option.fixedNumber = [1, 1];
        this.option.fixedBox = false;
        this.option.fixed = false;

        // this.option.centerBox = false;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.croppers {
  z-index: 9999;
  position: absolute;
  left: 50%;
  right: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 2px 2px 10px 6px rgba(0, 0, 0, 0.16);
  width: 100%;
  height: 100vh;
  .cropper-content {
    .cropper {
      width: auto;
      height: 100vh;
    }
  }
  .dialog-footer {
    width: 100%;
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    button {
      margin: 10px 10px;
    }
  }
}
.each-scale-box {
    position: relative;
    width: 30px;
    height: 30px;
    // background-color:red;
    color: #9fa2a8;
    border-radius: 50%;
    cursor: pointer;
    user-select: none;
    .center-icon-box {
      position: absolute;
      left: 15px;
      top: 15.5px;
      transform: translate(-50%, -50%);
      z-index: 2;
      font-size: 22px;
    }
    .active-show-background {
      display: none;
      position: absolute;
      left: 50%;
      top: 50%;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      // background-color: rgba(0, 0, 0, 0.2);
      background-color: rgba(253, 73, 43, 0.2);
      z-index: 1;
    }
    .bottom-gray-text-content {
      display: none;
      width: 40px;
      height: 24px;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      bottom: 30px;
      text-align: center;
      line-height: 24px;
      font-size: 14px;
      color: #fff;
      background-color: #9fa2a8;
      border-radius: 4px;
    }
    &:hover {
      // background-color: rgb(253, 73, 43);
      // color: #fff;

      // color: rgb(33, 33, 33);
      color: rgb(253, 73, 43);
      .bottom-gray-text-content {
        display: block;
      }
    }
    &:active .active-show-background {
      display: block;
    }
  }
   .add-scale-box {
    left: 20px;
    // bottom: 21px;
  }
  .lost-scale-box {
    left: 20px;
    // bottom: 21px;
  }
</style>