<template>
  <div class="ComplieRightMenuIcon">
    <div class="MenuIcon-box">
      <div class="MenuIcon-priority-bigbox">
        <div class="MenuIcon-priority-box">
          <div
            v-for="(item, index) in MenuIcons"
            :key="index"
            class="MenuIcon-priority-content"
            @click="priorityAdd(item.key)"
          >
            <img :src="item.head" alt ref="priority" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MindIconsManager from "../../../../viewmodel/core/tools/MindIconsManager";
import EditMindmapVM from "../../../../viewmodel/mindelementdata/vm/EditMindmapVM";
export default {
  data() {
    return {
      MenuIcons: [],
    };
  },
  mounted() {
    this.MenuIconForFrist();
  },
  methods: {
    MenuIconForFrist() {
      let fristIcon = MindIconsManager.tabDatas[1];
      for (var i = 0; i < fristIcon.cells.length; i++) {
        let fristIconList = fristIcon.cells[i].key;
        this.MenuIcons.push({
          // head: location.protocol+'//'+location.host+"/static/img/mapContent/assets/icons/" +
          //   fristIconList +
          //   ".imageset/" +
          //   fristIconList +
          //   ".png",
          head: require("../../../../../static/img/mapContent/assets/icons/" +
            fristIconList +
            ".imageset/" +
            fristIconList +
            ".png"),
          key: fristIconList,
          title: fristIconList.title,
        });
      }
    },
    priorityAdd(key) {
      EditMindmapVM.addIconToNode(key);
    },
  },
};
</script>
<style scoped>
.MenuIcon-priority-bigbox {
  margin-bottom: 20px;
  position: relative;
}
.MenuIcon-priority-bigbox span {
  font-weight: 700;
  font-size: 14px;
  line-height: 25px;
  margin: 0;
}
.MenuIcon-priority-box {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
}
.MenuIcon-priority-box img {
  width: 34px;
  height: 34px;
}
.MenuIcon-priority-content {
  padding: 14px;
  cursor: pointer;
}
.MenuIcon-priority-content embed {
  width: 26px;
  height: 26px;
  filter: drop-shadow(red 100px 0);
  transform: translateX(-100px);
}
.MenuIcon-priority-content:hover {
  background-color: var(--rightMenuIconHoverBg);
  padding: 14px;
}
</style>