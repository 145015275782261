<template>
  <div class="ComplieRightMenuIcon">
    <div class="MenuIcon-box">
      <div class="MenuIcon-priority-bigbox">
        <div class="MenuIcon-priority-box">
          <div
            v-for="(item, index) in MenuIcons"
            :key="index"
            @click="priorityAdd(item.key)"
            class="MenuIcon-priority-content"
          >
            <img
              :src="item.head"
              alt
              ref="priority"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MindIconsManager from "../../../../viewmodel/core/tools/MindIconsManager";
export default {
  data() {
    return {
      MenuIcons: [],
      viewModel: null,
    };
  },
  mounted() {
    this.MenuIconFood();
    if (this.viewModel == null) {
      this.viewModel = this.$store.state.editMindmap;
    }
  },
  methods: {
    MenuIconFood() {
      let foodIcon = MindIconsManager.tabDatas[5];
      for (var i = 0; i < foodIcon.cells.length; i++) {
        let foodList = foodIcon.cells[i].key;
        this.MenuIcons.push({
          head: require("../../../../../static/img/mapContent/assets/icons/" +
            foodList +
            ".imageset/" +
            foodList +
            ".png"),
          key: foodList,
          title: foodList.title,
        });
      }
    },
    priorityAdd(key) {
      this.viewModel.addIconToNode(key);
    },
  },
  watch: {
    "$store.state.editMindmap"(newval, olval) {
      this.viewModel = newval;
    },
  },
};
</script>
<style scoped>
.MenuIcon-priority-bigbox {
  margin-bottom: 20px;
  position: relative;
}
.MenuIcon-priority-bigbox span {
  font-weight: 700;
  font-size: 14px;
  line-height: 25px;
  margin: 0;
}
.MenuIcon-priority-box {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
}
.MenuIcon-priority-box img {
  width: 35px;
  height: 35px;
}
.MenuIcon-priority-content {
  padding: 14px;
  cursor: pointer;
}
.MenuIcon-priority-content embed {
  width: 26px;
  height: 26px;
  filter: drop-shadow(red 100px 0);
  transform: translateX(-100px);
}
.MenuIcon-priority-content:hover {
  background-color: var(--rightMenuIconHoverBg);;
  padding: 14px;
}
</style>